@charset "UTF-8";
/*functions*/
/*flexbox*/
/*CSS3 Columns*/
@media screen and (max-width: 420px) {
  .mobile-hide {
    display: none;
  }
}

@media screen and (min-width: 1250px) {
  .desktop-hide {
    display: none;
  }
}

@-ms-keyframes spin3d {
  0% {
    -ms-transform: rotate(0deg) translateZ(0);
  }
  100% {
    -ms-transform: rotate(359deg) translateZ(0);
  }
}

@keyframes spin3d {
  0% {
    transform: rotate(0deg) translateZ(0);
  }
  100% {
    transform: rotate(359deg) translateZ(0);
  }
}

/*theme*/
/*Paths*/
/*theme palete*/
/*theme color variables*/
/*alert colors*/
/*fonts*/
/*spacing*/
/*form fields: textarea, checkbox, selects etc*/
/*buttons*/
/*@font-face {
    font-family: 'Montserrat_Bold';
    src: url(../assets/fonts/montserrat-bold-webfont.woff2) format('woff2'),
         url(../assets/fonts/montserrat-bold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Montserrat_Regular';
    src:  url(../assets/fonts/montserrat-regular-webfont.woff2) format('woff2'),
         url(../assets/fonts/montserrat-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Montserrat_Semi_Bold';
    src: url(../assets/fonts/montserrat-semibold-webfont.woff2) format('woff2'),
         url(../assets/fonts/montserrat-semibold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
*/
@font-face {
  font-family: 'Avenir';
  src: url(../assets/fonts/AvenirLTStd-Medium.woff2) format("woff2"), url(../assets/fonts/AvenirLTStd-Medium.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url(../assets/fonts/AvenirLTStd-Heavy.woff2) format("woff2"), url(../assets/fonts/AvenirLTStd-Heavy.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Haptik Bold';
  src: url(../assets/fonts/GTHaptik-Bold.eot);
  src: url(../assets/fonts/GTHaptik-Bold.woff2) format("woff2"), url(../assets/fonts/GTHaptik-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "icon";
  src: url(../assets/fonts/Flaticon.woff?v184) format("woff");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-comment:before {
  content: "\f100";
}

.icon-refresh-lock:before {
  content: "\f101";
}

.icon-password:before {
  content: "\f102";
}

.icon-tick-thick:before {
  content: "\f103";
}

.icon-avatar:before {
  content: "\f104";
}

.icon-plan:before {
  content: "\f105";
}

.icon-tool:before {
  content: "\f106";
}

.icon-rating:before {
  content: "\f107";
}

.icon-graph:before {
  content: "\f108";
}

.icon-note:before {
  content: "\f109";
}

.icon-eye:before {
  content: "\f10a";
}

.icon-more:before {
  content: "\f10b";
}

.icon-notifications-on:before {
  content: "\f10c";
}

.icon-info-circle-full:before {
  content: "\f10d";
}

.icon-info-circle:before {
  content: "\f10e";
}

.icon-settings:before {
  content: "\f10f";
}

.icon-history:before {
  content: "\f110";
}

.icon-new-message:before {
  content: "\f111";
}

.icon-question:before {
  content: "\f112";
}

.icon-loading:before {
  content: "\f113";
}

.icon-arrow-up:before {
  content: "\f114";
}

.icon-arrow-left:before {
  content: "\f115";
}

.icon-arrow-down:before {
  content: "\f116";
}

.icon-arrow-right:before {
  content: "\f117";
}

.icon-warning:before {
  content: "\f118";
}

.icon-minus-circle:before {
  content: "\f119";
}

.icon-plus-circle:before {
  content: "\f11a";
}

.icon-menu:before {
  content: "\f11b";
}

.icon-back:before {
  content: "\f11c";
}

.icon-menu-sml:before {
  content: "\f11d";
}

.icon-forward:before {
  content: "\f11e";
}

.icon-link-full:before {
  content: "\f11f";
}

.icon-creativity:before {
  content: "\f120";
}

.icon-target:before {
  content: "\f121";
}

.icon-link:before {
  content: "\f122";
}

.icon-account:before {
  content: "\f123";
}

.icon-tick-circle:before {
  content: "\f124";
}

.icon-error:before {
  content: "\f125";
}

.icon-danger:before {
  content: "\f126";
}

.icon-lock:before {
  content: "\f127";
}

.icon-unlock:before {
  content: "\f128";
}

.icon-search:before {
  content: "\f129";
}

.icon-list:before {
  content: "\f12a";
}

.icon-tick:before {
  content: "\f12b";
}

.icon-newspaper:before {
  content: "\f12c";
}

.icon-blog:before {
  content: "\f12d";
}

.icon-cancel-button:before {
  content: "\f12e";
}

.icon-add-button:before {
  content: "\f12f";
}

.icon-notifications:before {
  content: "\f130";
}

.icon-rubbish-bin:before {
  content: "\f131";
}

.icon-suitcase:before {
  content: "\f132";
}

.icon-cross:before {
  content: "\f133";
}

/*base*/
.container {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

@media (min-width: 481px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 767px) {
  .container {
    padding-left: 2%;
    padding-right: 2%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1366px;
    padding-left: 0;
    padding-right: 0;
  }
}

.grid {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0;
}

.grid .grid-cell {
  flex: 1;
}

/* Alignment per row */
.grid-top {
  align-items: flex-start;
}

.grid-bottom {
  align-items: flex-end;
}

.grid-center {
  align-items: center;
  height: 100%;
  justify-content: center;
}

/* Alignment per cell */
.grid-cell-top {
  align-self: flex-start;
}

.grid-cell-bottom {
  align-self: flex-end;
}

.grid-cell-center {
  align-self: center;
}

/* Base classes for all media */
.grid-fit,
.grid-cell {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}

.grid > [class*=grid-] {
  width: 100%;
}

@media (min-width: 767px) {
  .grid > [class*=grid-] {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.grid.no-gutters > [class*=grid-] {
  padding-left: 0;
  padding-right: 0;
}

.grid.grid-no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 767px) {
  /* With gutters */
  .grid {
    flex-direction: row;
    margin: 0 0 0 0;
    margin-left: -5%;
    width: 105%;
  }
  .grid.no-gutters {
    margin: 0;
  }
  .grid > [class*=grid-] {
    margin: 0 0 0 5%;
    padding: 0;
  }
  .grid.no-gutters {
    width: 100%;
  }
  .grid.no-gutters > [class*=grid-] {
    margin: 0;
  }
  .grid.grid-no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid.grid-no-padding > [class*=grid-] {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid-100 {
    -moz-box-flex: 1 1 100%  ;
    -webkit-flex: 1 1 100%  ;
    -ms-flex: 1 1 100%  ;
    flex: 1 1 100%  ;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  .grid-80 {
    -moz-box-flex: 1 1 80%  ;
    -webkit-flex: 1 1 80%  ;
    -ms-flex: 1 1 80%  ;
    flex: 1 1 80%  ;
    max-width: 80%;
    width: 100%;
    height: 100%;
  }
  .grid-75 {
    -moz-box-flex: 1 1 75%  ;
    -webkit-flex: 1 1 75%  ;
    -ms-flex: 1 1 75%  ;
    flex: 1 1 75%  ;
    max-width: 75%;
    width: 100%;
    height: 100%;
  }
  .grid-70 {
    -moz-box-flex: 1 1 70%  ;
    -webkit-flex: 1 1 70%  ;
    -ms-flex: 1 1 70%  ;
    flex: 1 1 70%  ;
    max-width: 70%;
    width: 100%;
    height: 100%;
  }
  .grid-66 {
    -moz-box-flex: 1 1 66.66%  ;
    -webkit-flex: 1 1 66.66%  ;
    -ms-flex: 1 1 66.66%  ;
    flex: 1 1 66.66%  ;
    max-width: 66.66%;
    width: 100%;
    height: 100%;
  }
  .grid-60 {
    -moz-box-flex: 1 1 60%  ;
    -webkit-flex: 1 1 60%  ;
    -ms-flex: 1 1 60%  ;
    flex: 1 1 60%  ;
    max-width: 60%;
    width: 100%;
    height: 100%;
  }
  .grid-50 {
    -moz-box-flex: 1 1 50%  ;
    -webkit-flex: 1 1 50%  ;
    -ms-flex: 1 1 50%  ;
    flex: 1 1 50%  ;
    max-width: 50%;
    width: 100%;
    height: 100%;
  }
  .grid-45 {
    -moz-box-flex: 1 1 45%  ;
    -webkit-flex: 1 1 45%  ;
    -ms-flex: 1 1 45%  ;
    flex: 1 1 45%  ;
    max-width: 45%;
    width: 100%;
    height: 100%;
  }
  .grid-40 {
    -moz-box-flex: 1 1 40%  ;
    -webkit-flex: 1 1 40%  ;
    -ms-flex: 1 1 40%  ;
    flex: 1 1 40%  ;
    max-width: 40%;
    width: 100%;
    height: 100%;
  }
  .grid-33 {
    -moz-box-flex: 1 1 33.33%  ;
    -webkit-flex: 1 1 33.33%  ;
    -ms-flex: 1 1 33.33%  ;
    flex: 1 1 33.33%  ;
    max-width: 33.33%;
    width: 100%;
    height: 100%;
  }
  .grid-30 {
    -moz-box-flex: 1 1 30%  ;
    -webkit-flex: 1 1 30%  ;
    -ms-flex: 1 1 30%  ;
    flex: 1 1 30%  ;
    max-width: 30%;
    width: 100%;
    height: 100%;
  }
  .grid-25 {
    -moz-box-flex: 1 1 25%  ;
    -webkit-flex: 1 1 25%  ;
    -ms-flex: 1 1 25%  ;
    flex: 1 1 25%  ;
    max-width: 25%;
    width: 100%;
    height: 100%;
  }
  .grid-20 {
    -moz-box-flex: 1 1 20%  ;
    -webkit-flex: 1 1 20%  ;
    -ms-flex: 1 1 20%  ;
    flex: 1 1 20%  ;
    max-width: 20%;
    width: 100%;
    height: 100%;
  }
  .grid-10 {
    -moz-box-flex: 1 1 10%  ;
    -webkit-flex: 1 1 10%  ;
    -ms-flex: 1 1 10%  ;
    flex: 1 1 10%  ;
    max-width: 10%;
    width: 100%;
    height: 100%;
  }
  .grid-order-end {
    order: 10;
  }
}

.hidden {
  display: none !important;
}

.visible-phone {
  display: inherit !important;
}

.visible-tablet {
  display: none !important;
}

.visible-desktop {
  display: none !important;
}

.hidden-phone {
  display: none !important;
}

.hidden-tablet {
  display: inherit !important;
}

.hidden-desktop {
  display: inherit !important;
}

@media (min-width: 1000px) {
  .visible-phone {
    display: none !important;
  }
  .visible-tablet {
    display: inherit !important;
  }
  .visible-desktop {
    display: none !important;
  }
  .hidden-phone {
    display: inherit !important;
  }
  .hidden-tablet {
    display: none !important;
  }
  .hidden-desktop {
    display: inherit !important;
  }
}

@media (min-width: 1250px) {
  .visible-phone {
    display: none !important;
  }
  .visible-tablet {
    display: none !important;
  }
  .visible-desktop {
    display: inherit !important;
  }
  .hidden-phone {
    display: inherit !important;
  }
  .hidden-tablet {
    display: inherit !important;
  }
  .hidden-desktop {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: inherit !important;
  }
  .hidden-print {
    display: none;
  }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input, textarea, [contenteditable] {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html {
  height: 100%;
}

html {
  font-size: 16px;
}

@media screen and (min-width: 420px) {
  html {
    font-size: calc(16px + 4 * ((100vw - 420px) / 946));
  }
}

@media screen and (min-width: 1366px) {
  html {
    font-size: 20px;
  }
}

html [ui-view="fullpage"], html login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 9999;
}

body {
  height: 100%;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  font-weight: normal;
  background-color: #f4f5f7;
  color: #58595b;
  -webkit-font-smoothing: antialiased;
}

body .content-container {
  background-color: #f4f5f7;
  height: calc(100% - 60px);
  margin-top: 60px;
}

@media (min-width: 767px) {
  body .content-container {
    height: calc(100% - 90px);
    margin-top: 90px;
  }
}

ui-view {
  overflow: hidden;
  width: 100%;
}

main {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  min-height: 300px;
  padding-top: 4.5rem;
  padding-bottom: 5.5rem;
  position: relative;
}

@media (min-width: 481px) {
  main {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 767px) {
  main {
    padding-left: 2%;
    padding-right: 2%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1500px) {
  main {
    width: 1366px;
    padding-left: 0;
    padding-right: 0;
  }
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto !important;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  vertical-align: baseline;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  margin: 0 0 .2rem;
  color: #A9A9A9;
  font-weight: 600;
  line-height: 1;
}

h4 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

h5, h6, .h5, .h6 {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  color: #A9A9A9;
  line-height: 1;
  font-weight: normal;
}

h1, .h1 {
  padding: .5rem 0 0rem;
  color: #243D51;
  font-size: 1.55rem;
  display: inline-block;
}

h2, .h2 {
  padding: 1rem 0 .5rem;
  font-size: 1.4rem;
}

h3, .h3 {
  padding: .9rem 0 .4rem;
  color: #555;
  font-size: 1.3rem;
}

h4, .h4 {
  font-size: 1rem;
  padding: .5rem 0 .1rem;
  margin: 0 0 .4rem;
  color: #555;
}

@media (min-width: 481px) {
  h4, .h4 {
    font-size: 1.1rem;
  }
}

h5, .h5 {
  font-size: .9rem;
  padding: .5rem 0 .1rem;
  margin: 0 0 .3rem;
}

h6, .h6 {
  font-size: .9rem;
}

a {
  text-decoration: none;
  color: #A9A9A9;
  transition: color 0.1s linear;
}

a:active, a:focus {
  outline: none;
}

p {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #58595b;
  margin: .4rem 0 1rem;
  line-height: 1.3;
}

p a {
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

.intro {
  font-weight: bold;
  margin: .4em 0 .8em;
  font-size: 1.1em;
}

ol, ul {
  margin: 0 0 0 0.5rem;
  padding: 0 0 1rem 0;
}

ol li, ul li {
  margin: 0 0 0 1rem;
  padding: 0 0 0.5rem 0;
}

dl {
  margin-bottom: 0.5rem;
}

dl dt {
  font-weight: bold;
  margin-top: 0.5rem;
}

dl dd {
  margin: 0;
}

hr {
  border-bottom: 1px solid rgba(119, 119, 119, 0.3);
  border-left: none;
  border-right: none;
  border-top: none;
  margin: 1.2em 0;
}

sub, sup {
  font-size: 45%;
  line-height: 0;
  position: relative;
  vertical-align: middle;
}

sup {
  top: -0.6rem;
}

sub {
  bottom: -.4rem;
}

small {
  font-size: 70%;
}

p small {
  font-size: 90%;
}

.block-title, .block-footer {
  background-color: #A9A9A9;
  color: #fff;
  text-align: center;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  margin: 0 0 0;
  clear: both;
  padding: 25px 5% 25px;
}

.block-title a, .block-footer a {
  text-decoration: none;
}

.block-title a:hover, .block-footer a:hover {
  text-decoration: underline;
}

.block-title span, .block-footer span {
  display: table;
  margin: 0 auto -24px auto;
  width: auto;
  padding: 5px 5%;
}

h4.block-title {
  margin: 10px 0 0;
}

h4.block-title span {
  padding: 0 5%;
  margin: 10px auto -11px auto;
}

.block-title.block-title-inverse {
  color: #A9A9A9;
  background-color: #fff;
}

.block-title.block-title-purple {
  background-color: white;
}

.block-title.block-title-purple h1, .block-title.block-title-purple h2, .block-title.block-title-purple h3 {
  color: #A9A9A9;
}

.block-footer {
  margin: 25px 0 40px;
  border-bottom: 3px solid #eee;
}

.block-footer span {
  margin: 0 auto -16px auto;
}

.page-title {
  margin: 30px 0 25px;
  position: relative;
  clear: both;
  border-bottom: 3px solid #ddd;
  width: 100%;
}

.page-title h1 {
  margin: 0;
}

.page-title h1 small {
  display: block;
  font-size: 52.5%;
}

.page-title .thickbox img {
  width: auto;
}

.page-title img {
  width: 100%;
}

.page-title img + .page-title-inner {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 2%;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-title img + .page-title-inner .slide-content-left {
  width: 45%;
  padding: 0 0 0 4%;
}

.page-title img + .page-title-inner h1,
.page-title img + .page-title-inner .breadcrumb a {
  color: #fff;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
}

@media (min-width: 1000px) {
  .page-title img + .page-title-inner h1,
  .page-title img + .page-title-inner .breadcrumb a {
    font-size: 1em;
  }
}

@media (min-width: 1000px) {
  .page-title img + .page-title-inner h1 {
    font-size: 3rem;
  }
}

.breadcrumb {
  margin: 0rem 0 1rem;
  padding: 0;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.breadcrumb h6 {
  font-size: .8rem;
}

.breadcrumb li {
  margin: 0;
  display: inline-block;
  color: #bbb;
  text-transform: uppercase;
  line-height: .8em;
  padding: 0;
}

.breadcrumb li a {
  color: #888;
}

.breadcrumb li a:hover {
  color: #000;
}

.breadcrumb li:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: .7em;
  color: #999;
  vertical-align: top;
  padding: 1px 1px 0 2px;
  display: inline-block;
}

.page-title .breadcrumb {
  margin: 0 0 -0.5rem;
}

/* Helper Classes */
.align-left, .text-left {
  text-align: left !important;
}

.align-center, .text-center {
  text-align: center !important;
}

.align-right, .text-right {
  text-align: right !important;
}

.margin {
  margin: 0.5rem !important;
}

.padding {
  padding: 0.5rem !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.margin-top {
  margin-top: 0.5rem !important;
}

.margin-bottom {
  margin-bottom: 0.5rem !important;
}

.margin-top-lg {
  margin-top: 1rem !important;
}

.margin-bottom-lg {
  margin-bottom: 1rem !important;
}

.margin-left {
  margin-left: 0.5rem !important;
}

.margin-right {
  margin-right: 0.5rem !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.opacity-half {
  opacity: .5;
}

.full-width {
  width: 100% !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.float-left, .fl_l, .floatleft, .alignleft {
  float: left !important;
}

.float-right, .fl_r, .floatright, .alignright {
  float: right !important;
}

/* wp
.alignleft{
    float:left !important;
    margin:10px 15px 10px 0;
}
.alignright{
    float:right !important;
    margin:10px 0 10px 15px;
}
*/
.flex-end {
  align-self: flex-end;
}

.flex-fit {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}

.clear-both, .clear, .clearfix {
  clear: both !important;
}

.box-center {
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden !important;
}

.hide {
  display: none !important;
}

.hide-height {
  max-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
}

.hide-opacity {
  visibility: hidden;
  opacity: 0;
}

.show-opacity {
  visibility: visible;
  opacity: 1;
}

.lock-position {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visually-hidden-focusable:active,
.visually-hidden-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.flexbox {
  display: flex;
}

/*modules*/
.browserupgrade {
  margin: 0;
  background: #454545;
  color: #fff;
  height: 100%;
  font-size: 16px;
  text-align: center;
  padding: 5% 10%;
  font-family: Arial, san-serif;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.browserupgrade img {
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 300px;
}

.browserupgrade strong {
  font-size: 20px;
  line-height: 2em;
}

.browserupgrade a {
  color: #fff;
  text-decoration: underline;
}

/*.form-group {
    margin: .2em 0;
    display: flex;
    flex-flow: row wrap;
    label {
        @include flex(0 1 4em);
    }
    input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, .inputElement {
        @include flex(1);
        min-width: 50px;
    }
    .btn {
        margin: 0;
    }
    .form-group{
        @include flex(0 0 50%;)
    }
}*/
.form-bordered {
  transform: translateZ(0);
  background-color: #fff;
  border: 1px solid #dfdfdf;
  margin-bottom: 1rem;
  padding: 8% 4% 2%;
}

/*input[required]{
    background-image: radial-gradient(#F00 15%, transparent 16%);
    background-size: 2em 2em;
    background-position: right center;
    background-repeat: no-repeat;
}*/
.input-heading,
.label,
label {
  line-height: 1;
  font-size: 17px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #58595b;
  color: #243D51;
}

.input-heading strong,
.label strong,
label strong {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #58595b;
}

.input-heading.lbl-sm,
.label.lbl-sm,
label.lbl-sm {
  line-height: .6;
  font-size: 95% !important;
}

.pac-container {
  margin-top: -1px !important;
  margin-top: -1px !important;
}

.input-width-xs {
  max-width: 100px;
}

.input-width-sm {
  max-width: 200px;
}

.recaptcha {
  flex: 1 1 100%;
  margin-left: -8px;
  margin-top: 25px;
}

@media (min-width: 580px) {
  .recaptcha {
    margin-left: 170px;
    margin-right: 20%;
    margin-top: 0px;
    width: auto;
  }
}

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea, .inputElement {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  font-size: 1rem;
  min-width: 10px;
  transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
  color: #243D51;
  border-radius: 0;
  border: 2px solid #A9A9A9;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: .4rem;
  padding: .6rem .9rem;
  font-size: 1rem;
}

input[type="color"]::-webkit-input-placeholder, input[type="date"]::-webkit-input-placeholder, input[type="datetime"]::-webkit-input-placeholder, input[type="datetime-local"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="month"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, input[type="search"]::-webkit-input-placeholder, input[type="tel"]::-webkit-input-placeholder, input[type="text"]::-webkit-input-placeholder, input[type="time"]::-webkit-input-placeholder, input[type="url"]::-webkit-input-placeholder, input[type="week"]::-webkit-input-placeholder, input:not([type])::-webkit-input-placeholder, textarea::-webkit-input-placeholder, .inputElement::-webkit-input-placeholder {
  color: #A9A9A9;
}

input[type="color"]:-moz-placeholder, input[type="date"]:-moz-placeholder, input[type="datetime"]:-moz-placeholder, input[type="datetime-local"]:-moz-placeholder, input[type="email"]:-moz-placeholder, input[type="month"]:-moz-placeholder, input[type="number"]:-moz-placeholder, input[type="password"]:-moz-placeholder, input[type="search"]:-moz-placeholder, input[type="tel"]:-moz-placeholder, input[type="text"]:-moz-placeholder, input[type="time"]:-moz-placeholder, input[type="url"]:-moz-placeholder, input[type="week"]:-moz-placeholder, input:not([type]):-moz-placeholder, textarea:-moz-placeholder, .inputElement:-moz-placeholder {
  color: #A9A9A9;
}

input[type="color"]::-moz-placeholder, input[type="date"]::-moz-placeholder, input[type="datetime"]::-moz-placeholder, input[type="datetime-local"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="month"]::-moz-placeholder, input[type="number"]::-moz-placeholder, input[type="password"]::-moz-placeholder, input[type="search"]::-moz-placeholder, input[type="tel"]::-moz-placeholder, input[type="text"]::-moz-placeholder, input[type="time"]::-moz-placeholder, input[type="url"]::-moz-placeholder, input[type="week"]::-moz-placeholder, input:not([type])::-moz-placeholder, textarea::-moz-placeholder, .inputElement::-moz-placeholder {
  color: #A9A9A9;
}

input[type="color"]:-ms-input-placeholder, input[type="date"]:-ms-input-placeholder, input[type="datetime"]:-ms-input-placeholder, input[type="datetime-local"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="month"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, input[type="search"]:-ms-input-placeholder, input[type="tel"]:-ms-input-placeholder, input[type="text"]:-ms-input-placeholder, input[type="time"]:-ms-input-placeholder, input[type="url"]:-ms-input-placeholder, input[type="week"]:-ms-input-placeholder, input:not([type]):-ms-input-placeholder, textarea:-ms-input-placeholder, .inputElement:-ms-input-placeholder {
  color: #A9A9A9;
}

input[type="color"]:placeholder, input[type="date"]:placeholder, input[type="datetime"]:placeholder, input[type="datetime-local"]:placeholder, input[type="email"]:placeholder, input[type="month"]:placeholder, input[type="number"]:placeholder, input[type="password"]:placeholder, input[type="search"]:placeholder, input[type="tel"]:placeholder, input[type="text"]:placeholder, input[type="time"]:placeholder, input[type="url"]:placeholder, input[type="week"]:placeholder, input:not([type]):placeholder, textarea:placeholder, .inputElement:placeholder {
  color: #A9A9A9;
}

input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover, .inputElement:hover {
  border-color: #aaa;
}

input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus, .inputElement:focus {
  outline: none;
  border-color: #222;
  background-color: #f9f9f9;
}

input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled, .inputElement:disabled {
  opacity: 0.8;
}

input[type="color"] select, input[type="date"] select, input[type="datetime"] select, input[type="datetime-local"] select, input[type="email"] select, input[type="month"] select, input[type="number"] select, input[type="password"] select, input[type="search"] select, input[type="tel"] select, input[type="text"] select, input[type="time"] select, input[type="url"] select, input[type="week"] select, input:not([type]) select, textarea select, .inputElement select {
  padding: .6rem .9rem;
  font-size: 1rem;
  border: none;
}

.select-holder {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  font-size: 1rem;
  min-width: 10px;
  transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
  color: #243D51;
  border-radius: 0;
  border: 2px solid #A9A9A9;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: .4rem;
  padding: .6rem .9rem;
  font-size: 1rem;
  padding: 0;
  display: inline-block;
  min-width: 140px;
  height: auto;
  cursor: pointer;
  font-size: normal;
  position: relative;
}

.select-holder::-webkit-input-placeholder {
  color: #A9A9A9;
}

.select-holder:-moz-placeholder {
  color: #A9A9A9;
}

.select-holder::-moz-placeholder {
  color: #A9A9A9;
}

.select-holder:-ms-input-placeholder {
  color: #A9A9A9;
}

.select-holder:placeholder {
  color: #A9A9A9;
}

.select-holder:hover {
  border-color: #aaa;
}

.select-holder:focus {
  outline: none;
  border-color: #222;
  background-color: #f9f9f9;
}

.select-holder:disabled {
  opacity: 0.8;
}

.select-holder select {
  padding: .6rem .9rem;
  font-size: 1rem;
  border: none;
}

.select-holder select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: none;
  border: none;
  box-shadow: none;
  margin: -2px;
  border-top: 4px solid transparent;
  color: #243D51;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  cursor: pointer;
  height: auto;
  position: relative;
  width: 100%;
  z-index: 10;
}

.select-holder select::-ms-expand {
  display: none;
}

.select-holder select:hover:before {
  color: #aaa;
}

.select-holder select:focus:before {
  color: #222;
}

.select-holder select option {
  color: #243D51;
}

.select-holder select.ng-invalid, .select-holder select option:first-child {
  color: #A9A9A9;
}

.select-holder:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #A9A9A9;
  font-size: 19px;
  position: absolute;
  right: .8rem;
  top: 50%;
  margin-top: -10px;
}

input[type="color"].input-xs, input[type="date"].input-xs, input[type="datetime"].input-xs, input[type="datetime-local"].input-xs, input[type="email"].input-xs, input[type="month"].input-xs, input[type="number"].input-xs, input[type="password"].input-xs, input[type="search"].input-xs, input[type="tel"].input-xs, input[type="text"].input-xs, input[type="time"].input-xs, input[type="url"].input-xs, input[type="week"].input-xs, input:not([type]).input-xs, textarea.input-xs, .inputElement.input-xs {
  padding: .375rem .5rem;
  font-size: 16px;
  line-height: normal;
}

input[type="color"].input-sm, input[type="date"].input-sm, input[type="datetime"].input-sm, input[type="datetime-local"].input-sm, input[type="email"].input-sm, input[type="month"].input-sm, input[type="number"].input-sm, input[type="password"].input-sm, input[type="search"].input-sm, input[type="tel"].input-sm, input[type="text"].input-sm, input[type="time"].input-sm, input[type="url"].input-sm, input[type="week"].input-sm, input:not([type]).input-sm, textarea.input-sm, .inputElement.input-sm {
  padding: .55rem .75rem;
  font-size: 16px;
}

input[type="color"].input-lg, input[type="date"].input-lg, input[type="datetime"].input-lg, input[type="datetime-local"].input-lg, input[type="email"].input-lg, input[type="month"].input-lg, input[type="number"].input-lg, input[type="password"].input-lg, input[type="search"].input-lg, input[type="tel"].input-lg, input[type="text"].input-lg, input[type="time"].input-lg, input[type="url"].input-lg, input[type="week"].input-lg, input:not([type]).input-lg, textarea.input-lg, .inputElement.input-lg {
  padding: .75rem 1.85rem;
  font-size: 17px;
}

input[type="color"].input-xl, input[type="date"].input-xl, input[type="datetime"].input-xl, input[type="datetime-local"].input-xl, input[type="email"].input-xl, input[type="month"].input-xl, input[type="number"].input-xl, input[type="password"].input-xl, input[type="search"].input-xl, input[type="tel"].input-xl, input[type="text"].input-xl, input[type="time"].input-xl, input[type="url"].input-xl, input[type="week"].input-xl, input:not([type]).input-xl, textarea.input-xl, .inputElement.input-xl {
  padding: .85rem 1.2rem;
  font-size: 19px;
}

.select-holder.select-lg select {
  padding: .75rem 1.85rem;
  font-size: 17px;
}

/* I use form--complex for forms that have more complex layouts than simple left/right label and field alignment etc */
.form--complex {
  display: flex;
}

.form__object--fillspace {
  /* This element will receive priority of size. 
     All other elements will be sized at the size dictated by their display type */
  flex-grow: 1;
}

.form__object--fillspace + .form__object--fillspace-gap {
  margin-left: .5em;
}

.form__object--fillspace .btn {
  margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.form-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100%;
  padding: 0;
}

.form-row label {
  margin-top: 1.2rem;
  margin-bottom: .3rem;
  display: block;
  width: 100%;
}

@media (min-width: 580px) {
  .form-row label {
    display: block;
    width: 100%;
  }
}

.form-row input[type="color"], .form-row input[type="date"], .form-row input[type="datetime"], .form-row input[type="datetime-local"], .form-row input[type="email"], .form-row input[type="month"], .form-row input[type="number"], .form-row input[type="password"], .form-row input[type="search"], .form-row input[type="tel"], .form-row input[type="text"], .form-row input[type="time"], .form-row input[type="url"], .form-row input[type="week"], .form-row input:not([type]), .form-row textarea, .form-row .inputElement,
.form-row .select-holder {
  margin: 0;
}

.form-row label.hide + input,
.form-row label.hide + .select-holder {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

@media (min-width: 580px) {
  .form-row label.hide + input,
  .form-row label.hide + .select-holder {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.form-row label.checkbox, .form-row label.radio {
  margin-top: .2rem;
}

@media (min-width: 580px) {
  .form-row {
    flex-direction: row;
    margin: .3rem 0 1.2rem;
  }
  .form-row label {
    margin-top: .3rem;
    margin-bottom: .3rem;
  }
  .form-row label.checkbox, .form-row label.radio {
    margin-top: .2rem;
  }
}

.form-row p {
  flex-direction: column;
  width: 100%;
  margin-bottom: .2em;
  font-size: 14px;
}

.form-row a {
  text-decoration: underline;
  color: #777;
}

.form-row a:hover {
  text-decoration: none;
  color: #111;
}

.form-row p.location {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  font-size: 1rem;
  min-width: 10px;
  transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
  color: #243D51;
  border-radius: 0;
  border: 2px solid #A9A9A9;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #f9f9f9;
  font-weight: normal;
  margin-bottom: .4rem;
  padding: .6rem .9rem;
  font-size: 1rem;
  margin-bottom: 0;
  height: auto;
}

.form-row p.location::-webkit-input-placeholder {
  color: #A9A9A9;
}

.form-row p.location:-moz-placeholder {
  color: #A9A9A9;
}

.form-row p.location::-moz-placeholder {
  color: #A9A9A9;
}

.form-row p.location:-ms-input-placeholder {
  color: #A9A9A9;
}

.form-row p.location:placeholder {
  color: #A9A9A9;
}

.form-row p.location:hover {
  border-color: #aaa;
}

.form-row p.location:focus {
  outline: none;
  border-color: #222;
  background-color: #f9f9f9;
}

.form-row p.location:disabled {
  opacity: 0.8;
}

.form-row p.location select {
  padding: .6rem .9rem;
  font-size: 1rem;
  border: none;
}

.form-row .form-cell {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
}

@media (min-width: 580px) {
  .form-row .form-cell + .form-cell {
    margin-top: 1.5em;
  }
}

@media (min-width: 1250px) {
  .form-row .form-cell {
    -moz-box-flex: 1  ;
    -webkit-flex: 1  ;
    -ms-flex: 1  ;
    flex: 1  ;
    padding: 0 3% 0 0;
  }
  .form-row .form-cell + .form-cell {
    margin-top: 0;
  }
}

.form-row .form-cell:last-child {
  padding: 0 0 0 0;
}

.signup-form {
  position: relative;
  padding: 40px 5%;
  background-color: #00adef;
}

.signup-form input[type="color"], .signup-form input[type="date"], .signup-form input[type="datetime"], .signup-form input[type="datetime-local"], .signup-form input[type="email"], .signup-form input[type="month"], .signup-form input[type="number"], .signup-form input[type="password"], .signup-form input[type="search"], .signup-form input[type="tel"], .signup-form input[type="text"], .signup-form input[type="time"], .signup-form input[type="url"], .signup-form input[type="week"], .signup-form input:not([type]), .signup-form textarea, .signup-form .inputElement,
.signup-form select {
  padding: .75rem 1.85rem;
  font-size: 17px;
}

@media (min-width: 1000px) {
  .signup-form {
    padding: 50px 14%;
  }
}

textarea {
  resize: vertical;
  height: 5em;
}

.form-loader:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  z-index: 100;
  transition: opacity 1s linear;
  visibility: hidden;
}

.form-loader.form-loading:before {
  visibility: visible;
  opacity: .5;
}

input[type=radio], input[type=checkbox] {
  position: absolute;
  left: -9999px;
  height: 0;
  width: 0;
  user-select: none;
}

input[type=radio]:checked + .radio-btn, input[type=checkbox]:checked + .radio-btn {
  opacity: 1;
}

input[type=radio]:checked + .radio-btn:before, input[type=checkbox]:checked + .radio-btn:before {
  transform: scale(1);
  opacity: 1;
  margin: -.2rem .5rem 0 -.3rem;
  display: inline-block;
}

input[type=radio]:checked + .radio-btn.radio-select span.text-unchecked, input[type=checkbox]:checked + .radio-btn.radio-select span.text-unchecked {
  display: none;
}

input[type=radio]:checked + .radio-btn.radio-select span.text-checked, input[type=checkbox]:checked + .radio-btn.radio-select span.text-checked {
  display: inline;
}

input[type=radio] + .radio-btn.radio-select span.text-checked, input[type=checkbox] + .radio-btn.radio-select span.text-checked {
  display: none;
}

input[type=radio]:checked + .radio:after, input[type=checkbox]:checked + .checkbox:after {
  visibility: visible;
  transform: scale(1);
}

input[type=radio]:checked + .radio:before, input[type=checkbox]:checked + .radio:before {
  border: solid 1px #243D51;
}

input[type=radio]:checked + .checkbox:before, input[type=checkbox]:checked + .checkbox:before {
  visibility: visible;
  color: #243D51;
  transform: scale(1);
}

input[type=radio]:disabled + .checkbox, input[type=checkbox]:disabled + .checkbox {
  cursor: not-allowed;
  opacity: .4;
}

input[type=radio] + label.radio, input[type=checkbox] + label.checkbox {
  user-select: none;
  font-size: .9rem;
  display: inline-block;
}

input[type=radio] + label .sub, input[type=checkbox] + label .sub {
  color: #b7b7b7;
  display: block;
  margin-top: -3px;
}

label.radio-btn {
  opacity: .85;
  display: inline-block;
  width: auto;
}

label.radio-btn:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  transition: all .1s ease;
  margin: -.3rem -.3rem 0 -.3rem;
  transform: scale(0.1);
  opacity: 0;
  font-size: 1.2em;
}

.radio-btn.radio-btn--inline {
  width: auto;
  display: inline-block;
}

.radio-btn.radio-btn--inline:after {
  margin-left: 0.75rem;
}

.radio-btn:hover {
  cursor: pointer;
}

.radio-btn:after {
  display: none;
}

.radio-btn + .radio-btn {
  margin-right: .5rem;
}

.radio {
  margin: .2rem 0.7rem 0.7rem 0;
  width: 100%;
  padding-top: .2em;
  position: relative;
  padding-left: 1.7rem !important;
}

.radio.radio--inline {
  width: auto;
  display: inline-block;
}

.radio:hover {
  cursor: pointer;
}

.radio:before {
  content: "";
  display: block;
  border-radius: 50%;
  border: solid 1px #aaa;
  transition: border-color .1s ease;
  width: 22px;
  height: 22px;
  top: 0;
  position: absolute;
  left: 0;
}

.radio:after {
  content: "";
  display: block;
  border-radius: 50%;
  transition: transform .1s ease;
  visibility: hidden;
  background: #243D51;
  width: 12px;
  height: 12px;
  transform: scale(0.5);
  position: absolute;
  left: 5px;
  top: 5px;
  margin: 0;
}

.checkbox {
  margin: .2rem 0.1rem 0.5rem 0.1rem;
  padding-top: .2rem;
  width: 100%;
  position: relative;
  padding-left: 1.5rem !important;
}

@media (min-width: 767px) {
  .checkbox {
    padding-top: 0;
  }
}

.checkbox.checkbox--inline {
  width: auto;
  display: inline-block;
}

.checkbox:hover {
  cursor: pointer;
}

.checkbox:before {
  transition: transform .1s ease;
  display: block;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: absolute;
  left: 2px;
  transform: scale(0.01);
  top: 0px;
  font-size: 18px;
  z-index: 2;
  color: #222;
}

.checkbox:after {
  content: '';
  color: #fff;
  font-size: 20px;
  border: 2px solid #A9A9A9;
  width: 21px;
  background-color: #eee;
  height: 21px;
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  margin: 0;
  z-index: 1;
}

.radio, .checkbox {
  transition: color .1s ease;
}

.radio:hover, .checkbox:hover {
  color: #000;
}

input[type=radio].input-lg + .radio {
  font-size: 115%;
  padding-left: 2.1rem;
}

input[type=radio].input-lg + .radio:before {
  border: solid 1px #999;
  width: 26px;
  height: 26px;
}

input[type=radio].input-lg + .radio:after {
  background: #243D51;
  width: 16px;
  height: 16px;
  left: 5px;
  top: 5px;
}

.reveal-area {
  transition: all .3s ease-out;
  max-height: 550px;
  overflow: hidden;
}

input[type=radio].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #activate-card-btn,
input[type=checkbox].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #activate-card-btn {
  display: none;
}

input[type=radio].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #confirm-email-btn,
input[type=checkbox].reveal-checkbox:checked + label + .reveal-area ~ .btn-group #confirm-email-btn {
  display: inline-block;
}

input[type=radio].reveal-checkbox + label + .reveal-area ~ .btn-group #confirm-email-btn,
input[type=checkbox].reveal-checkbox + label + .reveal-area ~ .btn-group #confirm-email-btn {
  display: none;
}

input[type=radio].reveal-checkbox + label + .reveal-area ~ .btn-group #activate-card-btn,
input[type=checkbox].reveal-checkbox + label + .reveal-area ~ .btn-group #activate-card-btn {
  display: inline-block;
}

.toggle-hidden.reveal-area {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  border-bottom: 0;
}

.alert {
  padding: .8rem 1rem .8rem 2.4rem;
  font-size: 85%;
  clear: both;
  border-width: 1px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: .5rem 0;
  border-style: solid;
  color: #333;
  vertical-align: middle;
  line-height: 1.05;
  position: relative;
}

.alert li, .alert p {
  text-transform: none;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.alert p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert:before {
  font-size: 1rem;
  position: absolute;
  left: 1rem;
  top: .7rem;
}

@media (min-width: 481px) {
  .alert {
    font-size: 100%;
    padding: 1rem 1.5rem 1rem 3rem;
  }
  .alert:before {
    font-size: 1.35rem;
    left: 1rem;
  }
}

.alert.radius {
  border-radius: 0.6rem;
}

.alert strong {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.alert.alert-primary {
  border-color: #243D51;
  background-color: #243D51;
  color: #fff;
}

.alert.alert-primary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-primary h1, .alert.alert-primary h2, .alert.alert-primary h3, .alert.alert-primary h4, .alert.alert-primary h5, .alert.alert-primary p {
  color: #fff;
}

.alert.alert-primary h1:first-child, .alert.alert-primary h2:first-child, .alert.alert-primary h3:first-child, .alert.alert-primary h4:first-child, .alert.alert-primary h5:first-child, .alert.alert-primary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-primary h1:last-child, .alert.alert-primary h2:last-child, .alert.alert-primary h3:last-child, .alert.alert-primary h4:last-child, .alert.alert-primary h5:last-child, .alert.alert-primary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-primary a {
  color: #fff;
}

.alert.alert-secondary {
  border-color: #243D51;
  background-color: #A9A9A9;
  color: #fff;
}

.alert.alert-secondary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-secondary h1, .alert.alert-secondary h2, .alert.alert-secondary h3, .alert.alert-secondary h4, .alert.alert-secondary h5, .alert.alert-secondary p {
  color: #fff;
}

.alert.alert-secondary h1:first-child, .alert.alert-secondary h2:first-child, .alert.alert-secondary h3:first-child, .alert.alert-secondary h4:first-child, .alert.alert-secondary h5:first-child, .alert.alert-secondary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-secondary h1:last-child, .alert.alert-secondary h2:last-child, .alert.alert-secondary h3:last-child, .alert.alert-secondary h4:last-child, .alert.alert-secondary h5:last-child, .alert.alert-secondary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-secondary a {
  color: #fff;
}

.alert.alert-info {
  border-color: #A9A9A9;
  background-color: #f7f7f7;
  color: #A9A9A9;
}

.alert.alert-info:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-info h1, .alert.alert-info h2, .alert.alert-info h3, .alert.alert-info h4, .alert.alert-info h5, .alert.alert-info p {
  color: #A9A9A9;
}

.alert.alert-info h1:first-child, .alert.alert-info h2:first-child, .alert.alert-info h3:first-child, .alert.alert-info h4:first-child, .alert.alert-info h5:first-child, .alert.alert-info p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-info h1:last-child, .alert.alert-info h2:last-child, .alert.alert-info h3:last-child, .alert.alert-info h4:last-child, .alert.alert-info h5:last-child, .alert.alert-info p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-error {
  border-color: #d20808;
  background-color: #fee6e6;
  color: #d20808;
}

.alert.alert-error:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-error h1, .alert.alert-error h2, .alert.alert-error h3, .alert.alert-error h4, .alert.alert-error h5, .alert.alert-error p {
  color: #d20808;
}

.alert.alert-error h1:first-child, .alert.alert-error h2:first-child, .alert.alert-error h3:first-child, .alert.alert-error h4:first-child, .alert.alert-error h5:first-child, .alert.alert-error p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-error h1:last-child, .alert.alert-error h2:last-child, .alert.alert-error h3:last-child, .alert.alert-error h4:last-child, .alert.alert-error h5:last-child, .alert.alert-error p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-success {
  border-color: #3eb44a;
  background-color: #edf9ee;
  color: #3eb44a;
}

.alert.alert-success:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-success h1, .alert.alert-success h2, .alert.alert-success h3, .alert.alert-success h4, .alert.alert-success h5, .alert.alert-success p {
  color: #3eb44a;
}

.alert.alert-success h1:first-child, .alert.alert-success h2:first-child, .alert.alert-success h3:first-child, .alert.alert-success h4:first-child, .alert.alert-success h5:first-child, .alert.alert-success p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-success h1:last-child, .alert.alert-success h2:last-child, .alert.alert-success h3:last-child, .alert.alert-success h4:last-child, .alert.alert-success h5:last-child, .alert.alert-success p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert.alert-warning {
  border-color: #de8500;
  background-color: #fff5e5;
  color: #de8500;
}

.alert.alert-warning:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.alert.alert-warning h1, .alert.alert-warning h2, .alert.alert-warning h3, .alert.alert-warning h4, .alert.alert-warning h5, .alert.alert-warning p {
  color: #de8500;
}

.alert.alert-warning h1:first-child, .alert.alert-warning h2:first-child, .alert.alert-warning h3:first-child, .alert.alert-warning h4:first-child, .alert.alert-warning h5:first-child, .alert.alert-warning p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.alert.alert-warning h1:last-child, .alert.alert-warning h2:last-child, .alert.alert-warning h3:last-child, .alert.alert-warning h4:last-child, .alert.alert-warning h5:last-child, .alert.alert-warning p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert strong {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.alert ul, .alert ol {
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert-dismissible {
  padding-right: 2.5em;
  transition: all .3s ease;
}

.alert-dismissible:after {
  cursor: pointer;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  float: right;
  opacity: .4;
  display: block;
  text-align: center;
  position: absolute;
  right: 1.2rem;
  border-radius: 50%;
  width: 30px;
  line-height: 30px;
  margin-top: -15px;
  top: 50%;
  background-color: #fff;
}

.alert-dismissible:hover:after {
  opacity: 1;
}

.page-alert {
  margin: 100px auto 5px auto;
  max-width: 700px;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
button,
a,
label,
div,
span {
  /*&.btn-loading{
        &:before {
            margin-top:-1px;
            font-size: 150%;
            content:$icon-loading !important;
            animation:spin3d .75s infinite;
        }
    }*/
}

button.btn,
input[type="button"].btn,
input[type="reset"].btn,
input[type="submit"].btn,
button.btn,
a.btn,
label.btn,
div.btn,
span.btn {
  border: none;
  border-radius: 0;
  background: none;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  outline: none;
  text-align: center;
  border-radius: .2rem;
  line-height: 1.1;
  color: #fff;
  transition: all .3s linear;
  margin: .2em 0;
  padding: 14px 2.1rem;
  font-size: .95rem;
  margin: .3rem 0 .3rem;
  background-color: #243D51;
  color: #ffffff;
}

button.btn:disabled:not(.btn-success),
input[type="button"].btn:disabled:not(.btn-success),
input[type="reset"].btn:disabled:not(.btn-success),
input[type="submit"].btn:disabled:not(.btn-success),
button.btn:disabled:not(.btn-success),
a.btn:disabled:not(.btn-success),
label.btn:disabled:not(.btn-success),
div.btn:disabled:not(.btn-success),
span.btn:disabled:not(.btn-success) {
  cursor: not-allowed;
  opacity: 0.3;
}

button.btn:hover:not(:disabled),
input[type="button"].btn:hover:not(:disabled),
input[type="reset"].btn:hover:not(:disabled),
input[type="submit"].btn:hover:not(:disabled),
button.btn:hover:not(:disabled),
a.btn:hover:not(:disabled),
label.btn:hover:not(:disabled),
div.btn:hover:not(:disabled),
span.btn:hover:not(:disabled) {
  color: #ffffff;
  background-color: #05080a;
}

button.btn i,
input[type="button"].btn i,
input[type="reset"].btn i,
input[type="submit"].btn i,
button.btn i,
a.btn i,
label.btn i,
div.btn i,
span.btn i {
  margin-left: .1em;
  margin-right: .1em;
  margin-top: -2px;
  font-size: 85%;
}

button .btn-submit,
input[type="button"] .btn-submit,
input[type="reset"] .btn-submit,
input[type="submit"] .btn-submit,
button .btn-submit,
a .btn-submit,
label .btn-submit,
div .btn-submit,
span .btn-submit {
  min-width: 13rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #00adef;
}

button .form-loading .btn-submit:before,
input[type="button"] .form-loading .btn-submit:before,
input[type="reset"] .form-loading .btn-submit:before,
input[type="submit"] .form-loading .btn-submit:before,
button .form-loading .btn-submit:before,
a .form-loading .btn-submit:before,
label .form-loading .btn-submit:before,
div .form-loading .btn-submit:before,
span .form-loading .btn-submit:before {
  display: none;
}

button .success-message,
input[type="button"] .success-message,
input[type="reset"] .success-message,
input[type="submit"] .success-message,
button .success-message,
a .success-message,
label .success-message,
div .success-message,
span .success-message {
  display: none;
}

button.btn-success,
input[type="button"].btn-success,
input[type="reset"].btn-success,
input[type="submit"].btn-success,
button.btn-success,
a.btn-success,
label.btn-success,
div.btn-success,
span.btn-success {
  background-color: #3eb44a;
  color: #ffffff;
}

button.btn-success:hover:not(:disabled),
input[type="button"].btn-success:hover:not(:disabled),
input[type="reset"].btn-success:hover:not(:disabled),
input[type="submit"].btn-success:hover:not(:disabled),
button.btn-success:hover:not(:disabled),
a.btn-success:hover:not(:disabled),
label.btn-success:hover:not(:disabled),
div.btn-success:hover:not(:disabled),
span.btn-success:hover:not(:disabled) {
  color: #ffffff;
  background-color: #24682b;
}

button.btn-success span,
input[type="button"].btn-success span,
input[type="reset"].btn-success span,
input[type="submit"].btn-success span,
button.btn-success span,
a.btn-success span,
label.btn-success span,
div.btn-success span,
span.btn-success span {
  display: none;
}

button.btn-success span.success-message,
input[type="button"].btn-success span.success-message,
input[type="reset"].btn-success span.success-message,
input[type="submit"].btn-success span.success-message,
button.btn-success span.success-message,
a.btn-success span.success-message,
label.btn-success span.success-message,
div.btn-success span.success-message,
span.btn-success span.success-message {
  display: inline-block;
}

button.btn-success:before,
input[type="button"].btn-success:before,
input[type="reset"].btn-success:before,
input[type="submit"].btn-success:before,
button.btn-success:before,
a.btn-success:before,
label.btn-success:before,
div.btn-success:before,
span.btn-success:before {
  position: absolute;
  top: 30%;
  left: 1.2rem;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 110%;
}

button.btn-next,
input[type="button"].btn-next,
input[type="reset"].btn-next,
input[type="submit"].btn-next,
button.btn-next,
a.btn-next,
label.btn-next,
div.btn-next,
span.btn-next {
  margin: 2.5em 0 1em;
}

button.btn-next:after,
input[type="button"].btn-next:after,
input[type="reset"].btn-next:after,
input[type="submit"].btn-next:after,
button.btn-next:after,
a.btn-next:after,
label.btn-next:after,
div.btn-next:after,
span.btn-next:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-left: .4em;
  line-height: .6;
  margin-top: -1px;
}

button.btn-next:hover,
input[type="button"].btn-next:hover,
input[type="reset"].btn-next:hover,
input[type="submit"].btn-next:hover,
button.btn-next:hover,
a.btn-next:hover,
label.btn-next:hover,
div.btn-next:hover,
span.btn-next:hover {
  background-color: #318e3a;
}

button.btn-prev,
input[type="button"].btn-prev,
input[type="reset"].btn-prev,
input[type="submit"].btn-prev,
button.btn-prev,
a.btn-prev,
label.btn-prev,
div.btn-prev,
span.btn-prev {
  margin: 2.5em 0 1em;
}

button.btn-prev:before,
input[type="button"].btn-prev:before,
input[type="reset"].btn-prev:before,
input[type="submit"].btn-prev:before,
button.btn-prev:before,
a.btn-prev:before,
label.btn-prev:before,
div.btn-prev:before,
span.btn-prev:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-right: .4em;
  line-height: 0.6 !important;
  margin-top: -1px;
}

button .btn-group.btn-set,
input[type="button"] .btn-group.btn-set,
input[type="reset"] .btn-group.btn-set,
input[type="submit"] .btn-group.btn-set,
button .btn-group.btn-set,
a .btn-group.btn-set,
label .btn-group.btn-set,
div .btn-group.btn-set,
span .btn-group.btn-set {
  margin: 0;
}

button .btn-group.btn-set .btn,
input[type="button"] .btn-group.btn-set .btn,
input[type="reset"] .btn-group.btn-set .btn,
input[type="submit"] .btn-group.btn-set .btn,
button .btn-group.btn-set .btn,
a .btn-group.btn-set .btn,
label .btn-group.btn-set .btn,
div .btn-group.btn-set .btn,
span .btn-group.btn-set .btn {
  height: 100%;
  padding: 0.55em .9rem 0.55rem;
  font-size: .8rem;
  margin: .1rem 0;
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
  align-self: center;
  background-color: #eee;
  color: #ffffff;
  height: 42px;
  margin: 0 1px 0 0;
}

button .btn-group.btn-set .btn:hover:not(:disabled),
input[type="button"] .btn-group.btn-set .btn:hover:not(:disabled),
input[type="reset"] .btn-group.btn-set .btn:hover:not(:disabled),
input[type="submit"] .btn-group.btn-set .btn:hover:not(:disabled),
button .btn-group.btn-set .btn:hover:not(:disabled),
a .btn-group.btn-set .btn:hover:not(:disabled),
label .btn-group.btn-set .btn:hover:not(:disabled),
div .btn-group.btn-set .btn:hover:not(:disabled),
span .btn-group.btn-set .btn:hover:not(:disabled) {
  color: #ffffff;
  background-color: #bbbbbb;
}

button .btn-group.btn-set .btn:before, button .btn-group.btn-set .btn:after, button .btn-group.btn-set .btn i,
input[type="button"] .btn-group.btn-set .btn:before,
input[type="button"] .btn-group.btn-set .btn:after,
input[type="button"] .btn-group.btn-set .btn i,
input[type="reset"] .btn-group.btn-set .btn:before,
input[type="reset"] .btn-group.btn-set .btn:after,
input[type="reset"] .btn-group.btn-set .btn i,
input[type="submit"] .btn-group.btn-set .btn:before,
input[type="submit"] .btn-group.btn-set .btn:after,
input[type="submit"] .btn-group.btn-set .btn i,
button .btn-group.btn-set .btn:before,
button .btn-group.btn-set .btn:after,
button .btn-group.btn-set .btn i,
a .btn-group.btn-set .btn:before,
a .btn-group.btn-set .btn:after,
a .btn-group.btn-set .btn i,
label .btn-group.btn-set .btn:before,
label .btn-group.btn-set .btn:after,
label .btn-group.btn-set .btn i,
div .btn-group.btn-set .btn:before,
div .btn-group.btn-set .btn:after,
div .btn-group.btn-set .btn i,
span .btn-group.btn-set .btn:before,
span .btn-group.btn-set .btn:after,
span .btn-group.btn-set .btn i {
  color: #999;
}

button .btn-group.btn-set .btn:last-child,
input[type="button"] .btn-group.btn-set .btn:last-child,
input[type="reset"] .btn-group.btn-set .btn:last-child,
input[type="submit"] .btn-group.btn-set .btn:last-child,
button .btn-group.btn-set .btn:last-child,
a .btn-group.btn-set .btn:last-child,
label .btn-group.btn-set .btn:last-child,
div .btn-group.btn-set .btn:last-child,
span .btn-group.btn-set .btn:last-child {
  margin: 0;
}

button .btn-cancel,
input[type="button"] .btn-cancel,
input[type="reset"] .btn-cancel,
input[type="submit"] .btn-cancel,
button .btn-cancel,
a .btn-cancel,
label .btn-cancel,
div .btn-cancel,
span .btn-cancel {
  background-color: transparent;
  color: #ffffff;
  color: #666;
  border-radius: 1rem;
  font-weight: normal;
  border: 2px solid #A9A9A9;
  margin: 2.5em 0 1em;
  max-width: 140px;
}

button .btn-cancel:hover:not(:disabled),
input[type="button"] .btn-cancel:hover:not(:disabled),
input[type="reset"] .btn-cancel:hover:not(:disabled),
input[type="submit"] .btn-cancel:hover:not(:disabled),
button .btn-cancel:hover:not(:disabled),
a .btn-cancel:hover:not(:disabled),
label .btn-cancel:hover:not(:disabled),
div .btn-cancel:hover:not(:disabled),
span .btn-cancel:hover:not(:disabled) {
  color: #ffffff;
  background-color: transparent;
}

button .btn-cancel:hover, button .btn-cancel.active,
input[type="button"] .btn-cancel:hover,
input[type="button"] .btn-cancel.active,
input[type="reset"] .btn-cancel:hover,
input[type="reset"] .btn-cancel.active,
input[type="submit"] .btn-cancel:hover,
input[type="submit"] .btn-cancel.active,
button .btn-cancel:hover,
button .btn-cancel.active,
a .btn-cancel:hover,
a .btn-cancel.active,
label .btn-cancel:hover,
label .btn-cancel.active,
div .btn-cancel:hover,
div .btn-cancel.active,
span .btn-cancel:hover,
span .btn-cancel.active {
  background-color: #A9A9A9 !important;
  color: #fff;
}

button .btn-delete,
input[type="button"] .btn-delete,
input[type="reset"] .btn-delete,
input[type="submit"] .btn-delete,
button .btn-delete,
a .btn-delete,
label .btn-delete,
div .btn-delete,
span .btn-delete {
  border: none;
  border-radius: 0;
  background: none;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  outline: none;
  text-align: center;
  border-radius: .2rem;
  line-height: 1.1;
  color: #fff;
  transition: all .3s linear;
  margin: .2em 0;
  padding: 14px 2.1rem;
  font-size: .95rem;
  margin: .3rem 0 .3rem;
  background-color: #fa7979;
  color: #ffffff;
}

button .btn-delete:disabled:not(.btn-success),
input[type="button"] .btn-delete:disabled:not(.btn-success),
input[type="reset"] .btn-delete:disabled:not(.btn-success),
input[type="submit"] .btn-delete:disabled:not(.btn-success),
button .btn-delete:disabled:not(.btn-success),
a .btn-delete:disabled:not(.btn-success),
label .btn-delete:disabled:not(.btn-success),
div .btn-delete:disabled:not(.btn-success),
span .btn-delete:disabled:not(.btn-success) {
  cursor: not-allowed;
  opacity: 0.3;
}

button .btn-delete:hover:not(:disabled),
input[type="button"] .btn-delete:hover:not(:disabled),
input[type="reset"] .btn-delete:hover:not(:disabled),
input[type="submit"] .btn-delete:hover:not(:disabled),
button .btn-delete:hover:not(:disabled),
a .btn-delete:hover:not(:disabled),
label .btn-delete:hover:not(:disabled),
div .btn-delete:hover:not(:disabled),
span .btn-delete:hover:not(:disabled) {
  color: #ffffff;
  background-color: #f61717;
}

button .btn-delete i,
input[type="button"] .btn-delete i,
input[type="reset"] .btn-delete i,
input[type="submit"] .btn-delete i,
button .btn-delete i,
a .btn-delete i,
label .btn-delete i,
div .btn-delete i,
span .btn-delete i {
  margin-left: .1em;
  margin-right: .1em;
  margin-top: -2px;
  font-size: 85%;
}

button.btn.btn-block,
input[type="button"].btn.btn-block,
input[type="reset"].btn.btn-block,
input[type="submit"].btn.btn-block,
button.btn.btn-block,
a.btn.btn-block,
label.btn.btn-block,
div.btn.btn-block,
span.btn.btn-block {
  display: block;
  width: 100%;
}

button.btn.btn-sec,
input[type="button"].btn.btn-sec,
input[type="reset"].btn.btn-sec,
input[type="submit"].btn.btn-sec,
button.btn.btn-sec,
a.btn.btn-sec,
label.btn.btn-sec,
div.btn.btn-sec,
span.btn.btn-sec {
  background-color: #A9A9A9;
  color: #ffffff;
}

button.btn.btn-sec:hover:not(:disabled),
input[type="button"].btn.btn-sec:hover:not(:disabled),
input[type="reset"].btn.btn-sec:hover:not(:disabled),
input[type="submit"].btn.btn-sec:hover:not(:disabled),
button.btn.btn-sec:hover:not(:disabled),
a.btn.btn-sec:hover:not(:disabled),
label.btn.btn-sec:hover:not(:disabled),
div.btn.btn-sec:hover:not(:disabled),
span.btn.btn-sec:hover:not(:disabled) {
  color: #ffffff;
  background-color: #767676;
}

button.btn.btn-highlight,
input[type="button"].btn.btn-highlight,
input[type="reset"].btn.btn-highlight,
input[type="submit"].btn.btn-highlight,
button.btn.btn-highlight,
a.btn.btn-highlight,
label.btn.btn-highlight,
div.btn.btn-highlight,
span.btn.btn-highlight {
  background-color: #d20808;
  color: #ffffff;
}

button.btn.btn-highlight:hover:not(:disabled),
input[type="button"].btn.btn-highlight:hover:not(:disabled),
input[type="reset"].btn.btn-highlight:hover:not(:disabled),
input[type="submit"].btn.btn-highlight:hover:not(:disabled),
button.btn.btn-highlight:hover:not(:disabled),
a.btn.btn-highlight:hover:not(:disabled),
label.btn.btn-highlight:hover:not(:disabled),
div.btn.btn-highlight:hover:not(:disabled),
span.btn.btn-highlight:hover:not(:disabled) {
  color: #ffffff;
  background-color: #700404;
}

button.btn.btn-invert,
input[type="button"].btn.btn-invert,
input[type="reset"].btn.btn-invert,
input[type="submit"].btn.btn-invert,
button.btn.btn-invert,
a.btn.btn-invert,
label.btn.btn-invert,
div.btn.btn-invert,
span.btn.btn-invert {
  background-color: transparent;
  color: #ffffff;
  color: #666;
  border-radius: 1rem;
  font-weight: normal;
  border: 2px solid #A9A9A9;
}

button.btn.btn-invert:hover:not(:disabled),
input[type="button"].btn.btn-invert:hover:not(:disabled),
input[type="reset"].btn.btn-invert:hover:not(:disabled),
input[type="submit"].btn.btn-invert:hover:not(:disabled),
button.btn.btn-invert:hover:not(:disabled),
a.btn.btn-invert:hover:not(:disabled),
label.btn.btn-invert:hover:not(:disabled),
div.btn.btn-invert:hover:not(:disabled),
span.btn.btn-invert:hover:not(:disabled) {
  color: #ffffff;
  background-color: transparent;
}

button.btn.btn-invert:hover, button.btn.btn-invert.active,
input[type="button"].btn.btn-invert:hover,
input[type="button"].btn.btn-invert.active,
input[type="reset"].btn.btn-invert:hover,
input[type="reset"].btn.btn-invert.active,
input[type="submit"].btn.btn-invert:hover,
input[type="submit"].btn.btn-invert.active,
button.btn.btn-invert:hover,
button.btn.btn-invert.active,
a.btn.btn-invert:hover,
a.btn.btn-invert.active,
label.btn.btn-invert:hover,
label.btn.btn-invert.active,
div.btn.btn-invert:hover,
div.btn.btn-invert.active,
span.btn.btn-invert:hover,
span.btn.btn-invert.active {
  background-color: #A9A9A9 !important;
  color: #fff;
}

button.btn.btn-xl,
input[type="button"].btn.btn-xl,
input[type="reset"].btn.btn-xl,
input[type="submit"].btn.btn-xl,
button.btn.btn-xl,
a.btn.btn-xl,
label.btn.btn-xl,
div.btn.btn-xl,
span.btn.btn-xl {
  padding: 0.9em 4.6rem;
  font-size: 1.25rem;
  margin: .8rem 0 1rem;
}

button.btn.btn-lg,
input[type="button"].btn.btn-lg,
input[type="reset"].btn.btn-lg,
input[type="submit"].btn.btn-lg,
button.btn.btn-lg,
a.btn.btn-lg,
label.btn.btn-lg,
div.btn.btn-lg,
span.btn.btn-lg {
  padding: 18px 3.8rem;
  font-size: 1.05rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

button.btn.btn-sm,
input[type="button"].btn.btn-sm,
input[type="reset"].btn.btn-sm,
input[type="submit"].btn.btn-sm,
button.btn.btn-sm,
a.btn.btn-sm,
label.btn.btn-sm,
div.btn.btn-sm,
span.btn.btn-sm {
  padding: 0.55em .9rem 0.55rem;
  font-size: .8rem;
  margin: .1rem 0;
}

button.btn.btn-xs,
input[type="button"].btn.btn-xs,
input[type="reset"].btn.btn-xs,
input[type="submit"].btn.btn-xs,
button.btn.btn-xs,
a.btn.btn-xs,
label.btn.btn-xs,
div.btn.btn-xs,
span.btn.btn-xs {
  padding: .425em .65rem;
  font-size: .725rem;
  margin: 0;
}

button.btn.btn-dropdown,
input[type="button"].btn.btn-dropdown,
input[type="reset"].btn.btn-dropdown,
input[type="submit"].btn.btn-dropdown,
button.btn.btn-dropdown,
a.btn.btn-dropdown,
label.btn.btn-dropdown,
div.btn.btn-dropdown,
span.btn.btn-dropdown {
  padding-left: .85em;
  padding-right: .7em;
  margin-left: 1px !important;
  display: inline-block;
  height: 100%;
  min-width: 40px;
  vertical-align: middle;
}

button.btn.btn-dropdown:before,
input[type="button"].btn.btn-dropdown:before,
input[type="reset"].btn.btn-dropdown:before,
input[type="submit"].btn.btn-dropdown:before,
button.btn.btn-dropdown:before,
a.btn.btn-dropdown:before,
label.btn.btn-dropdown:before,
div.btn.btn-dropdown:before,
span.btn.btn-dropdown:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  transition: transform .2s ease;
  display: inline-block;
  margin: 0px;
  margin-bottom: 2px;
  line-height: 1.1;
}

button.btn.btn-dropdown:hover:before,
input[type="button"].btn.btn-dropdown:hover:before,
input[type="reset"].btn.btn-dropdown:hover:before,
input[type="submit"].btn.btn-dropdown:hover:before,
button.btn.btn-dropdown:hover:before,
a.btn.btn-dropdown:hover:before,
label.btn.btn-dropdown:hover:before,
div.btn.btn-dropdown:hover:before,
span.btn.btn-dropdown:hover:before {
  transform: rotate(-90deg);
}

button.btn.btn-cart-remove,
input[type="button"].btn.btn-cart-remove,
input[type="reset"].btn.btn-cart-remove,
input[type="submit"].btn.btn-cart-remove,
button.btn.btn-cart-remove,
a.btn.btn-cart-remove,
label.btn.btn-cart-remove,
div.btn.btn-cart-remove,
span.btn.btn-cart-remove {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

button.btn.btn-cart-remove:before,
input[type="button"].btn.btn-cart-remove:before,
input[type="reset"].btn.btn-cart-remove:before,
input[type="submit"].btn.btn-cart-remove:before,
button.btn.btn-cart-remove:before,
a.btn.btn-cart-remove:before,
label.btn.btn-cart-remove:before,
div.btn.btn-cart-remove:before,
span.btn.btn-cart-remove:before {
  padding-right: 10px;
  color: #d20808;
  float: left;
  font-size: 135%;
  line-height: .9;
}

button.btn.btn-cart-add:before,
input[type="button"].btn.btn-cart-add:before,
input[type="reset"].btn.btn-cart-add:before,
input[type="submit"].btn.btn-cart-add:before,
button.btn.btn-cart-add:before,
a.btn.btn-cart-add:before,
label.btn.btn-cart-add:before,
div.btn.btn-cart-add:before,
span.btn.btn-cart-add:before {
  padding-right: 10px;
  color: #aaa;
  float: left;
  font-size: 135%;
  line-height: .9;
}

button.btn.btn-facebook,
input[type="button"].btn.btn-facebook,
input[type="reset"].btn.btn-facebook,
input[type="submit"].btn.btn-facebook,
button.btn.btn-facebook,
a.btn.btn-facebook,
label.btn.btn-facebook,
div.btn.btn-facebook,
span.btn.btn-facebook {
  padding: 0.9em 4.6rem;
  font-size: 1.25rem;
  margin: .8rem 0 1rem;
  background-color: #49639f;
  color: #ffffff;
  color: #fff;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  margin: .8em 0 1em;
  padding-left: 2.5em;
  padding-right: 0;
  width: 100%;
  max-width: 400px;
  height: auto;
  display: table;
  position: relative;
  vertical-align: middle;
}

button.btn.btn-facebook:hover:not(:disabled),
input[type="button"].btn.btn-facebook:hover:not(:disabled),
input[type="reset"].btn.btn-facebook:hover:not(:disabled),
input[type="submit"].btn.btn-facebook:hover:not(:disabled),
button.btn.btn-facebook:hover:not(:disabled),
a.btn.btn-facebook:hover:not(:disabled),
label.btn.btn-facebook:hover:not(:disabled),
div.btn.btn-facebook:hover:not(:disabled),
span.btn.btn-facebook:hover:not(:disabled) {
  color: #ffffff;
  background-color: #293759;
}

button.btn.btn-facebook:before,
input[type="button"].btn.btn-facebook:before,
input[type="reset"].btn.btn-facebook:before,
input[type="submit"].btn.btn-facebook:before,
button.btn.btn-facebook:before,
a.btn.btn-facebook:before,
label.btn.btn-facebook:before,
div.btn.btn-facebook:before,
span.btn.btn-facebook:before {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: .4em .5em;
  display: table-cell;
  padding-right: 10px;
  color: #fff;
  font-size: 31px;
  margin: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.btn-group {
  position: relative;
  clear: both;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .9rem 0 2rem;
  padding: .45rem 0 1rem;
}

.btn-group::after {
  clear: both;
  content: "";
  display: table;
}

.btn-group .btn-next, .btn-group .btn-prev {
  height: 54px;
}

.btn-group .btn {
  width: 100%;
  margin: .2rem 0 .5rem;
}

.btn-group .btn-next, .btn-group .btn-cancel {
  align-self: flex-end;
  margin-left: auto !important;
}

.btn-group .btn {
  width: 100%;
}

.btn-group .add-checked-to-cart {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}

@media (min-width: 481px) {
  .btn-group .btn {
    width: auto;
    margin: 1rem 0;
  }
  .btn-group .add-checked-to-cart {
    -moz-box-flex: 0 0 auto  ;
    -webkit-flex: 0 0 auto  ;
    -ms-flex: 0 0 auto  ;
    flex: 0 0 auto  ;
    max-width: auto;
    width: 100%;
    height: 100%;
    margin-left: auto;
  }
  .btn-group .btn + .btn {
    margin-left: 5px;
  }
  .btn-group .btn + .btn.btn-block {
    margin-left: 0;
  }
}

.btn-down-arrow {
  width: 100%;
  display: block;
}

.btn-down-arrow:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 30px;
  padding: 30px;
  display: block;
  color: #243D51;
}

.btn-down-arrow:hover:before {
  color: #A9A9A9;
}

.aux-link {
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #eee;
  margin: 0 0 0 5%;
  padding: 0 0 0 5%;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 10px 0 15px;
}

table tr,
table td,
table th {
  vertical-align: middle;
}

table tr {
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}

table td {
  font-weight: normal;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  padding: 10px;
}

table td i[class*=card]:before {
  font-size: 2.9em;
}

table thead tr {
  border-top: none;
  border-bottom: 1px solid #d9d9d9;
  font-weight: normal;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #888;
}

table tr th {
  padding: 10px;
  margin: 0;
  border-bottom: 2px solid #999;
  font-weight: normal;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: left;
}

.table-selectable tbody tr:hover {
  background-color: #eee;
}

.table-selectable a {
  display: block;
}

.table-selectable a.btn {
  display: inline-block;
}

.table-selectable .icon-pdf-file-symbol {
  font-size: 19px;
  margin: -1px 0;
}

table.table-xl td {
  padding: 12px 12px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-xl .btn {
  margin: -2px 0;
}

table.table-lg {
  margin: 10px 0;
  font-size: 15px;
}

table.table-lg td {
  padding: 12px 12px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-lg .btn {
  margin: -2px 0;
}

table.table-md .btn {
  margin: -2px 0;
}

table.table-sm {
  font-size: 14px;
}

table.table-sm tr th {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-size: 13px;
  padding: 4px 8px;
}

table.table-sm td {
  padding: 4px 8px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-sm .btn {
  margin: -2px 0;
}

table.table-xs {
  margin: 5px 0;
  font-size: 14px;
}

table.table-xs td {
  padding: 2px 5px;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

table.table-xs .btn {
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s linear;
  display: block;
  z-index: 99999;
  margin-top: -24px;
  margin-left: -12px;
  width: 24px;
  height: 24px;
  border: 0.2rem solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  animation: spin 1s infinite linear;
}

.loading--double {
  border-style: double;
  border-width: .5rem;
}

.loading.btn-loading {
  position: absolute;
  top: 70%;
  left: 2rem;
}

.page-loading {
  position: fixed;
  left: 50%;
  z-index: 99999;
  top: 40%;
  color: #333;
}

.page-loading.loading {
  width: 38px;
  height: 38px;
  margin-top: -38px;
  margin-left: -19px;
  border: 0.25rem solid rgba(50, 50, 50, 0.1);
  border-top-color: #333;
}

.page-loading.loading-text {
  width: 228px;
  font-style: italic;
  font-size: .8rem;
  margin-top: .3rem;
  color: #333;
  text-align: center;
  margin-left: -114px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s linear;
}

.home .page-loading {
  color: #fff;
}

.home .page-loading.loading-text {
  color: #fff;
}

.home .page-loading.loading {
  border: 0.25rem solid rgba(250, 250, 250, 0.15);
  border-top-color: #eee;
}

.form-loading .loading, .is-loading {
  visibility: visible !important;
  opacity: 1 !important;
}

input[type="color"].parsley-success, input[type="date"].parsley-success, input[type="datetime"].parsley-success, input[type="datetime-local"].parsley-success, input[type="email"].parsley-success, input[type="month"].parsley-success, input[type="number"].parsley-success, input[type="password"].parsley-success, input[type="search"].parsley-success, input[type="tel"].parsley-success, input[type="text"].parsley-success, input[type="time"].parsley-success, input[type="url"].parsley-success, input[type="week"].parsley-success, input:not([type]).parsley-success, textarea.parsley-success, .inputElement.parsley-success {
  border: 1px solid #3eb44a;
}

input[type="color"].parsley-error, input[type="date"].parsley-error, input[type="datetime"].parsley-error, input[type="datetime-local"].parsley-error, input[type="email"].parsley-error, input[type="month"].parsley-error, input[type="number"].parsley-error, input[type="password"].parsley-error, input[type="search"].parsley-error, input[type="tel"].parsley-error, input[type="text"].parsley-error, input[type="time"].parsley-error, input[type="url"].parsley-error, input[type="week"].parsley-error, input:not([type]).parsley-error, textarea.parsley-error, .inputElement.parsley-error {
  border: 1px solid white;
}

.parsley-error .checkbox:after {
  border: 1px solid white;
}

.parsley-error .select-holder {
  border: 1px solid white;
}

.parsley-errors-list {
  font-size: 0.9em;
  line-height: 0.9em;
  list-style-type: none;
  margin: 2px 0 3px;
  opacity: 0;
  padding: 0;
  transition: all 0.3s ease 0s;
}

.parsley-errors-list.filled {
  opacity: 1;
  margin: 0 0 -4px 0;
  position: relative;
  display: block;
  width: 100%;
  background: #fee5e5;
  color: #f84848;
  padding: 6px 2px 0px;
  font-size: 13px;
  text-align: left;
  display: block;
  border-radius: 0 0 4px 4px;
}

.parsley-errors-list.filled li {
  display: block;
}

.form-row.parsley-error {
  margin-bottom: 0;
}

.parsley-error input[type=radio].radio-btn:required + label, .parsley-error input[type=checkbox].radio-btn:required + label {
  border: 3px solid #f84848;
}

p.validate {
  margin: -.6rem auto .1rem 0;
  display: none;
  color: #fff !important;
  background: #f84848;
  padding: .4rem .4rem;
  font-size: .75rem;
  width: 100%;
  text-align: left;
  position: relative;
  line-height: 1;
  border-radius: 0 0 .2rem .2rem;
}

@media (min-width: 580px) {
  p.validate {
    margin: -.1rem auto -1rem 0;
  }
}

p.validate:before {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 6px solid #f84848;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: absolute;
  top: -6px;
  left: 10px;
}

p.validate else {
  display: block;
  width: 100%;
}

.field-invalid input, .field-invalid .select-holder, .field-invalid label.radio:before, .field-invalid label.checkbox:after {
  border-color: #f84848 !important;
  background: #fff7f8 !important;
  color: #c91032 !important;
}

.field-invalid::-webkit-input-placeholder {
  color: #ff676b;
}

.field-invalid:-moz-placeholder {
  /* Firefox 18- */
  color: #ff676b;
}

.field-invalid::-moz-placeholder {
  /* Firefox 19+ */
  color: #ff676b;
}

.field-invalid:-ms-input-placeholder {
  color: #ff676b;
}

.field-invalid p.validate {
  display: block;
}

dl {
  margin: 1rem 0;
}

dl dt, dl .dt {
  border-radius: .8rem;
  cursor: pointer;
  font-weight: normal;
  transition: all 0.3s ease;
  margin: 0 0 0rem;
  position: relative;
  color: #fff;
  padding: .5rem 1.2rem .6rem 0rem;
}

@media (min-width: 1000px) {
  dl dt, dl .dt {
    padding: .5rem 0.6rem;
  }
}

dl dt:after, dl .dt:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  transition: all 0.3s ease;
  display: block;
  padding: 1rem .5rem;
  position: absolute;
  right: 0;
  top: .6rem;
  text-align: center;
  color: #444;
  font-size: 1.1rem;
  line-height: 1;
}

@media (min-width: 1000px) {
  dl dt:after, dl .dt:after {
    padding: 1rem 1.3rem;
  }
}

dl dt, dl .dt, dl dd, dl .dd {
  opacity: .75;
}

dl dt.expand, dl .dt.expand, dl dd.expand, dl .dd.expand {
  opacity: 1;
}

dl dt.expand:after, dl .dt.expand:after {
  transform: rotate(180deg);
}

dl dd, dl .dd {
  border-bottom: 2px solid #ddd;
  margin: 0 0 0;
  height: auto;
  overflow: hidden;
  position: relative;
  max-height: 0;
  font-size: 1rem;
  transition: all 0.3s ease;
}

@media (min-width: 1000px) {
  dl dd, dl .dd {
    padding: 0 2rem .2rem;
  }
}

dl dd dt:after, dl dd .dt:after, dl .dd dt:after, dl .dd .dt:after {
  color: #777;
  font-size: 1rem;
  padding: .8rem 1.5rem;
}

dl dd.expand, dl .dd.expand {
  max-height: 3000px;
}

dl .dd:last-child {
  border-bottom: none;
}

html,
body,
body,
.sidebar-pusher,
.content-container {
  height: 100%;
}

.content-container {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.content-container,
.content-container-inner {
  position: relative;
}

body {
  position: relative;
  overflow: hidden;
}

.sidebar-pusher {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1000;
  transition: transform 0.5s;
  overflow: hidden;
}

@media (max-width: 767px) {
  .sidebar-pusher::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    content: '';
    opacity: 0;
    visibility: hidden;
    transition: ease all 0.5s;
  }
  [class*=sidebar-open-] .sidebar-pusher::after {
    opacity: 1;
    visibility: visible;
  }
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 300px;
  height: 100%;
  z-index: 1;
  transition: all 0.5s;
}

.sidebar.sidebar-right {
  left: auto;
  right: 0;
}

.sidebar.sidebar-right::after {
  right: auto;
  left: 0;
}

[class*=sidebar-open-] .sidebar {
  visibility: visible;
  transition: ease transform 0.5s;
  transform: scale(1, 1);
}

/* Effect: Push-Left*/
@media (min-width: 767px) {
  .sidebar {
    z-index: 9999;
  }
  .sidebar-open-left .sidebar.sidebar-left {
    transform: translate3d(0, 0, 0);
  }
  .sidebar-open-left .sidebar.sidebar-right {
    transform: translate3d(100%, 0, 0);
  }
  .sidebar-open-right .sidebar.sidebar-left {
    transform: translate3d(-100%, 0, 0);
  }
  .sidebar-open-right .sidebar.sidebar-right {
    transform: translate3d(0, 0, 0);
  }
  .sidebar-left {
    transform: translate3d(-100%, 0, 0);
  }
  .sidebar-right {
    transform: translate3d(100%, 0, 0);
  }
}

@media (max-width: 767px) {
  [class*=sidebar-open-] .sidebar {
    z-index: 0;
  }
  /* Effect: Push-Right*/
  .sidebar-open-left .sidebar-pusher {
    transform: translate3d(300px, 0, 0);
  }
  .sidebar-open-right .sidebar-pusher {
    transform: translate3d(-300px, 0, 0);
  }
  .sidebar {
    transform: translate3d(0, 0, 0) scale(0.9, 0.9);
  }
  .sidebar.cart {
    transform: translate3d(0, 0, 0) scale(0.9, 0.9);
  }
}

[class*=sidebar-open-] .sidebar {
  visibility: visible;
  transition: transform 0.5s;
}

.sidebar::after {
  display: none;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .sidebar-pusher,
.no-js .sidebar-pusher {
  padding-left: 300px;
}

.search-bar {
  width: 100%;
  margin: 5px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.search-bar input {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
  color: #A9A9A9;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: 0;
  height: 45px;
  border-right: none;
  background-color: #f4f4f4;
}

.search-bar button.btn {
  width: 50px;
  height: 45px;
  border: none;
  border-radius: 0;
  background: none;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  outline: none;
  text-align: center;
  border-radius: .2rem;
  line-height: 1.1;
  color: #fff;
  transition: all .3s linear;
  margin: .2em 0;
  padding: 14px 2.1rem;
  font-size: .95rem;
  margin: .3rem 0 .3rem;
  background-color: #243D51;
  color: #ffffff;
  border: none;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.search-bar button.btn:disabled:not(.btn-success) {
  cursor: not-allowed;
  opacity: 0.3;
}

.search-bar button.btn:hover:not(:disabled) {
  color: #ffffff;
  background-color: #05080a;
}

.search-bar button.btn i {
  margin-left: .1em;
  margin-right: .1em;
  margin-top: -2px;
  font-size: 85%;
}

.search-bar button.btn:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: relative;
  font-size: 21px;
}

.search-bar button.btn:hover {
  background-color: #A9A9A9;
}

.popupOpen {
  overflow-y: auto;
}

.popupHolder {
  width: calc(100% - 20px);
  background-color: #fff;
  min-height: calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  top: 10px;
  left: 10px;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  box-shadow: 0 0 10px #000;
  transition: all .2s linear;
  transform: scale(0.1, 0.1);
}

.popupHolder img {
  text-align: center;
  margin: 2rem auto 0 auto;
  width: 100% !important;
  max-width: 1500px;
  transition: all .2s linear;
}

.popupHolder:before {
  cursor: pointer;
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  display: inline-block;
  padding: .95rem;
  text-shadow: 0 0 1px #fff;
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
}

@media (min-width: 420px) {
  .popupHolder:before {
    padding: 1.25rem;
  }
}

.popupHolder:hover:before {
  color: #333;
}

.popupOpen .popupHolder {
  visibility: visible;
  opacity: 1;
  z-index: 99999;
  transform: scale(1, 1);
}

.zoom-image:hover {
  opacity: .9;
}

.mobileapp-only {
  display: none !important;
}

.mobileapp .mobileapp-only {
  display: block !important;
}

.popup-holder {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  z-index: 999999;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s linear;
}

.popup-close {
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #243D51;
  text-align: center;
  cursor: pointer;
  line-height: 1.1;
  border-radius: 50%;
  border: 0.1rem solid #243D51;
  background-color: #fff;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  width: 95%;
  max-width: 800px;
  background-color: #fff;
  padding: 3% 4%;
}

/*.open-popup{*/
.popup-holder {
  visibility: visible;
  opacity: 1;
}

/*}*/
.ngdialog.ngdialog-theme-default {
  padding: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  min-width: 310px;
  max-width: 600px;
  width: 95%;
  padding: 1.5rem .8rem 0 .8rem;
}

@media (min-width: 481px) {
  .ngdialog.ngdialog-theme-default .ngdialog-content {
    padding: 1.5rem 1.8rem 0 1.8rem;
  }
}

.ngdialog.ngdialog-theme-default .ngdialog-content .btn-submit {
  width: 100%;
}

.ngdialog.ngdialog-theme-default .ngdialog-close {
  padding: .2rem .6rem;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  font-size: 2rem;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:focus {
  outline: none;
}

.ngdialog-content {
  overflow: auto;
  position: fixed;
  width: 100%;
  top: 20px;
}

.rating {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: .5rem 0 1rem;
  padding: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-content: center;
  justify-content: center;
}

.rating li {
  margin: 0;
  padding: 0;
}

.rating li {
  flex: 1;
  font-size: 1.1rem;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #333;
  cursor: pointer;
  color: #333;
  opacity: .3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .1rem;
  transition: all .15s linear;
}

.rating li:before {
  content: '';
  float: left;
  padding-top: 100%;
}

@media (min-width: 1000px) {
  .rating li {
    font-size: .7rem;
  }
}

@media (min-width: 1100px) {
  .rating li {
    font-size: .7rem;
  }
}

@media (min-width: 1500px) {
  .rating li {
    font-size: 1.1rem;
  }
}

.rating li:hover, .rating li.filled {
  background-color: #243D51;
  color: #fff;
  opacity: 1;
  /*&+ li {
              opacity:.90;
              color:$main-color;
          }
          &+ li + li{
              opacity:.80;
              color:$main-color;
          }
          &+ li + li + li{
              opacity:.70;
              color:$main-color;
          }
          &+ li + li + li + li{
              opacity:.60;
              color:$main-color;
          }*/
}

.rating li:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.rating.readonly .filled {
  color: #666;
}

.ratings-disabled {
  opacity: .6;
}

.ratings-disabled li {
  pointer-events: none;
}

.dropmenu-trigger {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;
  transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
}

.dropmenu-trigger a {
  color: #fff;
}

.dropmenu-open .dropmenu-holder {
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  top: 4rem;
}

.dropmenu-holder {
  transition: all .2s linear;
  visibility: hidden;
  opacity: 0;
  top: 3rem;
  position: absolute;
  right: 0;
  z-index: 99;
  width: 100%;
  min-width: 200px;
  border-radius: .3rem;
  background: rgba(34, 34, 34, 0.95);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.dropmenu-holder:before {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 0.6rem solid rgba(34, 34, 34, 0.95);
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  position: absolute;
  right: 15%;
  top: -.6rem;
}

@media (min-width: 1100px) {
  .dropmenu-holder:before {
    right: auto;
    left: 50%;
  }
}

.dropmenu-holder ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  padding: .5rem 0;
}

.dropmenu-holder ul li {
  margin: 0;
  padding: 0;
}

.dropmenu-holder ul li {
  display: block;
  width: 100%;
  border-top: 1px solid #333;
  font-size: .85rem;
  line-height: 1;
}

.dropmenu-holder ul li a {
  display: block;
  line-height: 1;
  width: 100%;
  padding: .6rem 1rem;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.01);
  transition: all .2s linear;
}

.dropmenu-holder ul li a:hover {
  background-color: black;
}

.dropmenu-holder ul li a i {
  margin: -.2rem .2rem 0  0;
  font-size: 1.1rem;
  display: inline-block;
}

.dropmenu-holder ul li:first-child {
  border: none;
}

.badge {
  padding: .5rem;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: normal;
  margin: .5rem 0;
  border-style: solid;
  color: #333;
  background-color: #A9A9A9;
  color: #fff;
  vertical-align: middle;
  line-height: 1.1;
  position: relative;
  width: auto;
  border-radius: 0.6rem;
  font-size: 72.5%;
}

.badge:before {
  font-size: 1.1rem;
  position: absolute;
  left: 1.2rem;
  top: 1.05rem;
}

.badge:before {
  font-size: .725rem;
  left: .4rem;
  top: .35rem;
}

.badge.no-radius {
  border-radius: 0;
}

.badge.badge-primary {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #243D51;
  background-color: #243D51;
  color: #fff;
}

.badge.badge-primary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-primary h1, .badge.badge-primary h2, .badge.badge-primary h3, .badge.badge-primary h4, .badge.badge-primary h5, .badge.badge-primary p {
  color: #fff;
}

.badge.badge-primary h1:first-child, .badge.badge-primary h2:first-child, .badge.badge-primary h3:first-child, .badge.badge-primary h4:first-child, .badge.badge-primary h5:first-child, .badge.badge-primary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-primary h1:last-child, .badge.badge-primary h2:last-child, .badge.badge-primary h3:last-child, .badge.badge-primary h4:last-child, .badge.badge-primary h5:last-child, .badge.badge-primary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-primary a {
  color: #fff;
}

.badge.badge-secondary {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #243D51;
  background-color: #A9A9A9;
  color: #fff;
}

.badge.badge-secondary:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-secondary h1, .badge.badge-secondary h2, .badge.badge-secondary h3, .badge.badge-secondary h4, .badge.badge-secondary h5, .badge.badge-secondary p {
  color: #fff;
}

.badge.badge-secondary h1:first-child, .badge.badge-secondary h2:first-child, .badge.badge-secondary h3:first-child, .badge.badge-secondary h4:first-child, .badge.badge-secondary h5:first-child, .badge.badge-secondary p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-secondary h1:last-child, .badge.badge-secondary h2:last-child, .badge.badge-secondary h3:last-child, .badge.badge-secondary h4:last-child, .badge.badge-secondary h5:last-child, .badge.badge-secondary p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-secondary a {
  color: #fff;
}

.badge.badge-info {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #A9A9A9;
  background-color: #f7f7f7;
  color: #A9A9A9;
}

.badge.badge-info:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-info h1, .badge.badge-info h2, .badge.badge-info h3, .badge.badge-info h4, .badge.badge-info h5, .badge.badge-info p {
  color: #A9A9A9;
}

.badge.badge-info h1:first-child, .badge.badge-info h2:first-child, .badge.badge-info h3:first-child, .badge.badge-info h4:first-child, .badge.badge-info h5:first-child, .badge.badge-info p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-info h1:last-child, .badge.badge-info h2:last-child, .badge.badge-info h3:last-child, .badge.badge-info h4:last-child, .badge.badge-info h5:last-child, .badge.badge-info p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-error {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #f84848;
  background-color: #feefef;
  color: #f84848;
}

.badge.badge-error:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-error h1, .badge.badge-error h2, .badge.badge-error h3, .badge.badge-error h4, .badge.badge-error h5, .badge.badge-error p {
  color: #f84848;
}

.badge.badge-error h1:first-child, .badge.badge-error h2:first-child, .badge.badge-error h3:first-child, .badge.badge-error h4:first-child, .badge.badge-error h5:first-child, .badge.badge-error p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-error h1:last-child, .badge.badge-error h2:last-child, .badge.badge-error h3:last-child, .badge.badge-error h4:last-child, .badge.badge-error h5:last-child, .badge.badge-error p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-success {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #3eb44a;
  background-color: #edf9ee;
  color: #3eb44a;
}

.badge.badge-success:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-success h1, .badge.badge-success h2, .badge.badge-success h3, .badge.badge-success h4, .badge.badge-success h5, .badge.badge-success p {
  color: #3eb44a;
}

.badge.badge-success h1:first-child, .badge.badge-success h2:first-child, .badge.badge-success h3:first-child, .badge.badge-success h4:first-child, .badge.badge-success h5:first-child, .badge.badge-success p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-success h1:last-child, .badge.badge-success h2:last-child, .badge.badge-success h3:last-child, .badge.badge-success h4:last-child, .badge.badge-success h5:last-child, .badge.badge-success p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.badge.badge-warning {
  padding: .3rem .5rem .3rem 1.3rem;
  border-color: #de8500;
  background-color: #fff5e5;
  color: #de8500;
}

.badge.badge-warning:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.badge.badge-warning h1, .badge.badge-warning h2, .badge.badge-warning h3, .badge.badge-warning h4, .badge.badge-warning h5, .badge.badge-warning p {
  color: #de8500;
}

.badge.badge-warning h1:first-child, .badge.badge-warning h2:first-child, .badge.badge-warning h3:first-child, .badge.badge-warning h4:first-child, .badge.badge-warning h5:first-child, .badge.badge-warning p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.badge.badge-warning h1:last-child, .badge.badge-warning h2:last-child, .badge.badge-warning h3:last-child, .badge.badge-warning h4:last-child, .badge.badge-warning h5:last-child, .badge.badge-warning p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

/*layout*/
.home-brick {
  margin: 0rem auto;
  max-width: 480px;
}

@media (min-width: 1000px) {
  .home-brick {
    margin: 1.5rem auto;
  }
}

.home-brick .brick.mysps-brick a:after {
  content: "";
}

.home-brick .brick {
  width: 100%;
  margin: 1% 0;
  height: 75px;
}

.home-brick .brick.masonry-brick-lge {
  height: 100px;
}

@media (min-width: 767px) {
  .home-brick .brick {
    height: 85px;
  }
  .home-brick .brick.masonry-brick-lge {
    height: 120px;
  }
}

.home-brick .brick img {
  width: 100%;
}

.home-brick .brick a {
  display: block;
  height: 100%;
  transform: scale(1);
  opacity: 1;
  transition: all .2s ease;
}

.home-brick .brick a:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #ddd;
  float: right;
  position: absolute;
  z-index: 1;
  margin-top: -19px;
  top: 50%;
  right: 25px;
  font-size: 38px;
  opacity: .8;
}

.home-brick .brick a:hover {
  opacity: .8;
  z-index: 99;
  position: relative;
}

@media (min-width: 767px) {
  .home-brick .brick a:hover {
    transform: scale(1.05);
  }
}

.home-brick .brick a:hover:after {
  opacity: 1;
  z-index: 99;
  position: relative;
}

@media (min-width: 767px) {
  .home-brick .brick a:hover:after {
    transform: scale(1.05);
  }
}

.home-brick .brick a.invert-block h4 {
  color: #000;
}

.home-brick .brick a.invert-block .header {
  background-color: #fff !important;
}

.home-brick h4 {
  position: absolute;
  top: 15px;
  color: #fff;
  padding: 0 18px;
  max-width: 85%;
  line-height: 1em;
  z-index: 2;
  font-weight: normal;
}

.home-brick .header {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  opacity: .95;
  background-color: rgba(36, 61, 81, 0.95);
}

.home-brick .header h3,
.home-brick .header h4,
.home-brick .header p {
  color: #fff;
  margin: 0;
  padding: 0;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.home-brick .header.header-img {
  background-position: right center;
  background-size: cover;
}

.masonry-brick.loaded {
  display: block;
}

.invert-title {
  display: none !important;
}

.invert-block h4, .invert-block span {
  display: none !important;
}

.invert-block .invert-title {
  display: block !important;
}

.home .content-container {
  position: absolute;
  top: 0;
  background-image: url(/assets/images/driver-new.jpg);
  left: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 -60px;
  width: 100%;
  background-color: #000;
}

@media (min-width: 767px) {
  .home .content-container {
    background-position: 0 -90px;
  }
}

.image-header.is-loading {
  height: 100%;
}

/**** vvvv HERE IS THE NEW STUFF vvvv  ****/
.slider-container {
  position: relative;
  background: url(/assets/images/driver.jpg) no-repeat 100% 100%;
  background-size: cover;
  /* you have to add a height here if your container isn't otherwise set 
          becuse the absolutely positioned image divs won't calculate the height 
          for you */
  height: 300px;
  width: 100%;
}

.slider-container .header {
  height: 60px;
}

.slider-container h4 {
  padding: 10px 10px;
  bottom: 0;
  margin: 0;
  opacity: 0;
  width: 100%;
  visibility: hidden;
  transition: all 1s linear;
  background-color: rgba(12, 21, 28, 0.88);
}

.slider-container h4 small {
  font-size: 65%;
  font-weight: 300;
}

.slider-container a {
  color: #fff;
  transition: opacity .2s linear;
}

.slider-container a:hover {
  opacity: .6;
}

.blog-footer {
  position: fixed;
  bottom: 0;
  z-index: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(34, 34, 34, 0.92);
  height: 80px;
}

.blog-footer .blog-item {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 1s ease;
}

.blog-footer .container {
  max-width: 475px;
  margin: 0 auto;
  position: relative;
  height: 80px;
}

.blog-footer .container a {
  opacity: .85;
  transition: opacity .2s ease;
  display: block;
}

.blog-footer .container a:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #eee;
  float: right;
  position: absolute;
  z-index: 1;
  margin-top: -19px;
  top: 50%;
  height: 100%;
  right: 20px;
  font-size: 38px;
  opacity: .8;
}

.blog-footer .container a:hover {
  opacity: 1;
}

.blog-footer .container a:hover:after {
  opacity: 1;
}

.blog-footer .container h5 {
  color: #aaa;
  padding: 16px 0 4px 0;
  margin: 0;
  text-shadow: 0 0 10px black;
}

.blog-footer .container p {
  color: #fff;
  padding: 0;
  margin: 0;
  text-shadow: 0 0 10px black;
  font-weight: 600;
  max-width: 85%;
  line-height: .825em;
}

/* Animations */
.ng-fade {
  opacity: 1 !important;
  visibility: visible !important;
}

/*header[data-headerfix=true]{
  position:fixed;
  top:-100px;
  box-shadow:0 0 10px rgba(0,0,0,.3);
}*/
header {
  position: relative;
  width: 100%;
  transition: all .2s ease;
  top: 0;
  position: fixed;
  z-index: 100;
  left: 0;
  background: transparent;
  /*height:100px;
  @include media($tablet) {
    height:150px;
  }*/
  box-shadow: 0 0 6rem rgba(46, 30, 29, 0.9);
  /*fix for CMS Area*/
  width: 100%;
}

header:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(68, 48, 46, 0.55), rgba(46, 30, 29, 0.85));
}

header:after {
  content: '';
  position: absolute;
  top: 0;
  opacity: 1;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(/assets/images/driver-new.jpg);
  background-size: 100%;
  background-position: top center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@media (min-width: 767px) {
  header:after {
    background-size: 100%;
  }
}

header .container {
  height: 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  z-index: 100;
}

@media (min-width: 767px) {
  header .container {
    height: 90px;
  }
}

header .container .title {
  position: absolute;
  width: 100%;
  padding: 0;
  left: 0;
  display: block;
  text-align: center;
  z-index: 1;
  line-height: 60px;
  color: #fff;
  font-size: 1.25rem;
}

@media (max-width: 420px) {
  header .container .title {
    display: inline-block;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    left: 50%;
    font-size: 1.05rem;
    margin-left: -6rem;
  }
}

@media (min-width: 767px) {
  header .container .title {
    line-height: 90px;
  }
}

header .container .offline-mode {
  position: absolute;
  display: block;
  top: 40px;
  margin-left: -10px;
  text-align: center;
  width: 100%;
}

@media (min-width: 767px) {
  header .container .offline-mode {
    top: 60px;
    margin-left: 0px;
  }
}

header .container .offline-mode span {
  border-color: #243D51;
  background-color: #f84848;
  color: #fff;
  font-size: .75em;
  padding: .3em .4em;
  width: auto;
  height: auto;
  margin: 3.1rem auto 0;
}

header .container .offline-mode span:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

header .container .offline-mode span h1, header .container .offline-mode span h2, header .container .offline-mode span h3, header .container .offline-mode span h4, header .container .offline-mode span h5, header .container .offline-mode span p {
  color: #fff;
}

header .container .offline-mode span h1:first-child, header .container .offline-mode span h2:first-child, header .container .offline-mode span h3:first-child, header .container .offline-mode span h4:first-child, header .container .offline-mode span h5:first-child, header .container .offline-mode span p:first-child {
  margin-top: 0;
  padding-top: 0;
}

header .container .offline-mode span h1:last-child, header .container .offline-mode span h2:last-child, header .container .offline-mode span h3:last-child, header .container .offline-mode span h4:last-child, header .container .offline-mode span h5:last-child, header .container .offline-mode span p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

header .container .offline-mode span a {
  color: #fff;
}

header .container .offline-mode span:before {
  margin: -.1rem .2rem 0 .1rem;
  display: inline-block;
}

header .logo {
  display: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  align-self: baseline;
  margin: .3em 0 0;
  padding: 0 1% 0 1%;
}

@media (min-width: 767px) {
  header .logo {
    display: block;
  }
}

header .logo .logo-icon {
  transition: all .2s ease;
  width: 92px;
}

@media (min-width: 767px) {
  header .logo {
    margin: .4em 0 0;
  }
  header .logo .logo-icon {
    width: 130px;
  }
}

header .logo img {
  display: block;
}

header .logo .logo-text {
  width: 220px;
  height: 150px;
}

header .searcher {
  display: inline-block;
  padding: 6.5em 3% 0 3%;
}

header .searcher:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 150%;
  color: #fff;
  display: block;
}

@media (min-width: 1000px) {
  header .searcher:before {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

header .menu {
  display: inline-block;
  text-align: center;
  z-index: 2;
  position: relative;
  /*&:after{
      content:"Menu";
      color:#666;
      font-size:.65rem;
      display: block;
      margin-top:.1rem;
    }*/
}

header .menu:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 182.5%;
  color: #fff;
  padding: .4em;
}

header .mobile-menu {
  display: block;
  margin: -10px auto 0 auto;
  width: 100%;
  text-align: center;
  z-index: 2;
}

header .mobile-menu:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 150%;
  color: #A9A9A9;
  padding-top: 15px;
  padding-bottom: 10px;
  display: block;
}

@media (min-width: 767px) {
  header .mobile-menu {
    display: none;
  }
}

header .mysps {
  /*&&:after{
      content:"My SPS";
    }*/
}

header .mysps:before {
  content: "";
  font-size: 175%;
}

header .blog:before {
  content: "";
  font-size: 165%;
}

header .blog:after {
  content: "Blog";
}

header .myaccount {
  display: none;
}

@media (min-width: 767px) {
  header .myaccount {
    display: inline-block;
  }
}

header .myaccount span {
  display: none;
  font-size: .95rem;
}

@media (min-width: 1100px) {
  header .myaccount span {
    display: inline-block;
  }
}

header .myaccount span:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: .6rem;
  display: inline-block;
  margin: 0 .6rem 0 .5rem;
}

header .myaccount > a > i {
  border-radius: 50%;
  border: 0.075em solid #fcfcfc;
  padding: 0.5rem;
  font-size: 1.4rem;
  display: inline-block;
  margin: 0 .5rem 0 .2rem;
}

header.mobile-menu-open nav {
  max-height: 400px;
}

header.mobile-menu-open .mobile-menu:before {
  content: "";
}

header nav {
  text-align: right;
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
  max-height: 100%;
  position: relative;
  display: block;
  color: #fff;
}

header nav > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

header nav > ul li {
  margin: 0;
  padding: 0;
}

header nav > ul > li {
  padding: 0 2% 0 2%;
  line-height: 60px;
  cursor: pointer;
}

@media (min-width: 767px) {
  header nav > ul > li {
    padding: 0 1% 0 1%;
    line-height: 90px;
  }
}

header nav a {
  opacity: .9;
  transition: all .2s ease;
}

header nav a:hover {
  opacity: 1;
  text-decoration: none;
}

.home .title {
  display: none !important;
}

.home header .logo {
  display: block;
}

.home .back-btn {
  display: none;
}

.back-btn {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  line-height: 60px;
  padding: 0 .8rem;
  font-size: 1.2rem;
}

@media (min-width: 767px) {
  .back-btn {
    line-height: 90px;
  }
}

.back-btn i {
  font-size: 1.9rem;
  display: inline-block;
  margin: -.2rem .1rem 0 0;
  color: #fff;
}

.back-btn:hover {
  color: #555;
}

@media (min-width: 767px) {
  header .back-btn {
    display: none;
  }
}

.articles-main {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.articles-main.main-full {
  max-width: 100%;
}

article {
  border: 1px solid #dfdfdf;
}

article figure {
  width: 100%;
  max-height: 400px;
  overflow: hidden;
}

article figure img {
  width: 100%;
}

article .article-body {
  padding: 15px 7% 20px;
  background-color: #fff;
}

article h3 {
  color: #243D51;
}

article h1 {
  color: #243D51;
}

.breadcrumb h6 {
  text-transform: uppercase;
}

.articles-list article {
  width: 100%;
  margin: 3px 0;
  /*@include media($sm-tablet){     
            width:49%;margin:.5%;
        }*/
}

.articles-list article:hover {
  z-index: 2;
}

article.main-sidebar {
  flex: 1;
}

aside {
  flex: 0 0 15em;
  padding: 20px;
}

.gutter-sizer {
  width: 1%;
}

.login-container {
  background: url(/assets/images/driver-new.jpg) no-repeat 0 0 #000;
  background-size: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.login-container main {
  height: 100%;
}

.login-container .logo {
  display: block;
  text-align: center;
}

.login-container .logo img {
  width: 9rem;
  margin: .1rem 0;
}

@media (min-width: 767px) {
  .login-container .logo img {
    margin: .2rem 0;
  }
}

.login-container h2 {
  margin-top: 40px;
}

.login-container h3 {
  padding: 1.25rem 0 .8rem;
}

.login-container .btn-group {
  margin-bottom: 1rem;
}

.login-form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 0 !important;
  max-width: 460px;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 250, 255, 0.96);
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.3);
}

@media (min-width: 420px) {
  .login-form {
    height: auto;
  }
}

.login-standard {
  padding: 1rem 7% 0 7%;
  width: 100%;
}

.login-standard p.ip-warning {
  font-size: .75rem;
  margin-bottom: .4rem;
}

@media (min-width: 767px) {
  .login-standard {
    padding: 1rem 10% 0 10%;
  }
  .login-standard p.ip-warning {
    font-size: 14px;
  }
}

a.forgot-link {
  display: inline-block;
  padding: .2rem 0;
  vertical-align: middle;
  font-size: 13px;
  color: #000;
}

.form-loader:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  z-index: 100;
  transition: opacity .3s linear;
  visibility: hidden;
}

.form-loader.form-loading:before {
  visibility: visible;
  opacity: .4;
}

.sidebar {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  background-color: #333;
  width: 300px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  /*.active > span > .dropdown {
        background-color: #f0f0f0;
        &:before {
            content: "\e602"
        }
    }*/
  /*    @include media($phablet) {
        ul{padding:52px 0 0 0}
        .header {
            height: 52px;
        }
        .menu-button,
        .cart-button {
            padding-top: 10px;
        }
        .account-button {
            padding-top: 11px;
        }
    }
*/
}

.sidebar p {
  margin: 0;
}

.sidebar .header {
  height: 60px;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #555;
  z-index: 9;
  position: absolute;
}

.sidebar .header .close-menu-button,
.sidebar .header .cart-button,
.sidebar .header .logout-button,
.sidebar .header .account-button {
  color: #ccc;
  width: 55px;
  position: absolute;
  font-size: 18px;
  font-weight: normal;
  right: 0px;
  opacity: .6;
  top: 0;
  line-height: 60px;
  display: block;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

.sidebar .header .close-menu-button span,
.sidebar .header .cart-button span,
.sidebar .header .logout-button span,
.sidebar .header .account-button span {
  display: inline-block;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

.sidebar .header .close-menu-button {
  cursor: pointer;
  z-index: 1;
}

.sidebar .header .close-menu-button:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  display: inline-block;
  margin-top: -3px;
}

.sidebar .header .close-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.sidebar .header .logout-button {
  right: 50px;
  padding-left: 2px;
  z-index: 1;
}

.sidebar .header .logout-button:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1.2em;
}

.sidebar .header .account-button {
  display: inline-block;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  text-align: left;
  padding-left: 20px;
  width: 100%;
  color: #ccc;
}

.sidebar .header .account-button:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 27px;
  margin: 15px 8px 0 0;
  display: inline-block;
  float: left;
}

.sidebar .header .account-button:hover {
  background-color: transparent;
  cursor: default;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar li {
  margin: 0;
  padding: 0;
  display: block;
}

.sidebar span {
  color: #ccc;
  position: relative;
}

@media (min-width: 1100px) {
  .sidebar li span a:hover {
    background-color: #000;
  }
}

.sidebar-content {
  position: absolute;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  transition: transform .3s linear;
  width: 300px;
  transform: translateX(0);
}

.sidebar-content h5 {
  width: 100%;
  padding: 0;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #ccc;
  display: block;
  border-bottom: 1px solid #555;
  font-size: 19px;
  margin: 0;
}

.sidebar-content h5 a, .sidebar-content h5 span {
  color: #ccc;
  display: block;
  padding: .75em .4em .70em 1.1em;
}

.sidebar-content h5 a:hover {
  background-color: #000;
}

.sidebar-content h5 i {
  font-size: 1em;
  margin-right: 5px;
  line-height: 1.1;
}

.sidebar-content > ul {
  width: 300px;
  padding: 0 1px;
}

.sidebar-content > ul li:not(.sidebar-footer) {
  width: 100%;
  border-bottom: 1px solid #555;
}

.sidebar-content > ul li:not(.sidebar-footer) span {
  display: block;
  padding: 0;
  width: 100%;
  margin: 0;
}

.sidebar-content > ul li:not(.sidebar-footer) span .dropdown {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  line-height: 3.1;
  cursor: pointer;
  padding: 0;
  text-align: center;
  border-left: 1px solid #eee;
  vertical-align: middle;
  width: 58px;
  height: 100%;
  display: block;
  text-align: center;
}

.sidebar-content > ul li:not(.sidebar-footer) span .dropdown:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: #aaa;
  vertical-align: middle;
  line-height: 1.7;
  margin-right: 3px;
}

.sidebar-content > ul li:not(.sidebar-footer) span .dropdown:hover {
  background-color: #f0f0f0;
}

.sidebar-content > ul li:not(.sidebar-footer) a,
.sidebar-content > ul li:not(.sidebar-footer) li.cat-back {
  color: #ccc;
  padding: .75em .4em .75em 1.3em;
  display: block;
  line-height: 1.15;
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
}

.sidebar-content > ul li:not(.sidebar-footer) a i,
.sidebar-content > ul li:not(.sidebar-footer) li.cat-back i {
  font-size: 1.15em;
  margin: -.2em .2em -.1em 0;
  line-height: 1.15;
}

.sidebar-content > ul li:not(.sidebar-footer) a i.icon-blog:before,
.sidebar-content > ul li:not(.sidebar-footer) li.cat-back i.icon-blog:before {
  content: "";
}

.sidebar-content > ul li:not(.sidebar-footer) a i.icon-about:before,
.sidebar-content > ul li:not(.sidebar-footer) li.cat-back i.icon-about:before {
  content: "";
}

.sidebar-content > ul li:not(.sidebar-footer) a strong,
.sidebar-content > ul li:not(.sidebar-footer) li.cat-back strong {
  margin: 0 .2rem 0 0;
}

.sidebar-content > ul li:not(.sidebar-footer) li.cat-back {
  background-color: #eee;
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
}

.sidebar-content > ul li:not(.sidebar-footer).active > ul {
  height: 100%;
  z-index: 9999;
}

.sidebar-content > ul li:not(.sidebar-footer) ul {
  width: 295px;
  display: block;
  position: absolute;
  top: 0;
  left: 300px;
}

.sidebar-content > ul > li.sidebar-footer {
  overflow: hidden;
  margin: 0;
  height: 100%;
  width: 295px;
  float: none;
  padding: .7em 1em 1em 1.3em;
}

.sidebar-content > ul > li.sidebar-footer ul.social-icons > li {
  width: auto;
  background-color: transparent;
  border: none;
}

.sidebar-content > ul > li.sidebar-footer ul.social-icons > li > a {
  color: #aaa;
  margin: 0;
  padding: 10px 5px 0 0;
}

.sub-cat > .sidebar-content {
  transform: translateX(-300px);
}

article.article-detail {
  border: 0;
}

article.article-detail .article-body {
  max-width: 950px;
  margin: -2rem auto;
  padding: 5%;
  border: 1px solid #ddd;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Variables
================================== */
.MySPS main {
  padding-top: 5.5rem;
  max-width: 950px;
}

.sub-nav {
  width: 100%;
  background-color: #333;
  opacity: .9;
  position: fixed;
  z-index: 99;
  top: 60px;
}

@media (min-width: 767px) {
  .sub-nav {
    top: 90px;
  }
}

.sub-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.sub-nav ul li {
  margin: 0;
  padding: 0;
}

@media (min-width: 481px) {
  .sub-nav ul {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 767px) {
  .sub-nav ul {
    padding-left: 2%;
    padding-right: 2%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1500px) {
  .sub-nav ul {
    width: 1366px;
    padding-left: 0;
    padding-right: 0;
  }
}

.sub-nav ul li {
  float: left;
  margin: 0;
  text-align: center;
  width: 25%;
}

.sub-nav ul li a {
  display: block;
  height: 52px;
  line-height: 52px;
  width: 100%;
}

.sub-nav ul li h4 {
  display: none;
  padding: 0;
  margin: 0 .5rem;
  color: #ccc;
  font-size: .925rem;
  line-height: 52px;
  padding: 0;
  font-weight: normal;
}

@media (min-width: 767px) {
  .sub-nav ul li h4 {
    display: inline-block;
  }
}

@media (min-width: 767px) {
  .sub-nav ul li h4 {
    line-height: 68px;
  }
  .sub-nav ul li a {
    height: 68px;
    line-height: 68px;
  }
}

.sub-nav ul li i {
  display: inline-block;
  margin: -4px 0 0;
  font-size: 1.15rem;
  border-radius: 50%;
  border: 2px solid #777;
  padding: 0.5rem;
}

.sub-nav ul li i.icon-note {
  font-size: 1rem;
  padding: .55rem;
}

.sub-nav ul li.active, .sub-nav ul li:hover {
  background-color: #000;
}

.sub-nav.sub-nav-alt {
  background-color: #ddd;
  top: 112px;
}

.sub-nav.sub-nav-alt ul li {
  width: 33.33%;
  text-align: center;
  padding: 0;
}

.sub-nav.sub-nav-alt ul li a {
  display: table;
  height: 50px;
  text-align: center;
  margin: 0 auto;
}

.sub-nav.sub-nav-alt ul li h4 {
  padding: 0;
  margin: 0 .5rem;
  color: #555;
  display: table-cell;
  vertical-align: middle;
  line-height: 1;
}

.sub-nav.sub-nav-alt ul li.active, .sub-nav.sub-nav-alt ul li:hover {
  background-color: #aaa;
}

@media (min-width: 767px) {
  .sub-nav.sub-nav-alt {
    top: 158px;
  }
  .sub-nav.sub-nav-alt ul li a {
    height: 60px;
  }
}

.sub-nav ~ main {
  margin-top: 1rem;
}

/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
}

.Rtable-cell {
  box-sizing: border-box;
  font-size: .9rem;
  position: relative;
  width: 100%;
  list-style: none;
  color: #555;
}

.Rtable-cell [contenteditable], .Rtable-cell.Rtable-head {
  padding: 0.5rem;
  min-height: 3.5rem;
  line-height: 1.15;
}

@media (min-width: 420px) {
  .Rtable-cell [contenteditable], .Rtable-cell.Rtable-head {
    padding: 0.75rem;
  }
}

.Rtable-cell > h1, .Rtable-cell > h2, .Rtable-cell > h3, .Rtable-cell > h4, .Rtable-cell > h5, .Rtable-cell > h6 {
  margin: 0;
  padding: 0;
}

.Rtable-cell h5 {
  color: #000;
  line-height: 1.1;
}

.Rtable-cell.Rtableactive {
  position: relative;
  background-color: #b0b0b0 !important;
}

.Rtable-cell.Rtableactive:after {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-top: 1rem solid #b0b0b0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  position: absolute;
  bottom: -1rem;
  margin-left: -1rem;
  left: 50%;
}

.Rtable-cell strong {
  color: #000;
}

.Rtable-container {
  padding: .3rem 0rem;
}

.Rtable-title {
  padding: 2rem 1rem 1rem;
}

.Rtable-title h3 {
  margin: 0;
  padding: 1rem 0;
}

.Rtable.RNav {
  background-color: #eee !important;
}

.Rtable.RNav h4 {
  font-size: .85rem;
  font-weight: bold;
  color: #fff;
}

.Rtable.RNav .Rtable-head.Rtable-cell {
  opacity: .75;
  cursor: pointer;
  border: none;
  border-right: solid 1px #ccc;
  background-color: #ddd;
  padding: 1.5rem .5rem;
}

.Rtable.RNav .Rtable-head.Rtable-cell > i {
  font-size: 2.5rem;
  color: #777;
  margin: .5rem 0 .5rem;
}

.Rtable.RNav .Rtable-head.Rtable-cell > a {
  display: block;
  text-align: center;
}

.Rtable.RNav .Rtable-head.Rtable-cell > a i {
  margin: -.4rem .2rem -.3rem 0;
}

.Rtable.RNav .Rtable-head.Rtable-cell h4 {
  color: #555;
}

@media (max-width: 481px) {
  .Rtable.RNav .Rtable-head.Rtable-cell h4 {
    display: none;
  }
}

.Rtable.RNav .RTable-link {
  opacity: .75;
}

.Rtable.RNav .RTable-link:hover {
  opacity: 1;
}

.Rtable.RNav .RTable-link > a {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .Rtable.RNav .RTable-multi {
    display: none;
  }
}

.Rtable-aux {
  text-align: center;
}

.Rtable-aux .Rtable-cell {
  min-height: auto;
  border: solid 0 white;
  background: #eee;
}

.Rtable-aux a {
  display: inline-block;
  margin: 0 .4rem 0 0;
  color: #aaa;
}

.Rtable-aux a i {
  margin: -.2rem 0 0 0;
}

@media (min-width: 767px) {
  /* Table column sizing
================================== */
  .Rtable--2cols > .Rtable-cell {
    width: 50%;
  }
  .Rtable--3cols > .Rtable-cell {
    width: 33.33%;
  }
  .Rtable--4cols > .Rtable-cell {
    width: 25%;
  }
  .Rtable--5cols > .Rtable-cell {
    width: 20%;
  }
  .Rtable--6cols > .Rtable-cell {
    width: 16.6%;
  }
}

.Rtable-head {
  position: relative;
  padding: .4rem 0 .4rem;
}

.Rtable-head h4 i, .Rtable-head h5 i {
  margin: -.2rem .3rem 0 .1rem;
}

.Rtable-head h5 {
  padding: 0;
  margin: 0;
}

.Rtable-head .icon-tick-circle {
  color: #3eb44a;
}

.Rtable-head .edit-button {
  position: absolute;
  right: 0;
  top: .3rem;
}

.RGoals.Rtable {
  margin: 2rem 0;
  /*.Rtable-cell:nth-child(4n+2){
        background:lighten(#ddd,3%);
    }
    .Rtable-cell:nth-child(4n+3){
        background:lighten(#ddd,6%);
    }
    .Rtable-cell:nth-child(4n+4){
        background:lighten(#ddd,9%);
    }*/
}

.RGoals.Rtable .Rtable-cell {
  border: 1px solid #fff;
}

.RGoals.Rtable .Rtable-cell.Rtable-head {
  background: #dedede;
  min-height: auto;
}

@media (max-width: 767px) {
  .RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-col-head {
    display: none;
  }
}

.RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-row-head {
  background: #e0e0e0;
}

@media (max-width: 767px) {
  .RGoals.Rtable .Rtable-cell.Rtable-head.Rtable-row-head {
    width: 100%;
    text-align: center;
    min-height: auto;
  }
}

.RGoals.Rtable [contenteditable] {
  border: 1px solid #cfcfcf;
  position: relative;
}

@media (max-width: 767px) {
  .RGoals.Rtable [contenteditable] {
    padding-top: 2.4rem;
    min-height: 4.5rem;
  }
  .RGoals.Rtable [contenteditable]:after {
    content: attr(title);
    display: inline-block;
    height: 1.75rem;
    line-height: 2.1;
    left: .0;
    position: absolute;
    background-color: #eee;
    top: 0;
    width: 100%;
    font-size: .85rem;
    text-align: center;
  }
}

.RGoals.Rtable h5 {
  font-weight: bold;
}

.btn.btn-invert.reminder {
  float: right;
  transition: opacity .2s linear;
  opacity: .8;
  margin: -.4rem 0 -.4rem 0;
  padding: 0 0.3rem 0 0;
  line-height: 1.9;
}

.btn.btn-invert.reminder:hover {
  opacity: 1;
}

.btn.btn-invert.reminder.reminder-set:before {
  color: #243D51;
}

.btn.btn-invert.reminder .badge {
  display: inline-block;
  width: 1.1rem;
  float: left;
  height: 1.1rem;
  margin: .15rem;
  font-size: .7rem;
  line-height: 1.7;
  padding: 0;
}

.RStatements {
  position: relative;
  margin: 1rem 0;
}

.RStatements .Rtable-head {
  position: relative;
}

.RStatements h5 {
  padding: .6rem 0;
}

.RStatements .btn.btn-invert.reminder {
  margin: -.4rem 0 -.4rem 0;
}

.RStatements .Rtable-group {
  border: none;
  padding: .4rem 2.5rem .3rem 0;
  position: relative;
}

.RStatements .Rtable-cell[contenteditable] {
  padding: .7rem;
  border: 1px solid #eee;
  background-color: #fff;
}

.Rtable-holder {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 1rem 1.2rem 1rem;
  margin: .5rem 0;
  overflow: hidden;
}

.Rtable-new {
  margin: 1.5rem 0 1.2rem;
  width: auto;
  cursor: pointer;
  font-size: .85rem;
}

.Rtable-new i {
  font-size: 1.2rem;
  display: inline-block;
  margin: -.3rem .2rem -.1rem 0rem;
}

.Rtable-new:hover a {
  color: #444;
}

.Rcell-title[contenteditable] {
  margin: .0rem 0 .2rem;
  font-weight: bold;
  min-height: auto;
}

.Rcell-message {
  margin: -1px 0 .5rem;
  min-height: 3.5rem;
}

.Rcell-delete {
  position: absolute;
  right: -1.5rem;
  transform: rotate(45deg);
  margin: 0;
  transition: opacity .2s linear;
  opacity: .6;
  cursor: pointer;
  font-size: .8rem;
  display: inline-block;
}

.Rcell-delete:before {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1.1rem;
}

.Rcell-delete:hover {
  opacity: 1;
}

[contenteditable=true] {
  transition: all .1s linear;
  font-size: 16px;
  word-wrap: break-word;
}

[contenteditable=true]:hover {
  background-color: #fff !important;
}

[contenteditable=true]:focus {
  background-color: #fff !important;
  transform: scale(1.04);
  opacity: 1 !important;
  border: 1px solid rgba(0, 158, 250, 0.5) !important;
  box-shadow: 0 0 1.5rem rgba(0, 158, 250, 0.5);
  position: relative;
  z-index: 9;
}

@media (min-width: 767px) {
  [contenteditable=true]:focus {
    transform: scale(1.15);
  }
}

[contenteditable=true]:empty {
  opacity: .5;
}

[contenteditable=true]:empty:before {
  content: "Enter text here...";
  font-style: italic;
  display: block;
  /* For Firefox */
}

[contenteditable=true][placeholder]:empty:before {
  content: attr(placeholder);
}

.help-holder {
  display: inline;
}

.Rtable-head h4 i.helpicon, .Rtable-head h5 i.helpicon {
  margin: -.3rem .1rem 0 .1rem;
}

.helpicon {
  transition: all .3s linear;
  background-color: transparent;
  border-radius: 50% 50% 0 0;
  padding: .1rem .4rem 0 .4rem;
  text-align: center;
  opacity: .5;
  float: right;
  margin-top: -.4rem;
  cursor: pointer;
}

.helpicon:hover {
  opacity: 1;
}

.helpicon:before {
  content: "";
  font-size: 1.3rem;
  transform: rotate(0);
  display: block;
}

.RTable-head .helpicon {
  float: right;
}

h1 + .help-holder .helpicon,
h1 + form .help-holder .helpicon {
  margin-top: .6rem;
}

.helpicon.infohide {
  background-color: #fff;
  opacity: 1;
  padding: .4rem;
}

.helpicon.infohide:before {
  content: "";
  transform: rotate(-45deg);
  display: block;
}

.info {
  background-color: #fff;
  cursor: pointer;
  padding: 0 1.5rem 0 1.5rem;
  max-height: 0;
  font-size: .85rem;
  overflow: hidden;
  font-size: .75rem;
  width: 100%;
  font-weight: normal;
  transition: max-height .3s linear;
}

.info strong:first-child {
  padding: .8rem 0 .2rem;
  display: block;
  font-size: .85rem;
}

.info p {
  margin: 0;
  padding: 0 0 .2rem;
  line-height: 1.15;
}

.info ol, .info ul {
  margin: 0;
  padding: 0;
}

.info ol li, .info ul li {
  margin: 0 0 0 1rem;
  line-height: 1.15;
  padding: 0 0 0.2rem 0;
}

.info.infohide {
  max-height: 350px;
  padding-bottom: .5rem;
}

.Rtable-group .Rtable-head .info.infohide {
  max-height: 370px;
}

.chart-container {
  position: relative;
  width: 100%;
}

.chart {
  padding: 0 0 1rem;
}

.chart-buttons {
  float: right;
  overflow: hidden;
  z-index: 1;
  padding: .8rem .5rem 1rem .5rem;
  position: relative;
}

.chart-buttons .btn.btn-xs {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-right: 10px;
}

.input-counter {
  position: absolute;
  bottom: .2rem;
  right: .3rem;
  display: none;
  opacity: .6;
  font-size: .75rem;
  z-index: 9999;
  color: #555;
}

@media (min-width: 767px) {
  .input-counter {
    bottom: 0rem;
    right: -.55rem;
  }
}

article.article-detail {
  border: 0;
}

article.article-detail .about-mysps-body {
  max-width: 950px;
  margin: -2rem auto;
  padding: 5%;
}

ul.list {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}

ul.list li {
  margin: 0;
  padding: 0;
}

ul.list li {
  font-size: 1.1rem;
  position: relative;
  padding: 1.5% 10% 1.5% 3%;
  border-top: 1px solid #ddd;
  width: 100%;
  display: block;
  cursor: pointer;
  transition: all .2s linear;
}

ul.list li p {
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  margin: 0 0 -.3rem;
  font-weight: bold;
}

ul.list li > small {
  display: block;
  margin: .3rem 0;
  line-height: 1;
}

ul.list li p + small {
  margin: .3rem 0 0;
}

ul.list li .badge {
  margin: .4rem .2rem 0 0;
  float: right;
}

ul.list li:after {
  content: "";
  font-family: 'icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-decoration: inherit;
  vertical-align: middle;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  position: absolute;
  font-size: 1.1rem;
  right: 3%;
  color: #555;
  margin-top: -.6rem;
  top: 50%;
}

ul.list li:hover {
  background-color: #fff;
}

ul.list.list-select li:after {
  content: "";
}

ul.list.list-select li.selected:after {
  content: "";
}

.ngdialog-content h4 {
  padding: 1em 0 .6rem 0;
}

.ngdialog-content h4 i {
  float: right;
  margin-top: -.6rem;
}

.ngdialog-content .back-btn {
  font-family: "proxima-nova", Arial, Helvetica, sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1.2;
  color: #999;
  padding: 1.6em .7rem;
  margin-top: -.5rem;
  font-size: .8rem;
}

.ngdialog-content .back-btn i {
  color: #999;
  font-size: 1.35rem;
  display: inline-block;
  margin: -.2rem .1rem 0 0;
}

.ngdialog-content .back-btn:hover {
  color: #555;
}

.ngdialog-content .select-holder, .ngdialog-content input[type=time] {
  height: 2.7rem;
}

.ngdialog-content .btn-group {
  margin: 0 0 .3rem;
}

.time-holder {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.time-holder .select-holder {
  margin-right: -.2rem;
  max-width: 135px;
  min-width: 100px;
}

.time-holder .select-holder select {
  -moz-box-flex: 1  ;
  -webkit-flex: 1  ;
  -ms-flex: 1  ;
  flex: 1  ;
}
