.browserupgrade {
    img {
        display: block;
        margin: 0 auto;
        text-align: center;
        width:300px;
    }
    margin: 0;
    background:  #454545;
    color: #fff;
    height:100%;
    font-size: 16px;
    text-align: center;
    padding:5% 10%;
    font-family: Arial, san-serif;
    position: fixed;
    top:0;
    strong{font-size: 20px;line-height:2em;}
    width:100%;
    z-index: 99999;
    a {
        color: #fff;
        text-decoration: underline;
    }
}