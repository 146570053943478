$text-inputs-list: 'input[type="color"]',
'input[type="date"]',
'input[type="datetime"]',
'input[type="datetime-local"]',
'input[type="email"]',
'input[type="month"]',
'input[type="number"]',
'input[type="password"]',
'input[type="search"]',
'input[type="tel"]',
'input[type="text"]',
'input[type="time"]',
'input[type="url"]',
'input[type="week"]',
'input:not([type])',
'textarea','.inputElement';

/*.form-group {
    margin: .2em 0;
    display: flex;
    flex-flow: row wrap;
    label {
        @include flex(0 1 4em);
    }
    #{$text-inputs-list} {
        @include flex(1);
        min-width: 50px;
    }
    .btn {
        margin: 0;
    }
    .form-group{
        @include flex(0 0 50%;)
    }
}*/

$form-column-breakpoint: $lg-phablet;


fieldset {}

.form-bordered {
    transform: translateZ(0);
    background-color: #fff;
    border: 1px solid #dfdfdf;
    margin-bottom: 1rem;
    padding:8% 4% 2%;
}




/*input[required]{
    background-image: radial-gradient(#F00 15%, transparent 16%);
    background-size: 2em 2em;
    background-position: right center;
    background-repeat: no-repeat;
}*/

.input-heading,
.label,
label {
    line-height: 1;
    font-size: $form-field-label-size;
    @include font-bold;
    strong{
        @include font-bold;
    }
    &.lbl-sm{ line-height: .6;font-size:95%!important;}
    color:$form-field-label-color;
}

@mixin input-xs {
    padding: .375rem .5rem;
    font-size: 16px;
    // height:33px;
    line-height: normal;
}

@mixin input-sm {
    padding: .55rem .75rem;
    font-size: 16px;
    // height:38px;
}

@mixin input-md {
    padding: .6rem .9rem;
    font-size: 1rem;
    // height:42px;
}

@mixin input-lg {
    padding: .75rem 1.85rem;
    font-size: 17px;
    // height:49px;
}

@mixin input-xl {
    padding: .85rem 1.2rem;
    font-size: 19px;
    // height:57px;
}

@mixin select-lg {
    select {
        @include input-lg
    }
}
.pac-container{
    margin-top:-1px !important;
    margin-top:-1px !important;
}

.input-width-xs{
    max-width:100px;
}
.input-width-sm{
    max-width:200px;
}
.recaptcha{
    flex:1 1 100%;
    margin-left:-8px;
    margin-top:25px;
    @include media($form-column-breakpoint) {
        margin-left:170px;
        margin-right:20%;
        margin-top:0px;
        width:auto;
    }
}
@mixin input-base() {
    @if $form-inline-form-fields == true {    
        flex:1 1 auto;
    } @else {
        display: block;
        width: 100%;
    }    
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    line-height: normal;
    font-size: 1rem;
    @include placeholder {
        color: $sec-color;
    }
    min-width: 10px;
    transition: all 260ms cubic-bezier(0.77, 0, 0.175, 1);
    color: $form-field-color;
    border-radius:$form-field-radius;
    border: $form-field-border;    
    font-family: $form-field-font;
    background-color: $form-field-background-color;
    font-weight: normal;
    margin-bottom: .4rem;
    &:hover {
        border-color: $form-field-hover-border-color;
    }
    &:focus {
        outline: none;
        border-color: $form-field-focus-border-color;
        background-color: $form-field-background-color;
    }
    @include input-md;
    &:disabled {
        //cursor: not-allowed;
        opacity: 0.8;
    }
    select {
        @include input-md;border:none;
    }
}

#{$text-inputs-list} {
    @include input-base;
}

.select-holder {
    @include input-base;
    padding: 0;
    display: inline-block;
    min-width: 140px;
    height: auto;
    cursor: pointer;
    font-size:normal;
    position: relative;
       select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        background-image: none;
        border: none;
        box-shadow: none;
        margin: -2px;//Adjust for border
        border-top:4px solid transparent;//Adjust for border
        color: $form-field-color;
        font-family: $form-field-font;
        cursor: pointer;
        height: auto;
        position: relative;
        width: 100%;
        z-index: 10;
        &::-ms-expand {
            display: none;//removes ie select arrow
        }
        &:hover:before {
            color: $form-field-hover-border-color;
        }
        &:focus:before {
            color: $form-field-focus-border-color;
        }
        option{
            color: $form-field-color;
        }
        &.ng-invalid,option:first-child{
            color: $sec-color;
        }
    }
    &:before {
        @include icon($icon-arrow-down);
        color: $form-field-border-color;
        font-size:19px;
        position:absolute;
        right:.8rem;
        top:50%;
        margin-top:-10px;
    }
    //&:focus:before{
    //color: #ddd;
    //transform:rotate(180deg);
    //}
}

#{$text-inputs-list} {
    &.input-xs {
        @include input-xs;
    }
}

#{$text-inputs-list} {
    &.input-sm {
        @include input-sm;
    }
}

#{$text-inputs-list} {
    &.input-lg {
        @include input-lg;
    }
}

#{$text-inputs-list} {
    &.input-xl {
        @include input-xl;
    }
}

.select-holder.select-lg select {
    @include input-lg;
}


.form {}


/* I use form--complex for forms that have more complex layouts than simple left/right label and field alignment etc */

.form--complex {
    display: flex;
}

.form__object--fillspace {
    /* This element will receive priority of size. 
     All other elements will be sized at the size dictated by their display type */
    flex-grow: 1;
    & + .form__object--fillspace-gap {
        margin-left: .5em;
    }
    .btn {
        margin: 0;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.form-row {
    @include flexbox;  
        flex-flow: row wrap;
    align-items:center;
    @if $form-inline-form-fields != true {     
        flex-direction: column;
    }
    margin: 0;
    width:100%;
    label {
        margin-top:1.2rem; 
        margin-bottom:.3rem;
        display: block;        
        width:100%;    
        @include media($form-column-breakpoint) {
            @if $form-inline-form-fields == true {        
                width:170px;                   
                display: inline-block;              
            }@else{
                display: block;        
                width:100%;       
            }
        }
    }
    #{$text-inputs-list},
    .select-holder {
        //flex: 0 0 100%;
        margin: 0;
    }
    label.hide+input,
    label.hide+.select-holder {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    @include media($form-column-breakpoint) {
    label.hide+input,
    label.hide+.select-holder {
      margin-top: 0;
      margin-bottom: 0;
    }

    }


    label.checkbox,label.radio{margin-top:.2rem;}
    @include media($form-column-breakpoint) {
        flex-direction: row;
        margin: .3rem 0 1.2rem;
        label{
            margin-top:.3rem; 
            margin-bottom:.3rem;
        }
        label.checkbox,label.radio{margin-top:.2rem;}
    }
    padding: 0;

    p {
        flex-direction: column;
        width:100%;
        margin-bottom: .2em;
        font-size: 14px;
    }
    a {
        text-decoration: underline;
        color: #777;
        &:hover {
            text-decoration: none;
            color: #111;
        }
    }
    p.location {
        @include input-base;
        margin-bottom: 0;
        height:auto;
    }
}



.form-row .form-cell {
    @include flexbox;
    align-items:center;
    flex-flow: row wrap;
    width:100%;
     @include media($form-column-breakpoint) {
        & + .form-cell{margin-top:1.5em;}
     }
     @include media($desktop) {
         & + .form-cell{margin-top: 0;}
         @include flex(1); 
         padding: 0 3% 0 0;
     }

}

.form-row .form-cell:last-child {
    padding: 0 0 0 0;
}

.signup-form{
    position:relative;
    padding:40px 5%;
    background-color:$third-color;
    #{$text-inputs-list},
    select {
       @include input-lg;
    }
    @include media($tablet) {
        padding:50px 14%;
    }
}

textarea{resize:vertical;height:5em;}


.form-loader:before{
    position:absolute;
    content:"";
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    background-color:#fff;
    z-index: 100;
    transition:opacity 1s linear;
    visibility: hidden;
}
.form-loader.form-loading:before{
    visibility: visible;
    opacity: .5;
}