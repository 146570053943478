article.article-detail{
    border:0;
    .article-body{
        max-width:950px;
        margin:-2rem auto;
		padding: 5%;
		border:1px solid #ddd;
    }
  
}


.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}