 html,
 body,
 body,
 .sidebar-pusher,
 .content-container {
   height: 100%;
 }
 
 .content-container {
   overflow-y: auto;
   overflow-x: hidden;
   -webkit-overflow-scrolling: touch;
 }
 
 .content-container,
 .content-container-inner {
   position: relative;
 }
 
 body {
   position: relative;
   overflow: hidden;
 }
 
 .sidebar-pusher {
   height: 100%;
   width: 100%;
   position: relative;
   z-index: 1000;
   transition: transform 0.5s;
   overflow: hidden;
 }
 
 @include media($sm-tablet-max) {
   .sidebar-pusher::after {
     position: absolute;
     top: 0;
     right: 0;
     width: 100%;
     height: 100%;
     z-index: 99999;
     background: rgba(#000, 0.5);
     content: '';
     opacity: 0;
     visibility: hidden;
     transition: ease all 0.5s;
   }
   [class*=sidebar-open-] .sidebar-pusher::after{
     opacity: 1;
     visibility: visible;
   }
 }
 
 .sidebar-open .content-container {
   //overflow-y: hidden;
 }
 
 .sidebar {
   position: absolute;
   top: 0;
   left: 0;
   visibility: hidden;
   width: 300px;
   height: 100%;
   z-index: 1;
   transition: all 0.5s;
 }

 .sidebar-open.cart-open .sidebar {
   //z-index: 9999;
 }
 
.sidebar.sidebar-right {
   left: auto;
   right: 0;
 }
 
.sidebar.sidebar-right::after {
   right: auto;
   left: 0;
 }
 
 [class*=sidebar-open-] .sidebar {
   visibility: visible;
   transition: ease transform 0.5s;
   transform: scale(1, 1);
 }

 /* Effect: Push-Left*/ 
 @include media($sm-tablet) {
   .sidebar {
     z-index: 9999
   }
   .sidebar-open-left .sidebar.sidebar-left {
     transform: translate3d(0, 0, 0);
   }
   .sidebar-open-left .sidebar.sidebar-right {
     transform: translate3d(100%, 0, 0);
   }
   .sidebar-open-right .sidebar.sidebar-left {
     transform: translate3d(-100%, 0, 0);
   }
   .sidebar-open-right .sidebar.sidebar-right {
     transform: translate3d(0, 0, 0);
   }
   .sidebar-left {
     transform: translate3d(-100%, 0, 0);
   }
   .sidebar-right {
     transform: translate3d(100%, 0, 0);
   }
 }
 
 @include media($sm-tablet-max) {
   [class*=sidebar-open-] .sidebar {
     z-index: 0;
   }
   /* Effect: Push-Right*/
   .sidebar-open-left .sidebar-pusher {
     transform: translate3d($sidebar-width, 0, 0);
   }
   .sidebar-open-right .sidebar-pusher {
     transform: translate3d(-$sidebar-width, 0, 0);
   }
   .sidebar {
     transform: translate3d(0, 0, 0) scale(.9, .9);
   }
   .sidebar.cart {
     transform: translate3d(0, 0, 0) scale(.9, .9);
   }
 }

 [class*=sidebar-open-] .sidebar {
   visibility: visible;
   transition: transform 0.5s;
 }
 
 .sidebar::after {
   display: none;
 }
 /* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
 
 .no-csstransforms3d .sidebar-pusher,
 .no-js .sidebar-pusher {
   padding-left: 300px;
 }
