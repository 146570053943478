/*header[data-headerfix=true]{
  position:fixed;
  top:-100px;
  box-shadow:0 0 10px rgba(0,0,0,.3);
}*/


header {
  position: relative;
  width:100%;
  transition:all .2s ease;
  top:0;
  position: fixed;
  z-index:100;
  left:0; 

  &:before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: linear-gradient(to top, rgba(68,48,46,.55), rgba(46,30,29,.85));
  }
  &:after{
    content:'';
    position: absolute;
    top:0;
    opacity:1;
    left:0;
    width:100%;
    height:100%;
    z-index: -1;
    background-image: url(/assets/images/driver-new.jpg);
    background-size: 100%;
    background-position: top center;
    @include media($mobile-menu-break) {
      background-size: 100%;
    }
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  background: transparent;
  /*height:100px;
  @include media($tablet) {
    height:150px;
  }*/

  //background-color:#000;
  
  box-shadow:0 0 6rem rgba(46,30,29,.9);
  /*fix for CMS Area*/
  .container {
    height:$header-height-mobile;
    @include media($mobile-menu-break) {
      height:$header-height-desktop;
    }
    //border-bottom:1px solid #ddd;
    @include flexbox;
    position: relative;
    z-index: 100;

    .title{
      position: absolute;
      width:100%;
      padding:0;
      left:0;
      display:block;
      text-align: center;
      z-index: 1;
      @include media($mobile-max) {
        @include ellipsis(12rem);
        left:50%;
        font-size:1.05rem;
        margin-left:-6rem;
      }
      line-height: $header-height-mobile;     
      @include media($mobile-menu-break) {
        line-height: $header-height-desktop;      
      }
      
      color:#fff;
      font-size:1.25rem;
    }
    
    .offline-mode{
      position: absolute;
      display:block;
      top:40px;
      margin-left:-10px;
      @include media($mobile-menu-break) {
        top:60px;
        margin-left:0px;
      }
      text-align: center;
      width:100%;
      span{
        @include alert-theme-inverted($error-color,$icon-error);
        &:before{margin:-.1rem .2rem 0 .1rem;display:inline-block;}
        font-size:.75em;
        padding:.3em .4em;
        width:auto;
        height:auto;
        margin:3.1rem auto 0;
      }
    }
  }
  width: 100%;
  .logo {
    display: none;
    
    @include media($mobile-menu-break) {
      display: block;
    }
    position: relative;
    z-index: 2;
    overflow: hidden;
    align-self: baseline;
    margin: .3em 0 0;
    padding: 0 1% 0 1%;
    
    .logo-icon {
      transition:all .2s ease;
      width: 92px;
    }
    @include media($mobile-menu-break) {
      margin: .4em 0 0;
      .logo-icon {
        width: 130px;
      }
    }
    img {
      display: block;
    }

    .logo-text {
      width: 220px;
      height:150px;
    }
  }
  .searcher {
    display: inline-block;
    padding: 6.5em 3% 0 3%;
    &:before {
      @include icon($icon-search);
      font-size: 150%;
      color: #fff;
      @include media($tablet) {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      display: block;
    }
  }
  .menu {
    display: inline-block;
    text-align: center;
    z-index: 2;
    position: relative;
    &:before {
      @include icon($icon-menu-sml);
      font-size: 182.5%;
      color: #fff;
      padding:.4em;
    }
    /*&:after{
      content:"Menu";
      color:#666;
      font-size:.65rem;
      display: block;
      margin-top:.1rem;
    }*/
  }
  .mobile-menu {
    display: block;
    margin: -10px auto 0 auto;
    width: 100%;
    text-align: center;
    z-index: 2;
    &:before {
      @include icon($icon-menu);
      font-size: 150%;
      color: $sec-color;
      padding-top: 15px;
      padding-bottom: 10px;
      display: block;
    }
    @include media($mobile-menu-break) {
      display: none;
    }
  }
  .mysps{
    &:before {
      content:$icon-account;
      font-size: 175%;
    }
    /*&&:after{
      content:"My SPS";
    }*/
  }
  .blog{
    &:before {
      content:$icon-newspaper;
      font-size: 165%;
    }
    &:after{
      content:"Blog";
    }
  }
  .myaccount{
    display: none;
    @include media($mobile-menu-break) {
      display:inline-block;
    }
    span{
      display:none;
      @include media($lg-tablet) {
        display:inline-block;
      }
      font-size:.95rem;
      &:after{
        @include icon($icon-arrow-down);
        font-size:.6rem;
        display: inline-block;
        margin:0 .6rem 0 .5rem;
      }
    }
    &>a>i{
      @include circle-border(#fcfcfc, .075em);
      font-size:1.4rem;
      display: inline-block;
      margin:0 .5rem 0 .2rem;
    }
  }

  &.mobile-menu-open {
    nav {
      max-height: 400px;
    }
    .mobile-menu {
      &:before {
        content: $icon-cross;
      }
    }
  }
  nav {
    &>ul{
      @include list-reset();
      &>li{
        padding: 0 2% 0 2%;
        line-height: $header-height-mobile;
        @include media($mobile-menu-break) {
          padding: 0 1% 0 1%;
          line-height: $header-height-desktop;
        }
        cursor:pointer;
      }
    }
    text-align: right;
    @include flex(1);
    max-height: 100%;
    position: relative;
    display: block;
    color:#fff;
    a{opacity:.9;transition: all .2s ease;}
    a:hover{opacity:1;text-decoration:none;}
  }
}

.home{
  .title{
    display:none !important;
  }
  header .logo{
    display: block;
  }
  .back-btn{display:none;}
}


.back-btn{
  font-family: $title-font;
  position: absolute;
  left:0;
  top:0;    
  z-index: 2;
  line-height: $header-height-mobile;     
  @include media($mobile-menu-break) {
    line-height: $header-height-desktop;      
  }
  padding:0 .8rem;
  font-size:1.2rem;
  i{font-size:1.9rem;display:inline-block;margin:-.2rem .1rem 0 0;color:#fff;}
  &:hover{
      color:#555;
  }
}

@include media($mobile-menu-break) {
  header .back-btn{display:none;}
}