[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, main {
    display: block;
}

//$min_width: 320px;
//$max_width: 1600px;
$min_font: 16px;
$max_font: 20px;

* {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
input, textarea, [contenteditable] {
    -webkit-touch-callout:default;
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
}
html {
    @include fluid-type($mobile-value, $container-maxwidth, $min_font, $max_font);
    height:100%;
    [ui-view="fullpage"],login{height:100%;width:100%;position:absolute;top:0;z-index:9999;}
}
body{
    height:100%;
    font-family:$content-font;
    line-height: $content-line-height;
    font-weight:normal;
    background-color: $background;
    color: $content-font-color;
    //font-size: $content-font-size;
    -webkit-font-smoothing: antialiased;
}
body .content-container{
    background-color: $background;
    height:calc(100% - #{$header-height-mobile});
    margin-top: $header-height-mobile;     
    @include media($mobile-menu-break) {
        height:calc(100% - #{$header-height-desktop});
        margin-top: $header-height-desktop;      
    }
}
ui-view{
    overflow: hidden;
    width:100%;
}
main{
    @include container-padding;
    min-height: 300px;
    padding-top:4.5rem;
    padding-bottom:5.5rem;
    position:relative;
}

img,
picture {
    margin: 0;
    max-width: 100%;
    height: auto!important;
}


main.homepage{
    @include media($lg-desktop){
        //background-image:url(../images/bg.jpg);
        //background-size:100%;
        //background-position:0 350px;
        //background-repeat:no-repeat;
    }  
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, select {
    margin: 0;
    padding: 0;
    border: 0;
    //font-size: 100%;
    //font: inherit;
    outline: none;
    vertical-align: baseline;
}


::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

