/* Variables
================================== */
$bw: 1px;  // border width
$black:rgba(12, 21, 28, 0.88);
$dark-grey:rgb(65, 65, 65);
$grey:rgb(176, 176, 176);

$subnav-height-mobile: 52px;
$subnav-height-desktop: 68px;


.MySPS main{
    padding-top:5.5rem;
     max-width:950px;
}



.sub-nav{
  
    width:100%;
    background-color:#333;
    opacity:.9;
    position:fixed;
    z-index:99;
    top: $header-height-mobile;     
    @include media($mobile-menu-break) {
        top: $header-height-desktop;      
    }
    
    ul{        
        @include list-reset;
        @include container-padding;
        li{
            float:left;
            margin:0;
            text-align: center;
            a{display:block;height:$subnav-height-mobile;line-height:$subnav-height-mobile;width:100%;}
            width:25%;
            h4{display:none;padding:0;margin:0 .5rem;color:#ccc;font-size:.925rem;line-height:$subnav-height-mobile;padding:0;font-weight:normal;}
            @include media($sm-tablet) {
                h4{display:inline-block;}
            }
            @include media($mobile-menu-break) {
                h4{line-height:$subnav-height-desktop;}
                a{height:$subnav-height-desktop;line-height:$subnav-height-desktop;}
            }
            i{display:inline-block;margin:-4px 0 0;font-size:1.15rem;            
                @include circle-border(#777, 2px, .5rem);
                &.icon-note{
                    font-size:1rem;  
                    padding:.55rem;
                }
            }
            &.active,&:hover{
                background-color:#000;
            }
        }
    }
    &.sub-nav-alt{
        
        background-color:#ddd;
        ul li{
            width:33.33%;text-align:center;padding:0;
            a{
                display:table;
                height:50px;text-align:center;
                margin:0 auto;
            }
            h4{padding:0;margin:0 .5rem;color:#555;display:table-cell;vertical-align:middle;line-height:1;}
            &.active,&:hover{background-color:#aaa;}
        }
        top:$subnav-height-mobile + $header-height-mobile;
        @include media($mobile-menu-break) {
            top:$subnav-height-desktop + $header-height-desktop;
            ul li a{height:60px;}
        }
    }
    ~main{
        margin-top:1rem;
    }
}



/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
}
.Rtable-cell {
  box-sizing: border-box;
  font-size:.9rem;
  position: relative;
  width: 100%;  // Default to full width

  [contenteditable],&.Rtable-head{
    padding: 0.5rem;
    @include media($mobile) {
        padding: 0.75rem;
    }
    min-height:3.5rem;
    line-height:1.15;
  }

  //overflow: hidden; // Or flex might break
  list-style: none;
  color:#555;

  //border: solid $bw white;
  //background: #eee;
  > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0;padding:0;}
  h5{
      color:#000;
      line-height:1.1;
  }
    &.Rtableactive{
        position: relative;
        background-color:$grey !important;
        h4{}
        &:after{
            @include triangle(top, $grey, 1rem);
            position:absolute;
            bottom:-1rem;
            margin-left:-1rem;
            left:50%;
        }
    }
    strong{
        color:#000;
    }
}
.Rtable-container{
    i{
        //text-shadow:0 0 1px #333;
    }
    //background-color:#eee !important;
    padding:.3rem 0rem;
}
.Rtable-title{padding:2rem 1rem 1rem;
    h3{margin:0;padding:1rem 0;}
}

.Rtable.RNav{
    background-color:#eee !important;

    
    h4{
        font-size:.85rem;
        font-weight:bold;
        color:#fff;
    }
    .Rtable-head.Rtable-cell{

        &>i{font-size:2.5rem;color:#777;margin:.5rem 0 .5rem;}
        &>a{
            i{margin:-.4rem .2rem -.3rem 0}
            display: block;
            text-align: center;
        }
        opacity:.75;
        cursor:pointer;
        border:none;
        border-right: solid 1px #ccc;

        background-color:#ddd;
        h4{color:#555;}

        padding:1.5rem .5rem;
        @include media($phablet-max) {
           h4{display:none;}
        }
    }
    .RTable-link{        
        opacity:.75;
        &:hover{
            opacity:1;
        }
    }

    .RTable-link > a {
        width: 100%;
        height: 100%;        
    }

    .RTable-multi{
        @include media($sm-tablet-max) {
           display:none;
        }
    }
}

.Rtable-aux{
        //padding:.5rem 0;
        text-align: center;
        .Rtable-cell{min-height:auto;border: solid 0 white;background: #eee;}
        a{display: inline-block;margin:0 .4rem 0 0;color:#aaa;
            i{margin:-.2rem 0 0 0}
        }
}


@include media($sm-tablet) {
/* Table column sizing
================================== */
    .Rtable--2cols > .Rtable-cell  { width: 50%; }
    .Rtable--3cols > .Rtable-cell  { width: 33.33%; }
    .Rtable--4cols > .Rtable-cell  { width: 25%; }
    .Rtable--5cols > .Rtable-cell  { width: 20%; }
    .Rtable--6cols > .Rtable-cell  { width: 16.6%; }

}


    .Rtable-head{
        position: relative;
        padding:.4rem 0 .4rem;
        h4 i,h5 i{margin:-.2rem .3rem 0 .1rem;}
        h5{padding:0;margin:0;}
        .icon-tick-circle{color:$green;}
        .edit-button{position:absolute;right:0;top:.3rem;}
    }


.RGoals.Rtable {
    margin:2rem 0;
    .Rtable-cell{
        border:$bw solid #fff;
    }
    .Rtable-cell.Rtable-head{
        background:#dedede;
        min-height:auto;
        @include media($sm-tablet-max) {
            &.Rtable-col-head{display: none;}
        }
    }
    .Rtable-cell.Rtable-head.Rtable-row-head{
        background:#e0e0e0;
        @include media($sm-tablet-max) {
            width:100%;
            text-align: center;
            min-height:auto;
        }
    }
    [contenteditable]{
        border:1px solid #cfcfcf;
        position: relative;
        @include media($sm-tablet-max) {
        padding-top:2.4rem;
        min-height: 4.5rem;
        &:after{
            content: attr(title);
            display:inline-block;
            height:1.75rem;
            line-height:2.1;
            left:.0;
            position: absolute;   
            background-color:#eee;
            top:0;         
            width:100%;
            font-size:.85rem;
            text-align: center;
        }
        }
    }
    h5{font-weight:bold;}
    /*.Rtable-cell:nth-child(4n+2){
        background:lighten(#ddd,3%);
    }
    .Rtable-cell:nth-child(4n+3){
        background:lighten(#ddd,6%);
    }
    .Rtable-cell:nth-child(4n+4){
        background:lighten(#ddd,9%);
    }*/
}



.btn.btn-invert.reminder{
    //position: absolute;
    float:right;
    transition:opacity .2s linear;
    opacity:.8;
    margin:-.4rem 0 -.4rem 0;
    padding:0 0.3rem 0 0;
    line-height: 1.9;
    &:hover{
        opacity:1;
    }
    &.reminder-set:before{
        color:$main-color;
    }
    .badge{
        display:inline-block;
        width:1.1rem;
        float:left;
        height:1.1rem;
        margin:.15rem;
        font-size:.7rem;
        //margin:-.6rem .2rem -.4rem -.4rem;
        line-height: 1.7;
        padding:0;
    }
}

.RStatements{
    position: relative;
    margin:1rem 0;
    .Rtable-head{position: relative;}
    //border: solid $bw transparent;
    h5{    
        padding:.6rem 0;
    }
    .btn.btn-invert.reminder{
        margin:-.4rem 0 -.4rem 0;
    }


    .Rtable-group{
        border:none;
        padding:.4rem 2.5rem .3rem 0;
        position: relative;
    }

    .Rtable-cell[contenteditable]{
        //background:lighten(#eee,3%);
        padding: .7rem;
        border: $bw solid #eee;
        background-color:#fff;
    }
}

.Rtable-holder{
    background-color:#fff;
    border:1px solid #eee;
    padding:1rem 1.2rem 1rem;
    margin:.5rem 0;
    overflow: hidden;
} 

.Rtable-new{
    margin:1.5rem 0 1.2rem;width:auto;
    cursor:pointer;
    font-size:.85rem;
    i{font-size:1.2rem;display:inline-block;margin:-.3rem .2rem -.1rem 0rem;}
    &:hover{
        a{color:#444;}
    }
}

.Rcell-title[contenteditable]{
        margin:.0rem 0 .2rem;
        font-weight:bold;
        min-height:auto;
}

.Rcell-message{
        margin:-1px 0 .5rem;
        min-height:3.5rem;
}

.Rcell-delete{
        position: absolute;
        right:-1.5rem;
        transform:rotate(45deg);
        &:before{
            @include icon($icon-plus-circle);font-size:1.1rem;
        }
        margin:0;
        transition:opacity .2s linear;
        opacity:.6;
        cursor:pointer;font-size:.8rem;display:inline-block;
        &:hover{
            opacity:1;
        }
}

 [contenteditable=true]{
    transition:all .1s linear;
    font-size:16px;
    word-wrap: break-word;
    &:hover{
        background-color:#fff !important;
    }
    &:focus{
        background-color:#fff !important;
        //border: solid $bw #ccc;
        transform:scale(1.04);
        @include media($sm-tablet) {
        transform:scale(1.15);
        }
        opacity: 1 !important;
        border:1px solid rgba(0, 158, 250, 0.5) !important;
        box-shadow:0 0 1.5rem rgba(0, 158, 250, 0.5);
        position: relative;
        z-index: 9;
    }
 }

 [contenteditable=true]:empty{
       opacity:.5;
 }

[contenteditable=true]:empty:before{
  content: "Enter text here...";
  font-style:italic;
  display: block; /* For Firefox */
}


[contenteditable=true][placeholder]:empty:before{
  content:attr(placeholder);
}


.help-holder{
    display:inline;
}

.Rtable-head{

    h4 i.helpicon,h5 i.helpicon{margin:-.3rem .1rem 0 .1rem;}
}

.helpicon {
    transition:all .3s linear;
    background-color:transparent;
    border-radius:50% 50% 0 0;
    padding:.1rem .4rem 0 .4rem;
    text-align: center;
    opacity: .5;
    float:right;
    margin-top:-.4rem;
    cursor: pointer;
    &:hover{
        opacity:1;
    }
    &:before{
        content:$icon-info-circle;
        font-size:1.3rem;
        transform: rotate(0);
        display: block;
    }
}
.RTable-head .helpicon{
    float: right;
} 

  h1+.help-holder .helpicon,
h1+form .help-holder .helpicon {

    margin-top:.6rem;
}

.helpicon.infohide{
    background-color:#fff;
    opacity:1;
    padding:.4rem;
    &:before{
        content:$icon-plus-circle;
        transform: rotate(-45deg);
        display: block;
    }
}
.info{
    background-color:#fff;
    //padding:1rem;
    cursor: pointer;
    padding:0 1.5rem 0 1.5rem;
    max-height: 0;
    font-size:.85rem;
    overflow: hidden;
    font-size:.75rem;
    width:100%;
    font-weight:normal;
    transition:max-height .3s linear;
    strong:first-child{padding:.8rem 0 .2rem;display:block;font-size:.85rem;}
    p{
        margin:0;
        padding:0 0 .2rem;
        line-height: 1.15;
    }
    ol, ul {
        margin: 0;
        padding: 0;
    }
    ol li, ul li {
        margin: 0 0 0 1rem;
        line-height: 1.15;
        padding: 0 0 0.2rem 0;
    }
}
.info.infohide{
      max-height:350px;
      padding-bottom:.5rem;
}

.Rtable-group .Rtable-head .info.infohide{
    max-height:370px;
}

.chart-container{
   position: relative;width:100%;
}
.chart{
    padding:0 0 1rem;
}
.chart-buttons{
    float: right;
    overflow: hidden;
    z-index: 1;
    padding:.8rem .5rem 1rem .5rem;
    position: relative;
    .btn.btn-xs {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-right: 10px;
    }
}





.input-counter {
    position:absolute;
    bottom:.2rem;
    right:.3rem;
    display: none;
    opacity:.6;
    font-size:.75rem;
    z-index: 9999;
    color:#555;
    @include media($sm-tablet) {
        bottom:0rem;
        right:-.55rem;
    }    
}


article.article-detail{
    border:0;
    .about-mysps-body{
        max-width:950px;
        margin:-2rem auto;
        padding: 5%;
    }
  
}

