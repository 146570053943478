
  @font-face {
    font-family: "icon";
    src: url(#{$font-path}Flaticon.woff?v#{random(300)}) format("woff");
    font-weight:normal;
    font-style:normal;
  }

	@mixin icon($icon: "\f100") {
		content:$icon;
		font-family: 'icon';
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		text-decoration: inherit;
		vertical-align: middle;
		/* Better Font Rendering =========== */
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
	}

	[class^="icon-"], [class*=" icon-"] {
        display: inline-block;
         @include icon;
  }

  .fi:before{
      display: inline-block;
      font-family: "Flaticon";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1;
      text-decoration: inherit;
      text-rendering: optimizeLegibility;
      text-transform: none;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
   }

    
    .icon-comment:before { content: "\f100"; }
    .icon-refresh-lock:before { content: "\f101"; }
    .icon-password:before { content: "\f102"; }
    .icon-tick-thick:before { content: "\f103"; }
    .icon-avatar:before { content: "\f104"; }
    .icon-plan:before { content: "\f105"; }
    .icon-tool:before { content: "\f106"; }
    .icon-rating:before { content: "\f107"; }
    .icon-graph:before { content: "\f108"; }
    .icon-note:before { content: "\f109"; }
    .icon-eye:before { content: "\f10a"; }
    .icon-more:before { content: "\f10b"; }
    .icon-notifications-on:before { content: "\f10c"; }
    .icon-info-circle-full:before { content: "\f10d"; }
    .icon-info-circle:before { content: "\f10e"; }
    .icon-settings:before { content: "\f10f"; }
    .icon-history:before { content: "\f110"; }
    .icon-new-message:before { content: "\f111"; }
    .icon-question:before { content: "\f112"; }
    .icon-loading:before { content: "\f113"; }
    .icon-arrow-up:before { content: "\f114"; }
    .icon-arrow-left:before { content: "\f115"; }
    .icon-arrow-down:before { content: "\f116"; }
    .icon-arrow-right:before { content: "\f117"; }
    .icon-warning:before { content: "\f118"; }
    .icon-minus-circle:before { content: "\f119"; }
    .icon-plus-circle:before { content: "\f11a"; }
    .icon-menu:before { content: "\f11b"; }
    .icon-back:before { content: "\f11c"; }
    .icon-menu-sml:before { content: "\f11d"; }
    .icon-forward:before { content: "\f11e"; }
    .icon-link-full:before { content: "\f11f"; }
    .icon-creativity:before { content: "\f120"; }
    .icon-target:before { content: "\f121"; }
    .icon-link:before { content: "\f122"; }
    .icon-account:before { content: "\f123"; }
    .icon-tick-circle:before { content: "\f124"; }
    .icon-error:before { content: "\f125"; }
    .icon-danger:before { content: "\f126"; }
    .icon-lock:before { content: "\f127"; }
    .icon-unlock:before { content: "\f128"; }
    .icon-search:before { content: "\f129"; }
    .icon-list:before { content: "\f12a"; }
    .icon-tick:before { content: "\f12b"; }
    .icon-newspaper:before { content: "\f12c"; }
    .icon-blog:before { content: "\f12d"; }
    .icon-cancel-button:before { content: "\f12e"; }
    .icon-add-button:before { content: "\f12f"; }
    .icon-notifications:before { content: "\f130"; }
    .icon-rubbish-bin:before { content: "\f131"; }
    .icon-suitcase:before { content: "\f132"; }
    .icon-cross:before { content: "\f133"; }
        
        $icon-comment: "\f100";
        $icon-refresh-lock: "\f101";
        $icon-password: "\f102";
        $icon-tick-thick: "\f103";
        $icon-avatar: "\f104";
        $icon-plan: "\f105";
        $icon-tool: "\f106";
        $icon-rating: "\f107";
        $icon-graph: "\f108";
        $icon-note: "\f109";
        $icon-eye: "\f10a";
        $icon-more: "\f10b";
        $icon-notifications-on: "\f10c";
        $icon-info-circle-full: "\f10d";
        $icon-info-circle: "\f10e";
        $icon-settings: "\f10f";
        $icon-history: "\f110";
        $icon-new-message: "\f111";
        $icon-question: "\f112";
        $icon-loading: "\f113";
        $icon-arrow-up: "\f114";
        $icon-arrow-left: "\f115";
        $icon-arrow-down: "\f116";
        $icon-arrow-right: "\f117";
        $icon-warning: "\f118";
        $icon-minus-circle: "\f119";
        $icon-plus-circle: "\f11a";
        $icon-menu: "\f11b";
        $icon-back: "\f11c";
        $icon-menu-sml: "\f11d";
        $icon-forward: "\f11e";
        $icon-link-full: "\f11f";
        $icon-creativity: "\f120";
        $icon-target: "\f121";
        $icon-link: "\f122";
        $icon-account: "\f123";
        $icon-tick-circle: "\f124";
        $icon-error: "\f125";
        $icon-danger: "\f126";
        $icon-lock: "\f127";
        $icon-unlock: "\f128";
        $icon-search: "\f129";
        $icon-list: "\f12a";
        $icon-tick: "\f12b";
        $icon-newspaper: "\f12c";
        $icon-blog: "\f12d";
        $icon-cancel-button: "\f12e";
        $icon-add-button: "\f12f";
        $icon-notifications: "\f130";
        $icon-rubbish-bin: "\f131";
        $icon-suitcase: "\f132";
        $icon-cross: "\f133";