.login-container {
  background: url(/assets/images/driver-new.jpg) no-repeat 0 0 #000;
  background-size: 100%;
  width: 100%;
  height: 100%;
  overflow:hidden;
  @include flexbox;
  justify-content: center;
  position:relative;
  align-items: center;
  main {
    height: 100%;
  }
  .logo{
      display:block;
      text-align: center;
  }
  .logo img {
    width: 9rem;
    margin:.1rem 0;
    @include media($sm-tablet) {
      margin:.2rem 0;
    }
  }
  h2{margin-top:40px;}
  h3{
    padding:1.25rem 0 .8rem;
  }
  .btn-group{
    margin-bottom:1rem;
  }
}

.login-form {
  @include flexbox;
  padding:0!important;
  max-width:460px;
  width:100%;
  height:100%;
  @include media($mobile) {
    height:auto;
  }
  background-color: rgba(250, 250, 255, .96);
  box-shadow:0 2px 25px rgba(0, 0, 0, .3);
}

.login-standard {
  padding: 1rem 7% 0 7%;
  width:100%;
  p.ip-warning {
    font-size: .75rem;
    margin-bottom:.4rem;
  }
  @include media($sm-tablet) {
    padding: 1rem 10% 0 10%;
    p.ip-warning {
      font-size: 14px;
    }
  }
}

a.forgot-link {
  display: inline-block;
  padding:.2rem 0;
  vertical-align: middle;
  font-size: 13px;
  color: #000;
}

.form-loader:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #fff;
  z-index: 100;
  transition: opacity .3s linear;
  visibility: hidden;
}

.form-loader.form-loading:before {
  visibility: visible;
  opacity: .4;
}
