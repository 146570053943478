      .home-brick {
          margin: 0rem auto;
          @include media($tablet) {
            margin: 1.5rem auto;
          }
          max-width:480px;
      .brick.mysps-brick {
          a:after{
            content:$icon-account;
          }
      }
      .brick {          
          width:100%;margin:1% 0;      
          height: 75px;
          &.masonry-brick-lge{ height: 100px;}
        @include media($sm-tablet){    
          height: 85px;
          &.masonry-brick-lge{ height: 120px;}
        }
        img {
          width: 100%;
        }
        a{display:block;height:100%;transform:scale(1);opacity:1;transition:all .2s ease;}
        a:after{
          @include icon($icon-forward);
          color:#ddd;
          float:right;
          position:absolute;
          z-index:1;
          margin-top:-19px;
          top:50%;
          right:25px;
          font-size:38px;
          opacity:.8;
        }
        a:hover{opacity:.8;
          
          @include media($sm-tablet){   
             transform:scale(1.05);
          }
          
          z-index:99;position: relative;}
        a:hover:after{
          opacity:1;
          
          @include media($sm-tablet){   
            transform:scale(1.05);
         }
          
          z-index:99;position: relative;
        }
        a.invert-block{
          h4{color:#000;}
          h4.invert-title{}
          .header{background-color:#fff !important;}
        }
      }
      h4 {
        position: absolute;
        top: 15px;
        color: #fff;
        padding:0 18px;
        max-width:85%;
        line-height:1em;
        z-index: 2;
        font-weight:normal;
      }
      .header {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        opacity: .95;
        background-color: rgba($main-color, .95);
        h3,
        h4,
        p {
          color: #fff;
          margin: 0;
          padding: 0;
          text-shadow: 0 0 2px rgba(0, 0, 0, .1)
        }
        &.header-img{background-position:right center;background-size:cover;}
      }
    }


    .masonry-brick.loaded {
      display: block;
    }
    

    .invert-title{display:none!important;}
    .invert-block{
      h4,span{display:none!important;}
      .invert-title{display:block!important;}
    }

    .home .content-container{
        position: absolute;
        top: 0;
        background-image:url(/assets/images/driver-new.jpg);
        left: 0;
        //transition: all 1s linear;
        background-size:100%;
        //background-attachment:fixed;
        background-repeat:no-repeat;
        background-position:0 -#{$header-height-mobile};     
        @include media($mobile-menu-break) {
          background-position:0 -#{$header-height-desktop};      
        }
        width:100%;
        background-color:#000;
    }
    .image-header.is-loading{height:100%;}

    /**** vvvv HERE IS THE NEW STUFF vvvv  ****/
    .slider-container{
        position: relative;
        background: url(/assets/images/driver.jpg) no-repeat 100% 100%;
        background-size: cover;
        /* you have to add a height here if your container isn't otherwise set 
          becuse the absolutely positioned image divs won't calculate the height 
          for you */
        height:300px;
        width:100%;
        .header {
          height:60px;        
        }
        h4{
          padding:10px 10px;bottom: 0;margin:0;
          small{font-size:65%;font-weight:$content-font-weight;}
          opacity:0;
          width:100%;
          visibility:hidden;
          transition:all 1s linear;    
          background-color: rgba(darken($main-color,15%), .88);
        }
        a{
          color:#fff;
          transition:opacity .2s linear;
          &:hover{
              opacity:.6;
          }
        }   
    }

    .blog-footer{
      position:fixed;
      bottom:0;
      z-index:0;
      width:100%;
      box-shadow:0 0 10px rgba(0,0,0,.2);
      background-color: rgba(#222, .92);
      height:80px;
      .blog-item{
          opacity:0;visibility:hidden;position:absolute;top:0;left:0;width:100%;transition:opacity 1s ease;
      }
      .container{max-width:475px;margin:0 auto;position:relative;height:80px;
          a{opacity:.85;transition:opacity .2s ease;display:block;}
          a:after{
            @include icon($icon-forward);
            color:#eee;
            float:right;
            position:absolute;
            z-index:1;
            margin-top:-19px;
            top:50%;      
            height:100%;
            right:20px;
            font-size:38px;
            opacity:.8;
          }
          a:hover{opacity:1;}
          a:hover:after{opacity:1;}
          h5{color:#aaa;padding:16px 0 4px 0;margin:0;text-shadow:0 0 10px rgba(0,0,0,1);}
          p{color:#fff;padding:0;margin:0;text-shadow:0 0 10px rgba(0,0,0,1);font-weight:600;max-width:85%;line-height:.825em;}
      }

    }

    /* Animations */
    .ng-fade{
        opacity:1 !important;
        //z-index:1;
        visibility:visible !important;
    }