 .search-bar{
        width:100%;
        margin:5px;
	    @include flexbox;
        input{
            @include flex(1);
            color: $sec-color;
            font-family: $content-font;
            font-weight: normal;
            margin:0;
            height:45px;
            border-right:none;
            background-color:#f4f4f4;
        }
        button.btn{
            width:50px;
            height:45px;
            @include btn;
            border:none; 
            margin:0;
            padding-left:0;
            padding-right:0;
            text-align: center;
            &:before{
                @include icon($icon-search);
                position:relative;
                font-size:21px;
            }
            &:hover{background-color:$sec-color;}
        }
        
    }
