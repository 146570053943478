$expand-trans:all .3s ease;


dl {
    margin:1rem 0;

    dt,.dt {
        border-radius:.8rem;
        cursor: pointer;
        font-weight: normal;
        transition: $expand-trans;
        margin:0 0 0rem;
        position:relative;
        color:#fff;
        padding:.5rem 1.2rem .6rem 0rem;
        @include media($tablet){
            padding:.5rem 0.6rem;
        }
        &:after {
            @include icon($icon-arrow-down);
            transition:$expand-trans;
            display: block;
            padding: 1rem .5rem;
            @include media($tablet){
                padding: 1rem 1.3rem;
            }
            position:absolute;
            right:0;
            top:.6rem;
            text-align: center;
            color:#444;
            font-size: 1.1rem;
            line-height: 1;
        }
    }

    dt:hover {
    // background-color: $second-color;
    }

    dt,.dt, dd,.dd {
        opacity:.75;
    }
    dt.expand,.dt.expand, dd.expand,.dd.expand {
        opacity:1;
    }

    dt.expand:after,.dt.expand:after {
        //content: $icon-chevron-left;
        transform: rotate(180deg);
    }

    

    dd,.dd {
        @include media($tablet){
            padding: 0 2rem .2rem;
        }
        border-bottom:2px solid #ddd;
        margin: 0 0 0;
        height: auto;
        overflow: hidden;
        position: relative;
        max-height: 0;
        font-size: 1rem;
        transition:  $expand-trans;
        dt:after,.dt:after {
            color:#777;
            font-size: 1rem;
            padding: .8rem 1.5rem;
        }
    }

    dd.expand,.dd.expand {
        max-height:3000px;
    }
    .dd:last-child{
        border-bottom:none;
    }

}