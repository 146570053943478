table{
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin:10px 0 15px;

    tr,
	td,
	th {
	    vertical-align: middle;
	}

	tr {
        background-color: #fff;
        border-top: 1px solid #e5e5e5;
    }
    td {
	    font-weight: normal;
	    font-family: $semi-font;
	    padding: 10px;
        i[class*=card]:before{font-size:2.9em;}
    }

    thead tr {
    	border-top:none;
        border-bottom: 1px solid #d9d9d9;
        font-weight:normal;font-family:$bold-font;font-size:14px;color:#888;
    }
    tr th {
        padding: 10px;
       	margin:0;
	    border-bottom: 2px solid #999;
	    font-weight: normal;
	    font-family: $bold-font;
	    text-align: left;
    }
}

@mixin table-xs{
	margin:5px 0;
	font-size:14px;
	td{padding:2px 5px;font-family:$reg-font;}
	.btn{margin:0;}
}

@mixin table-sm{
	tr th{font-family:$semi-font;font-size:13px;padding:4px 8px;}
	td{padding:4px 8px;font-family:$reg-font;}
	font-size:14px;
	.btn{margin:-2px 0;}
}

@mixin table-md{
	.btn{margin:-2px 0;}
}
@mixin table-lg{
	margin:10px 0;
	font-size:15px;
	td{padding:12px 12px;font-family:$semi-font;}
	.btn{margin:-2px 0;}
}
@mixin table-xl{
	td{padding:12px 12px;font-family:$bold-font;}
	.btn{margin:-2px 0;}
}

.table-selectable{
	tbody tr:hover{background-color:#eee;}
	a{display:block;}
	a.btn{display:inline-block;}
	.icon-pdf-file-symbol{font-size:19px;margin:-1px 0;}
}






table.table-xl{
	@include table-xl;
}

table.table-lg{
	@include table-lg;
}


table.table-md{
	@include table-md;
}

table.table-sm{
	@include table-sm;
}

table.table-xs{
	@include table-xs;
}
