

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}



/*flexbox*/

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($flex-grow, $flex-shrink:"", $flex-basis:"") {
  -moz-box-flex:  $flex-grow #{$flex-shrink} #{$flex-basis};
  -webkit-flex:  $flex-grow #{$flex-shrink} #{$flex-basis};
  -ms-flex:  $flex-grow #{$flex-shrink} #{$flex-basis};
  flex: $flex-grow #{$flex-shrink} #{$flex-basis};

   //Hack for IE
   @if $flex-basis == "" {
     $flex-check:append($flex-grow, "false");
     $flex-check:append($flex-check, "false");
     @if nth($flex-check, 3) != "false"{
         $flex-width: nth($flex-check, 3);
         max-width: $flex-width;
         width: 100%;
         height: 100%;
     }
   }
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}

/*CSS3 Columns*/
@mixin columns($column-count: 3, $column-gap: 1em, $column-rule:none) {
    column-count:$column-count;
    column-gap:$column-gap;
    column-rule: $column-rule;
}

@mixin columns-break($val:avoid) {
	 -webkit-column-break-inside: $val;
    -moz-column-break-inside:$val;
    column-break-inside:$val;
    page-break-inside: $val;
    break-inside: $val;
    display:table;   
}

@mixin empty-height {
        padding-top: 0!important;
        padding-bottom: 0!important;
        border-top-width: 0!important;
        max-height: 0;
        overflow: hidden!important;
}
