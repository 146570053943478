@mixin image-hover($image: "img"){
	overflow:hidden;
	#{$image}{transition:all .2s ease;opacity:1;}
	&:hover #{$image}{opacity:.8;-moz-transform: scale(1.1);-webkit-transform: scale(1.1);-o-transform: scale(1.1);transform:scale(1.1);}
}


@mixin noselect(){
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin responsiveImage(){
  max-width:100%;
  height: auto;
  width: auto\9; /* IE8 */
}


@mixin responsiveMaxImage(){
  max-width:100%;
  min-width:100%;
  height: auto;
  width: auto\9; /* IE8 */
}


@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}


@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin list-reset {
  margin:0;
  padding:0;
  list-style:none;
  li{margin:0;padding:0;}
}


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
  &:placeholder                {@content}  
}

@mixin circle-border($color: #666,$size: 1px, $padding:.5rem){
  border-radius:50%;
  border:$size solid $color;
  padding:$padding;
}