$checkbox-tick-icon: $icon-tick-thick;


input[type=radio],input[type=checkbox] {
  position: absolute;
  left: -9999px;
  height: 0;
  width: 0;
  user-select: none;
}

input[type=radio]:checked+.radio-btn,input[type=checkbox]:checked+.radio-btn {
    opacity:1;
    &:before{
      transform:scale(1);
      opacity: 1;
      margin:-.2rem .5rem 0 -.3rem;
      display:inline-block;
    }
}

input[type=radio]:checked+.radio-btn.radio-select,input[type=checkbox]:checked+.radio-btn.radio-select {
   span.text-unchecked{display:none;}
   span.text-checked{display:inline;}
}
input[type=radio]+.radio-btn.radio-select,input[type=checkbox]+.radio-btn.radio-select {
      span.text-checked{display:none;}
}

input[type=radio]:checked+.radio-btn:hover {


}


input[type=radio]:checked+.radio:after,input[type=checkbox]:checked+.checkbox:after {
  visibility: visible;
  transform: scale(1);
}

input[type=radio]:checked+.radio:before,input[type=checkbox]:checked+.radio:before {
  border: solid 1px $main-color;
}

input[type=radio]:checked+.checkbox:before,input[type=checkbox]:checked+.checkbox:before {
  visibility: visible;
    color: $main-color;
    transform: scale(1);
}






input[type=radio]:checked+.checkbox:after,input[type=checkbox]:checked+.checkbox:after {
  //background: $main-color;
  //border: solid 1px #aaa;
  
}

input[type=radio]:disabled+.checkbox,input[type=checkbox]:disabled+.checkbox {
  cursor: not-allowed;
  opacity: .4;
}



input[type=radio]+label.radio,input[type=checkbox]+label.checkbox {
  user-select: none;
  font-size:.9rem;
  display: inline-block;
}

input[type=radio]+label:hover,input[type=checkbox]+label:hover {
 // cursor: pointer;
}

input[type=radio]+label .sub,input[type=checkbox]+label .sub {
  color: #b7b7b7;
  display: block;
  margin-top: -3px;
}

label.radio-btn {
  opacity:.85;
  display:inline-block;
  width:auto;
  &:before{
      @include icon($checkbox-tick-icon);
      transition:all .1s ease;
      margin:-.3rem -.3rem 0 -.3rem;
      transform:scale(.1);
      opacity: 0;
      font-size:1.2em;
 }
}

.radio-btn.radio-btn--inline {
  width: auto;
  display: inline-block;
}

.radio-btn.radio-btn--inline:after {
  margin-left: 0.75rem;
}

.radio-btn:hover {
  cursor: pointer;
}

.radio-btn:after {
  display: none;
}

.radio-btn + .radio-btn{
  margin-right:.5rem;
}

.radio {
  margin: .2rem 0.7rem 0.7rem 0;
  width: 100%;
  padding-top:.2em;
  position: relative;
  padding-left: 1.7rem !important;
}

.radio.radio--inline {
  width: auto;
  display: inline-block;
}

.radio:hover {
  cursor: pointer;
}

.radio:before {
  content: "";
  display: block;
  border-radius: 50%;
  border: solid 1px #aaa;
  transition: border-color .1s ease;
  width: 22px;
  height: 22px;
  top:0;
  position: absolute;
  left: 0;
}

.radio:after {
  content: "";
  display: block;
  border-radius: 50%;
  transition: transform .1s ease;
  visibility: hidden;
  background:$main-color;
  width: 12px;
  height: 12px;
  transform: scale(.5);
  position: absolute;
  left: 5px;
  top: 5px;
  margin: 0;
}

.checkbox {
  margin: .2rem 0.1rem 0.5rem 0.1rem;
  padding-top:.2rem;
  @include media($mobile-menu-break){
     padding-top:0;
  }
  width: 100%;
  position: relative;
  padding-left: 1.5rem !important;
}

.checkbox.checkbox--inline {
  width: auto;
  display: inline-block;
}

.checkbox:hover {
  cursor: pointer;
}

.checkbox:before {
  transition:transform .1s ease;
  display: block;
  @include icon($checkbox-tick-icon);
  position: absolute;
  left: 2px;
  transform: scale(.01);
  top: 0px;
  font-size: 18px;
  z-index: 2;
  color: #222;
}

.checkbox:after {
  content:'';
  color:#fff;
  font-size:20px;
  border: $form-field-border;
  width: 21px;
  background-color:#eee;
  height: 21px;
  display: block;
  position: absolute;
  left: 0;
  top: -1px;
  margin: 0;
  z-index: 1;
}

.radio,.checkbox{
  transition:color .1s ease;
}
.radio:hover,.checkbox:hover {
  color:#000;
}



input[type=radio].input-lg,input[type=checkbox].input-lg{


}


input[type=radio].input-lg + .radio{
    font-size:115%;
    padding-left: 2.1rem;

    &:before {
      border: solid 1px #999;
      width: 26px;
      height: 26px;
    }

    &:after {
      background: $main-color;
      width: 16px;
      height: 16px;
      left: 5px;
      top: 5px;
    }

}



.reveal-area {
    transition: all .3s ease-out;
    max-height: 550px;    
    overflow: hidden;
}


input[type=radio].reveal-checkbox:checked+label+.reveal-area,
input[type=checkbox].reveal-checkbox:checked+label+.reveal-area {
    //max-height:520px;
    &~.btn-group #activate-card-btn{display:none;}
    &~.btn-group #confirm-email-btn{display:inline-block;}
}

input[type=radio].reveal-checkbox+label+.reveal-area,
input[type=checkbox].reveal-checkbox+label+.reveal-area {
    &~.btn-group #confirm-email-btn{display:none;}
    &~.btn-group #activate-card-btn{display:inline-block;}
}




.toggle-hidden.reveal-area {
    padding-top:0;
    padding-bottom:0;
    max-height:0;
    border-bottom:0;
}
