$sm-mobile-value: 360px;
$mobile-value: 420px;
$phablet-value:481px;
$lg-phablet-value: 580px;
$sm-tablet-value: 767px;
$tablet-value: 1000px;
$lg-tablet-value: 1100px;
$desktop-value: 1250px;
$lg-desktop-value: 1500px;

$sm-mobile-max: screen and (max-width: #{$sm-mobile-value});
$sm-mobile: screen and (min-width: #{$sm-mobile-value});
$mobile-max: screen and (max-width: #{$mobile-value});
$mobile: screen and (min-width: #{$mobile-value});
$phablet-max: screen and (max-width: #{$phablet-value});
$phablet: screen and (min-width: #{$phablet-value});
$lg-phablet: screen and (min-width: #{$lg-phablet-value});
$lg-phablet-max: screen and (max-width: #{$lg-phablet-value});
$sm-tablet: screen and (min-width: #{$sm-tablet-value});
$sm-tablet-max: screen and (max-width: #{$sm-tablet-value});
$tablet: screen and (min-width: #{$tablet-value});
$tablet-max: screen and (max-width: #{$tablet-value});
$lg-tablet: screen and (min-width: #{$lg-tablet-value});
$lg-tablet-max: screen and (max-width: #{$lg-tablet-value});

$desktop: screen and (min-width: #{$desktop-value});
$lg-desktop: screen and (min-width: #{$lg-desktop-value});

@mixin sm-mobile-max {
  @media screen and (max-width: #{$sm-mobile-value}) {
    @content;
  }
}

@mixin sm-mobile {
  @media screen and (min-width: #{$sm-mobile-value}) {
    @content;
  }
}


@mixin mobile-max {
  @media screen and (max-width: #{$mobile-value}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: #{$mobile-value}) {
    @content;
  }
}

@mixin phablet-max {
  @media screen and (max-width: #{$phablet-value}) {
    @content;
  }
}


@mixin phablet {
  @media screen and (min-width: #{$phablet-value}) {
    @content;
  }
}

@mixin lg-phablet {
  @media screen and (min-width: #{$lg-phablet-value}) {
    @content;
  }
}

@mixin sm-tablet-max {
  @media screen and (max-width: #{$sm-tablet-value}) {
    @content;
  }
}


@mixin sm-tablet {
  @media screen and (min-width: #{$sm-tablet-value}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$tablet-value}) {
    @content;
  }
}

@mixin lg-tablet {
  @media screen and (min-width: #{$lg-tablet-value}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-value}) {
    @content;
  }
}

@mixin lg-desktop {
  @media screen and (min-width: #{$lg-desktop-value}) {
    @content;
  }
}




@mixin media($query:screen and (min-width: 320)) {
  @media #{$query} {
    @content;
  }
}


.mobile-hide{
  @media screen and (max-width: #{$mobile-value}) {
    display:none;
  }
}

.desktop-hide{
  @media screen and (min-width: #{$desktop-value}) {
    display:none;
  }
}