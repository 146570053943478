

.articles-main{
 max-width:950px;
 margin-left: auto;
 margin-right:auto;   
}
.articles-main.main-full{
 max-width:100%;
}

article{
    border: 1px solid #dfdfdf;
	//box-shadow:0 2px 0 rgba(0,0,0,.01);
    figure{
        width:100%;
        max-height:400px;
        overflow:hidden;
        img{width:100%;}
    }
    .article-body{
        padding:15px 7% 20px;
        background-color:#fff;
        //border:1px solid #eee;
    }
    h3{color:$main-color;}
    h1{color:$main-color;}
    p{}    
}


.breadcrumb{
    h6{text-transform:uppercase;}
    h1{}
}

.articles-list{
    article{
        width:100%;margin:3px 0;      
        /*@include media($sm-tablet){     
            width:49%;margin:.5%;
        }*/
    }
    article:hover{
        //box-shadow:0 2px 10px rgba(0,0,0,.25);
        z-index:2;
    }
}
article.main-sidebar{
    flex:1;
}
aside{
    flex: 0 0 15em;padding:20px;
}
main{

}
.gutter-sizer {
  width: 1%;
}