$base-line-height: 24px;
$white: rgb(255,255,255);
$off-white: rgba($white, 0.2);
$spin-duration: 1s;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loading {
    border-radius: 50%;
    visibility:hidden;
    opacity:0;
    transition:opacity .2s linear;
    display:block;
    z-index:99999;
    margin-top:-$base-line-height;
    margin-left:-$base-line-height/2;
    width: $base-line-height;
    height: $base-line-height;
    border: .2rem solid $off-white;
    border-top-color: $white;
    animation: spin $spin-duration infinite linear;
  &--double {
    border-style: double;
    border-width: .5rem;
  }
}

.loading.btn-loading {
    position: absolute;
    top:70%;
    left:2rem;
}
$base-line-height:38px;

.page-loading{
    position: fixed;
    left:50%;    
    z-index:99999;
    top:40%;
    color:#333;
    &.loading{
      width: $base-line-height;
      height: $base-line-height;
      margin-top:-$base-line-height;
      margin-left:-$base-line-height/2;
      border: .25rem solid rgba(50,50,50,0.1);
      border-top-color: #333;
    }
}

.page-loading.loading-text{
    width: $base-line-height*6;
    font-style:italic;
    font-size:.8rem;    
    margin-top:.3rem;
    color:#333;
    text-align:center;
    margin-left:-$base-line-height*3;
    visibility:hidden;
    opacity:0;
    transition:opacity .2s linear;
}

.home .page-loading{
  color:#fff;
  &.loading-text{
     color:#fff;
  }
  &.loading{
    border: .25rem solid rgba(250, 250, 250, 0.15);
    border-top-color: #eee;
  }
}



.form-loading .loading,.is-loading{
	 visibility:visible !important;
   opacity:1 !important;
}