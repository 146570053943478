/*Paths*/
$font-path: "../assets/fonts/";
$image-path: "../assets/images/";

/*theme palete*/
$black:#000;
$dark-grey:#748191;
$red:#d20808;
$green:#3eb44a;
$orange:#de8500;
$purple:#90278e;
$blue:#487c85;

/*theme color variables*/
$main-color:#243D51;
$sec-color:#A9A9A9;
$third-color:#00adef;
$sale-color:$red;


/*alert colors*/
$success-color:$green;
$error-color:$red;
$danger-color:$red;
$info-color: $sec-color;
$warning-color: $orange;

$nav-icon-color:rgba(255, 255, 255, .5);

$background: #f4f5f7;

/*fonts*/
$bold-font:"proxima-nova", Arial, Helvetica, sans-serif;
$bold-font-weight:600;
$semi-font:"proxima-nova", Arial, Helvetica, sans-serif;
$reg-font:"proxima-nova", Arial, Helvetica, sans-serif;
$reg-font-weight:300;

$title-font:$bold-font;
$title-font-weight: $bold-font-weight;
$nav-font:$reg-font;
$content-font: $reg-font;
$content-font-weight: $reg-font-weight;


$content-font-color: #58595b;
$content-font-size:16px;
$content-line-height:1.3;

/*spacing*/
$small-spacing: .5rem;
$large-spacing:1rem;

$container-maxwidth:1366px;
$max-width:100%;

$header-height-desktop:90px;
$header-height-mobile:60px;


$grid-gutter: 5%;
$grid-vert-margin:0;
$grid-vert-padding:0;
$grid-breakpoint:$sm-tablet;



$sidebar-width:300px;

/*form fields: textarea, checkbox, selects etc*/
$form-inline-form-fields:false;
$form-field-label-color:$main-color;
$form-field-label-size:17px;
$form-field-border-color:$sec-color;
$form-field-border:2px solid $form-field-border-color;
$form-field-color:$main-color;
$form-field-radius: 0;
$form-field-font: $content-font;
$form-field-background-color: #f9f9f9;

$form-field-hover-border-color:#aaa;

$form-field-focus-border-color:#222;

$form-row-spacing:1em;

/*buttons*/
$button-radius:0;


$continer-padding-desktop:60px;

$mobile-menu-break:$sm-tablet;





