.ngdialog.ngdialog-theme-default{
    padding:0;
}

.ngdialog.ngdialog-theme-default .ngdialog-content{
    min-width:310px;
    max-width:600px;
    width:95%;
    padding:1.5rem .8rem 0 .8rem;
    @include media($phablet){
        padding:1.5rem 1.8rem 0 1.8rem;
    }
    .btn-submit{
        width:100%;
    }
}
.ngdialog.ngdialog-theme-default .ngdialog-close{
    padding:.2rem .6rem;
    &:before{
        font-size:2rem;
    }
    &:focus{
        outline:none;
    }
}

.ngdialog-content{
    overflow: auto;
    position: fixed;
    width: 100%;
    top:20px;
}