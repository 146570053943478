/*@font-face {
    font-family: 'Montserrat_Bold';
    src: url(#{$font-path}montserrat-bold-webfont.woff2) format('woff2'),
         url(#{$font-path}montserrat-bold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Montserrat_Regular';
    src:  url(#{$font-path}montserrat-regular-webfont.woff2) format('woff2'),
         url(#{$font-path}montserrat-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Montserrat_Semi_Bold';
    src: url(#{$font-path}montserrat-semibold-webfont.woff2) format('woff2'),
         url(#{$font-path}montserrat-semibold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
*/
@font-face {
    font-family: 'Avenir';
    src: url(#{$font-path}AvenirLTStd-Medium.woff2) format('woff2'),
        url(#{$font-path}AvenirLTStd-Medium.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src: url(#{$font-path}AvenirLTStd-Heavy.woff2) format('woff2'),
        url(#{$font-path}AvenirLTStd-Heavy.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'GT Haptik Bold';
    src: url(#{$font-path}GTHaptik-Bold.eot);
    src: url(#{$font-path}GTHaptik-Bold.woff2) format('woff2'),
        url(#{$font-path}GTHaptik-Bold.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@mixin font-bold{
    font-family:$bold-font;
    font-weight:bold;
    color:$content-font-color;    
}

@mixin font-semi{
    font-family:$semi-font;
    font-weight:bold;
    color:$content-font-color;
}

@mixin font-reg{
    font-family:$content-font;
    color:$content-font-color;
    font-weight:normal;
}