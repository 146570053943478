ul.list{
    @include list-reset();
    width:100%;
    li{
        font-size:1.1rem;
        position: relative;
        padding:1.5% 10% 1.5% 3%;
        p{
            margin:0;
            padding:0;
            font-size:1.1rem;
            margin:0 0 -.3rem;
            font-weight:bold;
        }
        >small{
            display:block;
            margin:.3rem 0;
            line-height: 1;
        }
        p+small{
            margin:.3rem 0 0;
        }
        .badge{
            margin:.4rem .2rem 0 0;
            float:right;
        }
        &:after{
            @include icon($icon-arrow-right);
            position: absolute;
            font-size:1.1rem;
            right:3%;
            color:#555;
            margin-top:-.6rem;
            top:50%;
        }
        border-top:1px solid #ddd;
        width:100%;
        display: block;
        cursor: pointer;
        transition:all .2s linear;
        &:hover{
            background-color:#fff;
        }
    }
    &.list-select{
        li{
            &:after{
                content:"";
            }
            &.selected:after{
                content:$icon-tick-thick;
            }
        }
    }
}

.ngdialog-content{
    h4{padding:1em 0 .6rem 0;
        i{float:right;margin-top:-.6rem;}
    }
    .back-btn{
        font-family: $title-font;
        position: absolute;
        left:0;
        top:0; 
        line-height: 1.2;   
        color:#999;
        padding:1.6em .7rem;
        margin-top:-.5rem;
        font-size:.8rem;
        i{   color:#999;font-size:1.35rem;display:inline-block;margin:-.2rem .1rem 0 0;}
        &:hover{
            color:#555;
        }
    }
    .select-holder,input[type=time]{
        height:2.7rem;
    }
    .btn-group{margin:0 0 .3rem;}
}


.time-holder{
    @include flexbox;
    width:100%;
    .select-holder{
        margin-right:-.2rem;max-width:135px;
        min-width:100px;
    }
    .select-holder select{
        @include flex(1);      
        //margin:0 1% 0 0;  
    }
}
