$dropmenu-bg: #222;
$tip-font: $content-font;
$tip-text-color: #fff;

.dropmenu-trigger {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: top;  
  transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
  a{color:#fff;}
}


.dropmenu-open .dropmenu-holder{
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  top:4rem;
}

.dropmenu-holder{
  transition:all .2s linear;
  visibility: hidden;
  opacity: 0;
  top:3rem;
  position:absolute;
  right:0;
  z-index: 99;
  width:100%;
  min-width:200px;
  border-radius:.3rem;
  background:rgba($dropmenu-bg,.95);
  box-shadow:0 0 10px rgba(0,0,0,.3);
  &:before{    
    @include triangle(bottom, rgba($dropmenu-bg,.95), .6rem);
    position: absolute;
    right:15%;
    @include media($lg-tablet){
      right:auto;
      left:50%;
    }
    top:-.6rem;
  }
  ul {
    @include list-reset();
    text-align: left;
    padding:.5rem 0;
    li{
      display:block;
      width:100%;
      border-top:1px solid #333;
      font-size:.85rem;line-height:1;
      a{display:block;line-height:1;width:100%;padding:.6rem 1rem;color:#eee;background-color:rgba(0,0,0,.01);
        transition:all .2s linear;
        &:hover{
          background-color:rgba(0,0,0,1);
        }
        i{margin:-.2rem .2rem 0  0;font-size:1.1rem;display:inline-block;}
      }
      &:first-child{
        border:none;
      }
    }
  }
}