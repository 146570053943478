@mixin alert-theme($color, $icon){

    &:before{@include icon($icon);}

    border-color: $color;
    background-color: scale-color($color, $lightness: 91%);
    //background-color:#eee;
    color: $color;
    h1,h2,h3,h4,h5,p{
          color: $color;
          &:first-child{margin-top:0;padding-top:0;}
          &:last-child{margin-bottom:0;padding-bottom:0;}
    }

}

@mixin alert-theme-inverted($color, $icon){
    &:before{@include icon($icon);}
    border-color: $main-color;
    background-color: $color;
    //background-color:#eee;
    color: #fff;
    h1,h2,h3,h4,h5,p{
          color: #fff;
          &:first-child{margin-top:0;padding-top:0;}
          &:last-child{margin-bottom:0;padding-bottom:0;}
    }
    a{color:#fff;}

}


.alert {
  padding: .8rem 1rem .8rem 2.4rem;
  font-size:85%;
  clear:both;

  li,p{text-transform:none;font-family:$content-font;}
  p:last-child{margin-bottom:0;padding-bottom:0;}

  border-width:1px;
  font-family: $semi-font;
  font-weight:normal;

  margin:.5rem 0;
  border-style:solid;color:#333;

  vertical-align:middle;
  line-height:1.05;
  position:relative;
  &:before{font-size:1rem;position:absolute;left:1rem;top:.7rem}

  @include media($phablet){
      font-size:100%;
      padding: 1rem 1.5rem 1rem 3rem;
      &:before{font-size:1.35rem;left:1rem;}
  }


  &.radius {
    border-radius: 0.6rem;
  }
  strong{font-family: $bold-font;}
  
  &.alert-primary {
    @include alert-theme-inverted($main-color,$icon-info-circle);
  }
  &.alert-secondary {
    @include alert-theme-inverted($sec-color,$icon-info-circle);
  }
  &.alert-info {
    @include alert-theme($info-color,$icon-info-circle);
  }
  &.alert-error {
    @include alert-theme($error-color,$icon-error);
  }
  &.alert-success {
    @include alert-theme($success-color,$icon-tick-thick);
  }
  &.alert-warning {
    @include alert-theme($warning-color,$icon-danger);
  }
  strong{font-family:$bold-font;}

  ul,ol{margin-bottom:0;padding-bottom:0;}
}



.alert-dismissible{
  padding-right:2.5em;
  transition:all .3s ease;
  &:after{
    cursor: pointer;
    @include icon($icon-cross);float:right;opacity:.4;display:block;text-align:center;position:absolute;right:1.2rem;border-radius:50%;width:30px;line-height:30px;margin-top:-15px;top:50%;background-color:#fff;
  }
  &:hover:after{opacity:1;}
}

.page-alert{
  margin:100px auto 5px auto;
  max-width:700px;
}