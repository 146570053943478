.popupOpen{
  overflow-y: auto;
}
.popupHolder{
  width:calc(100% - 20px);
  background-color:#fff;
  min-height:calc(100% - 20px);
  overflow: hidden;
  position: absolute;
  top:10px; 
  left:10px;
  visibility:hidden;
  opacity:0;
  text-align: center;
  box-shadow: 0 0 10px #000;
  transition:all .2s linear;
  transform: scale(.1, .1);
  img{
    text-align: center;
    margin:2rem auto 0 auto;
    //transform: scale(.1,.1);
    //transform-origin: 50% 50% !important;
    width:100% !important;
    max-width:1500px;
    transition:all .2s linear;
  }
    &:before {
      cursor: pointer;
      @include icon($icon-cross);
      display: inline-block;
      padding: .95rem;
      @include media($mobile) {
        padding: 1.25rem;
      }
      text-shadow: 0 0 1px #fff;
      z-index: 1;
      position: fixed;
      top: 0;
      right: 0;
    }
    &:hover:before {
      color: #333;
    }
}
.popupOpen .popupHolder{
  visibility:visible;
  opacity:1;
  z-index:99999;
  transform: scale(1, 1);
  img {
      //transform: scale(1, 1);
  }
}
.zoom-image{  
  &:hover {
        opacity:.9;
  }
}