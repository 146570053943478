$sidebar-levels:1;

$sidebar-bg:#333;
$sibebar-link-color:#ccc;
$sidebar-border-color:   #555;
$sidebar-header-height:$header-height-mobile;


.sidebar{
    font-family: $content-font;
    p {
        margin: 0;
    }
    background-color:$sidebar-bg;
    .header {
        //background-color: $main-color;
        height: $sidebar-header-height;
        width: 100%;;
        top: 0;
        border-bottom: 1px solid $sidebar-border-color;
        z-index: 9;
        position: absolute;
        .close-menu-button,
        .cart-button,
        .logout-button,
        .account-button {
            color: $sibebar-link-color;
            width: 55px;
            position: absolute;
            font-size: 18px;
            font-weight: normal;
            right: 0px;
            opacity: .6;
            top:0;
            line-height:$sidebar-header-height;
            display: block;
            border-left: 1px solid rgba(0, 0, 0, 0.4);
            height: 100%;
            vertical-align: middle;
            text-align: center;
            span{@include ellipsis(190px);}
        }
        .close-menu-button {
            cursor: pointer;
            &:before {
                @include icon($icon-cross);
                display: inline-block;
                margin-top: -3px;
            }
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                opacity: 1;
            }
            z-index: 1;
        }
        .logout-button {
            right: 50px;
            padding-left: 2px;
            z-index: 1;
            &:before {
                @include icon($icon-unlock);
                font-size: 1.2em;
            }
        }
        .account-button {
            display:inline-block;
            font-family: $content-font;
            text-align: left;
            padding-left: 20px;
            width: 100%;
            color: $sibebar-link-color;
            &:before {
                @include icon($icon-account);
                font-size: 27px;
                margin:15px 8px 0 0;
                display:inline-block;
                float:left;

            }
            &:hover {
                background-color: transparent;
                cursor:default;
            }
        }
    }
    //transform: translate3d(-280px, 0, 0px);
    //transition:all .3s linear;
    //&.sidebar-open {
    //    transform: translate3d(0, 0, 0);
    //}

    width: $sidebar-width;
    box-shadow:0 0 15px rgba(0, 0, 0, .2);
    ul {
        list-style: none;
        margin: 0;
        //transition: max-height .1s ease;
        padding:0;
        
    }
    li {
        margin: 0;
        padding: 0;
        display: block;
    }
    span {
        color: $sibebar-link-color;
        position: relative;
    }
    /*.active > span > .dropdown {
        background-color: #f0f0f0;
        &:before {
            content: "\e602"
        }
    }*/    
    @include media($lg-tablet) {
      li span a:hover {
        background-color: #000;
      }
    }
    /*    @include media($phablet) {
        ul{padding:52px 0 0 0}
        .header {
            height: 52px;
        }
        .menu-button,
        .cart-button {
            padding-top: 10px;
        }
        .account-button {
            padding-top: 11px;
        }
    }
*/
}

$sidebar-list-bg:#f9f9f9;
.sidebar-content {
    position: absolute;
    top:$sidebar-header-height;
    left: 0;
    height:calc(100% - #{$sidebar-header-height});
    overflow: hidden;
    overflow-y: auto;
    padding:0;
    transition: transform .3s linear;
    width: $sidebar-width*$sidebar-levels;
    transform: translateX(0);   
    h5{
        width: 100%;
        padding:0;
        font-family: $title-font;
        font-weight:$title-font-weight;
        color: $sibebar-link-color;
        a,span{
            color: $sibebar-link-color;display:block;
            padding: .75em .4em .70em 1.1em;
        }
        a:hover{background-color:#000;}
        display: block;
        border-bottom: 1px solid $sidebar-border-color;
        //border-top: 1px solid #e0e0e0;
        //line-height: 1.3;
        font-size: 19px;
        margin:0;
        i {
            font-size: 1em;
            margin-right: 5px;
            line-height: 1.1;
        }
    }
    &>ul {
        width: $sidebar-width;
        padding:0 1px;
        li:not(.sidebar-footer) {
            width: 100%;
            //background-color: $sidebar-list-bg;
            border-bottom: 1px solid $sidebar-border-color;
            span {
                display: block;
                padding: 0;
                width: 100%;
                margin: 0;
                .dropdown {
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 18px;
                    line-height: 3.1;
                    cursor: pointer;
                    padding: 0;
                    text-align: center;
                    border-left: 1px solid #eee;
                    vertical-align: middle;
                    width: 58px;
                    height: 100%;
                    display: block;
                    text-align: center;
                    &:before {
                        @include icon($icon-arrow-right);
                        color: #aaa;
                        vertical-align: middle;
                        line-height: 1.7;
                        margin-right: 3px;
                    }
                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
            a,
            li.cat-back {
                color: $sibebar-link-color;
                padding: .75em .4em .75em 1.3em;
                display: block;
                line-height: 1.15;
                cursor: pointer;
                font-size: 18px;
                font-weight: normal;
                i {
                    font-size: 1.15em;
                    margin:-.2em .2em -.1em 0;
                    line-height: 1.15;
                }
                i.icon-blog:before{
                    content:$icon-comment;
                }
                i.icon-about:before{
                    content:$icon-info-circle;
                }
                strong{
                    margin:0 .2rem 0 0;
                }
            }
            li.cat-back {
                background-color: #eee;
                font-family: $title-font;
            }
            &.active > ul {
                height: 100%;
                z-index: 9999;
            }
            ul {
                width: ($sidebar-width)-5px;
                display: block;
                position: absolute;
                top: 0;
                left: $sidebar-width;
                //overflow: hidden;

            }
        }

        &>li.sidebar-footer {
            overflow: hidden;
            margin: 0;
            height: 100%;
            width: ($sidebar-width)-5px;
            float: none;
            padding: .7em 1em 1em 1.3em;
            ul.social-icons >li {
                width:auto;background-color:transparent;border:none;
                &> a{
                    color:#aaa;
                    margin:0;
                    padding:10px 5px 0 0;
                }
                
            }
        }

    }
}

&.sub-cat >.sidebar-content {
    //left:-$sidebar-width;
    transform: translateX(-$sidebar-width);
}
